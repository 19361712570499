import styled from 'styled-components';
import {
  Headline3,
  ParagraphTitle,
} from '../../../../../../../../common/components/ui/typography';

export const FlexRowWrapper = styled.div`
  display: flex;
`;

export const FlexRowColumnWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const FlexRowHalfLeftWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  justify-content: flex-start;
`;

export const FlexRowHalfRightWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  justify-content: flex-end;
`;

export const FlexRowWrapperCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexRowWrapperLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexColumnWrapperCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpaceBetweenWrapper = styled.div<{
  isConstructions?: boolean;
  noConstuctions?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ isConstructions }) => isConstructions && 'padding: 0 12px 16px;'};
  ${({ isConstructions, noConstuctions }) =>
    isConstructions && noConstuctions && 'padding: 8px 12px 12px;'};
`;

export const PaddingWrapper = styled.div<{ padding: string }>`
  padding: ${(props) => props.padding || 0};
`;

export const MarginWrapper = styled.div<{ margin: string }>`
  margin: ${(props) => props.margin || 0};
`;

export const MainTitle = styled(Headline3)<{ isShrinked?: boolean }>`
  line-height: 1.33;
  font-size: 16px;
  ${(props) =>
    props.isShrinked &&
    `
    max-width: 10rem;
    overflow: hidden;
  `}
`;

export const MainLocation = styled(ParagraphTitle)<{
  fontWeight?: number | string;
  margin?: string;
}>`
  font-size: 10px;
  opacity: 0.5;
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-transform: uppercase;
  ${({ margin }) => margin && `margin: ${margin};`};
`;

export const MainRating = styled(Headline3)<{ color?: string }>`
  color: ${(props) => props.color};
  margin-right: 8px;
`;

export const MainAmount = styled(Headline3)<{ color?: string }>`
  color: ${({ theme }) => theme.blue};
  margin-right: 8px;
`;

export const ContentTitle = styled(ParagraphTitle)`
  font-size: 12px;
  font-weight: bold;
  opacity: 0.8;
  line-height: 1.67;
`;

export const ContentText = styled(ParagraphTitle)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RatingDotsWrapper = styled.div`
  border-radius: 30%;
  display: flex;
  width: 14px;
  height: 8px;
  overflow: hidden;
`;

export const RatingDotsWrapperBorder = styled.div<{
  percentage: number;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 10px;
  border-radius: 30%;
  overflow: hidden;
  background: #d9d9d9;
  background: ${(props) =>
    `linear-gradient(0deg, ${props.backgroundColor} calc(${props.percentage}%), #d9d9d9 calc(${props.percentage}%))`};
  padding: 0.5px;
  margin: 1px 0;
`;

export const LeftDotPart = styled.div<{
  percentage: number;
  backgroundColor: string;
}>`
  height: ${(props) => props.percentage}%;
  flex: 1 1 100%;
  background-color: ${(props) => props.backgroundColor || '#fff'};
`;

export const RightDotPart = styled.div<{
  percentage: number;
  backgroundColor: string;
}>`
  height: ${(props) => props.percentage}%;
  flex: 0 0 100%;
  background-color: ${(props) => props.backgroundColor || '#fff'};
`;
