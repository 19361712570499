import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ArrowButtonRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import Icon from '../../../common/components/ui/icon';
import LngLink from '../../../localization/lng-link';
import {
  Container,
  ItemBox,
  ItemTitle,
  ItemAddress,
  ItemDetailsBox,
  ChipDistance,
  ChipPrice,
  OuterContainer,
  LeftButton,
  RightButton,
  LeftButtonContainer,
  RightButtonContainer,
} from './multiple-search-profiles-styles';
import { siteMap } from '../../../../routes/site-map';

interface ItemProps {
  active?: boolean;
  _id: string;
  title: string;
  address: string;
  distance: string;
  price: string;
}

const Item = ({
  _id,
  active,
  title,
  address,
  distance,
  price,
}: ItemProps): JSX.Element => {
  return (
    <LngLink
      to={`${siteMap.SearchProfilePage.pathWithoutParams}/${_id}`}
      exact
      absolute
    >
      <ItemBox active={active}>
        <ItemTitle>{title}</ItemTitle>
        <ItemAddress>{address}</ItemAddress>
        <ItemDetailsBox>
          <ChipDistance>{distance}</ChipDistance>
          <ChipPrice>{price}</ChipPrice>
        </ItemDetailsBox>
      </ItemBox>
    </LngLink>
  );
};

interface SearchProfile {
  _id: string;
  title: string;
  address: string;
  distance: string;
  price: string;
}

interface MultipleSearchProfilesProps {
  searchProfilesData: SearchProfile[];
  activeId: string;
}

const MultipleSearchProfiles = ({
  searchProfilesData,
  activeId,
}: MultipleSearchProfilesProps): JSX.Element => {
  const scrollContainer = useRef(null);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);

  const checkForScroll = () => {
    if (!scrollContainer || !scrollContainer.current) return;

    const current = scrollContainer.current! as Element;
    const { clientWidth, scrollWidth, scrollLeft } = current;

    if (scrollLeft > 0) {
      setLeft(true);
    } else {
      setLeft(false);
    }

    if (clientWidth + scrollLeft < scrollWidth) {
      setRight(true);
    } else {
      setRight(false);
    }
  };

  useLayoutEffect(() => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    if (!scrollContainer || !scrollContainer.current) return undefined;

    const current = scrollContainer.current! as Element;
    current.addEventListener('scroll', checkForScroll);
    window.addEventListener('resize', checkForScroll);
    checkForScroll();

    return () => {
      current.removeEventListener('scroll', checkForScroll);
      window.removeEventListener('resize', checkForScroll);
    };
  }, []);

  const scrollHandler = (direction: 'left' | 'right') => {
    if (!scrollContainer || !scrollContainer.current) return;

    const current = scrollContainer.current! as Element;

    current.scrollTo({
      top: 0,
      left:
        direction === 'right'
          ? current.scrollLeft + 320
          : current.scrollLeft - 320,
      behavior: 'smooth',
    });

    checkForScroll();
  };

  useEffect(() => {
    checkForScroll();
  }, [searchProfilesData]);

  return (
    <OuterContainer>
      {left && (
        <LeftButtonContainer>
          <LeftButton onClick={() => scrollHandler('left')}>
            <Icon icon={ArrowButtonLeft} width={10} height={10} />
          </LeftButton>
        </LeftButtonContainer>
      )}
      <Container ref={scrollContainer}>
        {searchProfilesData.map((item) => {
          return (
            <Item {...item} active={activeId === item._id} key={item._id} />
          );
        })}
      </Container>
      {right && (
        <RightButtonContainer>
          <RightButton onClick={() => scrollHandler('right')}>
            <Icon icon={ArrowButtonRight} width={10} height={10} />
          </RightButton>
        </RightButtonContainer>
      )}
    </OuterContainer>
  );
};

export { MultipleSearchProfiles };
