import styled from 'styled-components';
import SwiperCore from 'swiper';

const Container = styled.div`
  position: absolute;
  bottom: 100px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 520px;
  align-items: center;
`;

const Bullet = styled.button<{ active?: boolean }>`
  width: 16px;
  position: relative;
  height: 16px;
  border-radius: 50%;
  padding: 3px;
  border: solid 1px rgba(0, 48, 94, 0.5);
  background: transparent;
  background-clip: content-box;
  margin: 0 20px;
  opacity: 1;
  cursor: pointer;

  ${(props) => props.active && `background-color: ${props.theme.blue};`}

  &:not(:last-of-type):after {
    content: '';
    border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
    position: absolute;
    width: calc(300% - 8px);
    top: 50%;
    left: calc(100% + 4px);
    transform: translateY(-50%);
  }
`;

interface IProps {
  swiper: SwiperCore | null;
  count: number;
}

const CustomPagination = ({ swiper, count }: IProps) => {
  if (!swiper) return null;

  return (
    <Container>
      {[...Array.from({ length: count })].map((_, index: number) => {
        return (
          <Bullet
            active={swiper.activeIndex === index}
            onClick={() => swiper.slideTo(index)}
          />
        );
      })}
    </Container>
  );
};

export default CustomPagination;
