import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ManageAccess } from 'assets/streamline-light/programing-apps-websites/digital-policies/ccpa-lock-protection-opt-out.svg';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { omit } from 'lodash';
import { toggleFileAccessManagerOpen } from '../../redux/brokerViewSlice';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { Modal, ModalTitle } from '../../../common/components/modal';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { InputSearch } from '../../../common/components/form/input/input-search';
import {
  ActionsWrapper,
  CheckboxWrapper,
  Email,
  FullName,
  NotificationInfoMessage,
  UserInfo,
  UsersList,
} from './file-access-manager-styles';
import { Checkbox } from '../../../common/components/form';
import { Form } from '../../../seller/components/expose/expose-state-card/expose-state-card-styles';
import {
  MainButton,
  SecondaryButton,
} from '../../../common/components/ui/buttons';
import {
  FileAccessConfigInput,
  FileAccessLimit,
  useGetBuyersDocumentAccessStatusQuery,
  useSaveFileAccessConfigMutation,
  useFileAccessConfigQuery,
} from '../../../../generated';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { AccessLimitSelect } from './access-limit-select';
import {
  buildAccessLimitOptions,
  parseOptionValue,
} from '../../utils/build-access-limit-options';
import { BoxText } from '../../../common/components/ui/typography';
import {
  getDate,
  getTime,
} from '../../../search-profile/components/appointments/utils';

interface IProps {
  isOpen: boolean;
  onSave: (input: FileAccessConfigInput) => void;
}

const FilesAccessManagerBase: FC<IProps> = ({ isOpen, onSave }) => {
  // const LIMIT = 20;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const [searchInput, setSearchInput] = useState<string>('');
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isSelectAllTouched, setIsSelectAllTouched] = useState<boolean>(false);
  // const accessLimitOptions = useMemo(() => buildAccessLimitOptions(), []);
  // const [selectedAccessLimit, setSelectedAccessLimit] = useState(
  //   accessLimitOptions[1]
  // );
  // const [pagination, setPagination] = useState({
  //   offset: 0,
  //   limit: LIMIT,
  // });

  const urlParams = new URLSearchParams(window.location.search);
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const selectedFileData = useAppSelector(
    (state) => state.brokerView.selectedFileData
  );

  const {
    data: onOfficeUsers,
    isLoading: isOnOfficeUserLoading,
    error: onOfficeErrors,
    isFetching: isOnOfficeUserFetching,
  } = useGetBuyersDocumentAccessStatusQuery(
    {
      propertyId: propertyOnOfficeId,
      fileId: String(selectedFileData?.id || ''),
      // offset: pagination.offset,
      // limit: pagination.limit,
    },
    {
      skip: !propertyOnOfficeId || !selectedFileData.id,
    }
  );

  // const {
  //   data: fileAccessConfig,
  //   isLoading: isAccessConfigLoading,
  //   error: accessConfigErrors,
  //   isFetching: isAccessConfigFetching,
  // } = useFileAccessConfigQuery(
  //   {
  //     fileId: String(selectedFileData?.id || ''),
  //   },
  //   {
  //     skip: !propertyOnOfficeId || !selectedFileData.id,
  //   }
  // );

  const formValues = useMemo(() => {
    if (isSelectAllTouched) {
      return (
        onOfficeUsers?.getBuyersDocumentAccessStatus?.reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [String(currentValue.id)]: isSelectAll,
            select_all: isSelectAll,
          }),
          {}
        ) ?? {}
      );
    }
    return (
      onOfficeUsers?.getBuyersDocumentAccessStatus?.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [String(currentValue.id)]: currentValue.hasAccessToDocument,
        }),
        {}
      ) ?? {}
    );
  }, [
    isSelectAll,
    isSelectAllTouched,
    onOfficeUsers?.getBuyersDocumentAccessStatus,
  ]);

  useEffect(() => {
    // setPagination({ offset: 0, limit: LIMIT });
    setIsSelectAllTouched(false);
    setIsSelectAll(false);
  }, [selectedFileData.id]);

  // const handleLoadMore = useCallback(() => {
  //   setPagination({ offset: pagination.offset + LIMIT, limit: LIMIT });
  // }, [pagination.offset]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: formValues,
  });

  const [saveFileAccessConfig, { isLoading: loader, error: saveConfigError }] =
    useSaveFileAccessConfigMutation();

  const closeModal = useCallback(() => {
    dispatch(toggleFileAccessManagerOpen(false));
  }, [dispatch, methods]);

  const onSubmit: SubmitHandler<Record<string, boolean>> = useCallback(
    async (formData) => {
      try {
        const usersIds = omit(formData, 'select_all');
        const usersWithGrantedAccess = Object.keys(usersIds)?.filter(
          (key) => usersIds[key]
        );
        const usersWithForbiddenAccess = Object.keys(usersIds)?.filter(
          (key) => !usersIds[key]
        );

        const input: FileAccessConfigInput = {
          propertyId: propertyOnOfficeId,
          fileId: String(selectedFileData?.id || ''),
          fileName: String(selectedFileData?.title || ''),
          usersWithGrantedAccess,
          usersWithForbiddenAccess,
          // accessLimit: parseOptionValue(selectedAccessLimit),
          accessLimit: FileAccessLimit.Unlimited,
        };

        await saveFileAccessConfig({
          input,
        }).unwrap();
        onSave(input);
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
    [
      propertyOnOfficeId,
      selectedFileData?.id,
      selectedFileData?.title,
      saveFileAccessConfig,
      onSave,
      closeModal,
      // selectedAccessLimit,
    ]
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    },
    []
  );

  const filteredUsers = useMemo(() => {
    const searchInputLowerCase = searchInput.toLowerCase();
    return (
      onOfficeUsers?.getBuyersDocumentAccessStatus?.filter(
        (address) =>
          address?.email?.toLowerCase().startsWith(searchInputLowerCase) ||
          address?.name?.toLowerCase().startsWith(searchInputLowerCase) ||
          address?.surname?.toLowerCase().startsWith(searchInputLowerCase) ||
          address?.id?.toString().startsWith(searchInputLowerCase)
      ) ?? []
    );
  }, [searchInput, onOfficeUsers?.getBuyersDocumentAccessStatus]);

  useEffect(() => {
    methods.reset(formValues);
  }, [formValues]);

  const wrapperStyles = useMemo(
    () =>
      isMobileSize
        ? {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            height: '100%',
            overflow: 'overlay',
            padding: '24px',
          }
        : {
            maxWidth: '800px',
            height: '800px',
          },
    [isMobileSize]
  );

  // const onAccessLimitChange = useCallback(
  //   (option: { field: FileAccessLimit; label: string }) => {
  //     setSelectedAccessLimit(option);
  //   },
  //   []
  // );

  if (
    loader ||
    isOnOfficeUserLoading ||
    isOnOfficeUserFetching
    // isAccessConfigFetching ||
    // isAccessConfigLoading
  ) {
    return <GlobalLoader />;
  }

  return (
    <Modal
      key={`file-access-manager-${selectedFileData.id}`}
      isOpen={isOpen}
      wrapperStyles={wrapperStyles}
      onClose={closeModal}
      overlayStyles={{ overflow: 'hidden' }}
    >
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalTitle
            icon={ManageAccess}
            text={t(
              'buyer-portal.broker-view.expose-documents-page.access-manager.title',
              { fileName: selectedFileData.title }
            )}
            iconPosition="left"
            textAlign="left"
          />

          <NotificationInfoMessage
            content={t(
              'buyer-portal.broker-view.expose-documents-page.access-manager.notification-info-message'
            )}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '10px',
            }}
          >
            <InputSearch
              placeholder={t(
                'buyer-portal.broker-view.expose-documents-page.access-manager.search'
              )}
              containerStyle={{
                width: '100%',
                marginTop: 0,
                maxWidth: '80%',
                marginRight: '8px',
              }}
              onChange={onSearchInputChange}
              value={searchInput}
              onClearSearch={() => setSearchInput('')}
            />
            <div
              role={'presentation'}
              onClick={(event) => {
                event.preventDefault();
                setIsSelectAllTouched(true);
                setIsSelectAll((prevState) => !prevState);
              }}
            >
              <Checkbox
                name={'select_all'}
                label={t(
                  'buyer-portal.broker-view.expose-documents-page.access-manager.select-all'
                )}
                isWithoutErrors
              />
            </div>
            {/* <AccessLimitSelect */}
            {/*  options={accessLimitOptions} */}
            {/*  selectedAccessLimit={selectedAccessLimit} */}
            {/*  onChange={onAccessLimitChange} */}
            {/* /> */}
          </div>

          {onOfficeErrors && (
            <GlobalError
              title={t(
                (onOfficeErrors as { message: string })?.message
                  ?.split(':')?.[0]
                  .toLowerCase()
              )}
            />
          )}
          {/* {accessConfigErrors && ( */}
          {/*  <GlobalError */}
          {/*    title={t( */}
          {/*      (accessConfigErrors as { message: string })?.message */}
          {/*        ?.split(':')?.[0] */}
          {/*        .toLowerCase() */}
          {/*    )} */}
          {/*  /> */}
          {/* )} */}
          {saveConfigError && (
            <GlobalError
              title={t(
                (saveConfigError as { message: string })?.message
                  ?.split(':')?.[0]
                  .toLowerCase()
              )}
            />
          )}
          {/* {fileAccessConfig?.fileAccessConfig?.accessExpiresAt && ( */}
          {/*  <BoxText */}
          {/*    content={`${t( */}
          {/*      'buyer-portal.broker-view.expose-documents-page.file-access.access-expires-at', */}
          {/*      { */}
          {/*        date: `${getDate( */}
          {/*          fileAccessConfig?.fileAccessConfig?.accessExpiresAt, */}
          {/*          true */}
          {/*        )} - ${getTime( */}
          {/*          fileAccessConfig?.fileAccessConfig?.accessExpiresAt */}
          {/*        )}`, */}
          {/*      } */}
          {/*    )}`} */}
          {/*  /> */}
          {/* )} */}

          <UsersList>
            {filteredUsers.map((item) => (
              <UserInfo key={item.id}>
                <FullName
                  content={`${item.name} ${item.surname} (${item.id})`}
                />
                <Email content={item.email ?? ''} />
                <CheckboxWrapper>
                  <Checkbox
                    name={String(item.id)}
                    label={t(
                      'buyer-portal.broker-view.expose-documents-page.access-manager.grant-access'
                    )}
                    isWithoutErrors
                  />
                </CheckboxWrapper>
              </UserInfo>
            ))}
          </UsersList>
          {/* <LoadMoreButtonContainer> */}
          {/*  <LoadMoreButton */}
          {/*    label={t('button.load-more')} */}
          {/*    onClick={handleLoadMore} */}
          {/*  /> */}
          {/* </LoadMoreButtonContainer> */}
          <ActionsWrapper>
            <SecondaryButton
              label={t(
                'buyer-portal.broker-view.expose-documents-page.access-manager.cancel'
              )}
              onClick={() => {
                closeModal();
                // methods.reset(formValues);
              }}
              type={'button'}
            />
            <MainButton
              label={t(
                'buyer-portal.broker-view.expose-documents-page.access-manager.save'
              )}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </ActionsWrapper>
        </Form>
      </FormProvider>
    </Modal>
  );
};

const FilesAccessManagerModal = memo(FilesAccessManagerBase);

export { FilesAccessManagerModal };
