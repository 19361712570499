import styled from 'styled-components';
import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';
import {
  Headline3,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { MainButton } from '../../../common/components/ui/buttons';
import { InputTextArea } from '../../../common/components/form/input/input-text-area';

export const Container = styled.div`
  max-width: 1104px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 32px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;

  @media ${device.tablet} {
    margin: 16px;
    padding: 0;
    border: none;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h3`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const ContentBox = styled.div`
  max-width: 1040px;
  padding: 32px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  background-color: #fff;

  @media ${device.mobile} {
    padding: 24px;
  }
`;

export const ContentText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.blue};
  letter-spacing: 0.5px;
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-areas:
    'icon purpose purposeText'
    'icon regard regardText';
  grid-template-columns: 52px 46px 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  column-gap: 18px;
  margin-bottom: 24px;

  @media ${device.mobile} {
    grid-template-columns: 32px 46px 1fr;
    column-gap: 16px;
  }
`;

export const LetterIcon = styled(Icon)`
  grid-area: icon;
`;

export const Purpose = styled(ParagraphText)`
  grid-area: purpose;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  opacity: 0.5;

  @media ${device.mobile} {
    font-size: 11px;
  }
`;

export const Regard = styled(ParagraphText)`
  grid-area: regard;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  opacity: 0.5;

  @media ${device.mobile} {
    font-size: 11px;
  }
`;

export const PurposeText = styled(Headline3)`
  grid-area: purposeText;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const RegardText = styled(ParagraphText)`
  grid-area: regardText;

  @media ${device.mobile} {
    font-size: 12px;
  }
`;
export const FormWrapper = styled.div`
  padding: 24px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const Form = styled.form``;

export const SubmitButton = styled(MainButton)`
  text-transform: uppercase;
  margin-top: 24px;
  justify-content: center;

  @media ${device.mobile} {
    margin-top: 18px;
    width: 100%;
    height: 40px;
  }
`;
