import { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { device } from '../../../../style/theme';
import { Tooltip } from '../../../common/components/tooltip';
import Icon from '../../../common/components/ui/icon';
import {
  ParagraphTitle,
  TitleSlogan,
} from '../../../common/components/ui/typography';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  @media ${device.tablet} {
    flex: 0 0 45%;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:not(:last-of-type) {
    margin-right: 8px;
    flex: 0 0 auto;
  }
`;

const Value = styled(ParagraphTitle)`
  && {
    display: inline-flex;
    color: ${(props) => props.theme.blue};
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Label = styled(TitleSlogan)<{ sloganBigger?: boolean }>`
  && {
    font-size: 10px;
    ${(props) => props.sloganBigger && 'font-size: 12px;'}
    line-height: 1.6;
    opacity: 0.6;
    color: ${(props) => props.theme.blue};
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  type: string;
  value: string | number;
  sloganBigger?: boolean;
}

const PropertyValuationIcon = ({
  icon,
  type,
  value,
  sloganBigger,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const labelRef = useRef<HTMLParagraphElement>(null);
  const [isTooltip, setIsTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (
      labelRef.current &&
      labelRef.current.scrollWidth > labelRef.current.offsetWidth
    ) {
      setIsTooltip(true);
    }
  }, []);

  return (
    <Container className="property-valuation-icon">
      <Column>
        <Icon icon={icon} width={24} height={24} color={themeContext.blue} />
      </Column>
      <Column>
        <Value className="property-valuation-icon-value" content={`${value}`} />
        <Tooltip
          isDisabled={!isTooltip}
          noMaxWidth
          textAlign={'left'}
          id={`${type}--${value}`}
          maxWidth={0}
          content={
            <Label ref={labelRef} content={type} sloganBigger={sloganBigger} />
          }
        >
          <Label content={type} sloganBigger={sloganBigger} />
        </Tooltip>
      </Column>
    </Container>
  );
};

export default PropertyValuationIcon;
