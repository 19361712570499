import shopping from 'assets/images/icons/infrastructure/infrastructure.shopping.png';
import family from 'assets/images/icons/infrastructure/infrastructure.education.png';
import handsHeart from 'assets/images/icons/infrastructure/infrastructure.love-heart-hands-hold.png';
import construction from 'assets/images/icons/infrastructure/infrastructure.construction.png';
import environment from 'assets/images/icons/infrastructure/infrastructure.environment.png';

interface ICategoryMeta {
  title: string;
  icon: string;
}

const getTitle = (category: string): string => {
  switch (category) {
    case 'shopping':
      return 'micro-situation.shopping';
    case 'family':
      return 'micro-situation.family';
    case 'life-quality':
      return 'micro-situation.life-quality';
    case 'environment':
      return 'micro-situation.environment';
    case 'construction':
      return 'micro-situation.construction';
    default:
      return '';
  }
};

const getIcon = (category: string): string => {
  switch (category) {
    case 'shopping':
      return shopping;
    case 'family':
      return family;
    case 'life-quality':
      return handsHeart;
    case 'environment':
      return environment;
    case 'construction':
      return construction;
    default:
      return '';
  }
};

const getMicroSituationMeta = (category: string): ICategoryMeta => {
  return {
    title: getTitle(category),
    icon: getIcon(category),
  };
};

export { getMicroSituationMeta };
