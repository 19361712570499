import { FC } from 'react';
import { useParams } from 'react-router';

import { Poi, PropertyPoi } from '../../../../../generated';

import { Infrastructure } from '../../../../common/components/infrastructure/infrastructure';
import { usePropertyQuery } from '../../../../../services/graphql/enhanced';
import { useMapInfrastructure } from './useMapInfrastructure';
import { addMarkers, removeMarkers } from '../../../../map/redux/mapSlice';

type PropertyPoiWithIndex = PropertyPoi & {
  [key: string]: Record<string, Poi[]>;
};

const InfrastructurePropertyWrapper: FC = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { propertyPois, propertyLocation, locationScores } = usePropertyQuery(
    {
      id: propertyId,
    },

    {
      selectFromResult: ({ data }) => ({
        propertyPois: data?.property?.poi as PropertyPoiWithIndex,
        propertyLocation: data?.property?.propertyData.location,
        locationScores: data?.property?.locationScores,
      }),
    }
  );
  const { currentMapboxMarkers } = useMapInfrastructure({
    location: propertyLocation,
    propertyPois,
  });
  return (
    <Infrastructure
      propertyPois={propertyPois}
      locationScores={locationScores}
      currentMapboxMarkers={currentMapboxMarkers}
      addMarkers={addMarkers}
      removeMarkers={removeMarkers}
    />
  );
};

export { InfrastructurePropertyWrapper };
