import { FC, RefObject } from 'react';

import { EnergyEfficiency } from './energy-efficiency/energy-efficiency';
import { MicroSituation } from './micro-situation/micro-situation';
// import { ValueDevelopment } from './value-development/value-development';
import { Tour360 } from './tour-360/tour-360';
import { PropertyDetails } from './property-details/property-details';
import { Gallery } from '../../slider/gallery';
import { ContactForm } from '../../contact-form/contact-form';
import { Description } from './description/description';

interface IProps {
  contactFormRef?: RefObject<HTMLDivElement>;
}

const Overview: FC<IProps> = ({ contactFormRef }) => {
  return (
    <>
      <Gallery />
      <PropertyDetails />

      <EnergyEfficiency />

      <MicroSituation />

      {/* <ValueDevelopment /> */}

      <Tour360 />

      <Description />

      <ContactForm contactFormRef={contactFormRef} />
    </>
  );
};

export { Overview };
