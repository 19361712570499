import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ReactComponent as File } from 'assets/streamline-light/files-folders/common-files/common-file-text-remove.svg';

import EmptyStatePlaceholder from '../../../../../../common/components/empty-state-placeholder';
import {
  Container,
  DocumentsWrapper,
  Title,
  LoaderContainer,
} from './documents-styles';
import { UploadedItem } from '../../../../../../common/components/form/input/input-file/uploaded-item/uploaded-item';
import {
  useGetExposeDocumentsQuery,
  useCreateDownloadedFileActivityMutation,
} from '../../../../../../../generated';
import { getFileExtension } from '../../../../../../common/components/form/input/input-file/utils/getFileExtension';
import { getSizeFromBase64 } from '../../../../../../common/components/form/input/input-file/utils/getSizeFromBase64';
import { UploadedBy } from '../../../../../../seller/components/overview/uploaded-files-section/uploaded-files-section';
import { isImage } from '../../../../../../common/components/form/input/input-file/utils/isImage';
import { useAppSelector } from '../../../../../../common/hooks';
import { GlobalError } from '../../../../../../common/components/form/error/global-error';
import { GlobalLoader } from '../../../../../../common/components/ui/loaders/global-loader';
import { ExternalFileType } from '../../../../../../seller/constants';

const DocumentsBase = () => {
  const { t } = useTranslation();

  const exposeId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );

  const { exposeDocuments, isLoading, errors } = useGetExposeDocumentsQuery(
    {
      exposeId,
    },
    {
      selectFromResult: ({ data, isLoading: loading, error }) => ({
        exposeDocuments: data?.getExposeDocuments,
        isLoading: loading,
        errors: error,
      }),
      skip: isEmpty(exposeId),
    }
  );

  const [createDownloadedFileMutation] =
    useCreateDownloadedFileActivityMutation();

  const handleOpenFile = useCallback(
    async (fileBase64: string, fileName: string, originalName: string) => {
      //   const fileBlob = await fetch(fileBase64).then((res) => res.blob());
      //   const url = URL.createObjectURL(fileBlob);
      //   window.open(url, '_blank');
      const fileBlob = await fetch(fileBase64).then((res) => res.blob());
      const fileNameExtension = getFileExtension(fileName);
      const originalFileNameExtension = getFileExtension(originalName);
      let fileNameWithExtension = fileName;
      if (fileNameExtension !== originalFileNameExtension) {
        fileNameWithExtension = `${fileName}.${originalFileNameExtension}`;
      }
      const a = document.createElement('a');
      document.body.append(a);
      a.style.setProperty('display', 'none');
      // a.style = 'display: none';
      const url = window.URL.createObjectURL(fileBlob);
      a.href = url;
      a.download = fileNameWithExtension;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    []
  );

  const handleOpenLink = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const transformedDocuments = exposeDocuments?.map((file) => {
    let fileType: string;

    switch (file.type) {
      case ExternalFileType.Expose:
      case ExternalFileType.Notice:
      case ExternalFileType.RentalStatement:
      case ExternalFileType.EnergyCertificate:
      case 'Grundriss':
      case 'Dokument':
        fileType = 'application';
        break;
      case 'Foto':
        fileType = 'image';
        break;
      default:
        fileType = '';
        break;
    }

    const fileExtension = getFileExtension(file.filename ?? '');
    const openFileHandler = async (fileOriginalName: string) => {
      const isDoc = fileExtension === 'docx' || fileExtension === 'doc';
      const fileName = file?.title ?? file?.filename ?? file.originalname;

      if (file.url) {
        handleOpenLink(file.url);
      } else {
        await handleOpenFile(
          `data:${fileType}/${isDoc ? 'msword' : fileExtension};base64, ${
            file.content
          }`,
          fileName,
          file.originalname
        );
      }

      createDownloadedFileMutation({ fileName, exposeId });
    };

    return {
      type: file.type,
      title: file.title,
      fileName: file.filename ?? file.url ?? '',
      size: file.content ? getSizeFromBase64(file.content) : 0,
      onOpenFile: () => openFileHandler(file.originalname),
      uploadedBy: UploadedBy.Broker,
      url: file.url ?? '',
      previewUrl: isImage(file.filename ?? '')
        ? `data:image/${fileExtension};base64, ${file.content}`
        : '',
    };
  });

  if (isLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      {errors?.message && (
        <GlobalError title={t(errors?.message?.split(':')[0].toLowerCase())} />
      )}
      <DocumentsWrapper>
        {isEmpty(transformedDocuments) ? (
          <EmptyStatePlaceholder
            icon={File}
            title={t('expose-page.tabs.documents.placeholder.title')}
            description={t(
              'expose-page.tabs.documents.placeholder.description'
            )}
          />
        ) : (
          <>
            <Title content={t('expose-page.tabs.documents.title')} />
            {transformedDocuments?.map(
              ({ title, fileName, size, onOpenFile, previewUrl, url }) => (
                <UploadedItem
                  key={fileName}
                  title={title}
                  fileName={fileName}
                  size={size}
                  onOpenFile={onOpenFile}
                  previewImgUrl={previewUrl}
                  url={url}
                />
              )
            )}
          </>
        )}
      </DocumentsWrapper>
    </Container>
  );
};

const Documents = memo(DocumentsBase);

export { Documents };
