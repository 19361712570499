import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import {
  BoxTitle,
  Headline2,
  ParagraphText,
} from '../../../../../common/components/ui/typography';

export const Wrapper = styled.div`
  .outer-accordion {
    border-color: ${(props) => props.theme.lightBlue};

    .outer-header {
      background: ${(props) => props.theme.faqActiveHeader};
      transition: all 0.3s;
      /* border-radius: 0; */
      padding: 24px 32px;

      &:hover {
        background: rgb(229, 233, 236, 1);
      }

      #close-button {
        order: 2;
        margin-left: auto;
      }
    }

    .rc-collapse-content {
      background: ${(props) => props.theme.greyBackground};
      color: ${(props) => props.theme.blue};
      padding: 8px 32px 32px;

      .rc-collapse-content-box {
        margin: 0;
      }
    }

    @media ${device.tablet} {
      margin: 16px -16px -16px -16px;

      .outer-header {
        padding-left: 16px;
      }

      .rc-collapse-content {
        padding: 8px 16px 16px;
      }
    }

    @media ${device.mobile} {
      border: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media ${device.mobile} {
    padding-left: 16px;
  }
`;
export const HeaderTitle = styled(Headline2)`
  font-size: 24px;

  @media ${device.mobile} {
    font-size: 20px;
  }
`;
export const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding-top: 32px;
  position: relative;

  &:before {
    content: '';
    display: block;
    border-top: 1px dashed ${(props) => props.theme.blue};
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    padding-top: 16px;

    &:before {
      border-top: none;
      border-left: 1px dashed ${(props) => props.theme.blue};
      position: absolute;
      top: 16px;
      left: 32px;
      right: auto;
      bottom: 0;
    }
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  @media ${device.mobile} {
    flex-direction: row;
    padding: 0 16px;
  }
`;

export const ItemNumber = styled.div`
  width: 32px;
  height: 32px;
  color: ${(props) => props.theme.logoBlue};
  border: 1px solid ${(props) => props.theme.blue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: ${(props) => props.theme.greyBackground};
`;
export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
`;

export const ItemTitle = styled(BoxTitle)`
  font-size: 16px;
`;
export const ItemText = styled(ParagraphText)`
  /* font-size: 14px;
  font-weight: 500; */
`;
