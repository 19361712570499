import { ReactComponent as EmptyStateIcon } from 'assets/streamline-light/real-estate/search/real-estate-search-house-target.svg';
import { ReactComponent as WarningIcon } from 'assets/streamline-light/transportation/signs/road-sign-warning.svg';
import {
  PaginationArgs,
  PropertyFilterInput,
  PropertyPortfolioFieldsFragment,
  ValuationType,
  usePaginatedPropertyPortfolioQuery,
  usePropertyPortfolioQuery,
} from 'generated';
import { InputSearch } from 'modules/common/components/form/input/input-search';
import { SecondaryButton } from 'modules/common/components/ui/buttons';
import { Hero } from 'modules/common/components/ui/hero';
import { HeroSlider } from 'modules/common/components/ui/hero-slider/hero-slider';
import Icon from 'modules/common/components/ui/icon';
import { GlobalLoader } from 'modules/common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import { OnlineValuationContainer } from 'modules/forms/form-property-valuation-wizard/components/containers/online-valuation/online-valuation-container';
import { PersonalValuationContainer } from 'modules/forms/form-property-valuation-wizard/components/containers/personal-valuation/personal-valuation-container';
import { CTAButtons } from 'modules/forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/cta-buttons/cta-buttons';
import { PropertyValuationWidgetContainer } from 'modules/forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/property-valuation-widget-container';
import { PageType } from 'modules/forms/form-property-valuation-wizard/interface';
import { Mapbox } from 'modules/map/components/mapbox';
import { defaultMapSettings } from 'modules/map/constants';
import {
  addMarkers,
  changeViewport,
  removeAllMarkers,
  setSettings,
} from 'modules/map/redux/mapSlice';
import { calculateBestMapView } from 'modules/map/utils';
import { PortfolioProperty } from 'modules/property/components/card/portfolio-property';
import { PropertyPortfolioOverviewCard } from 'modules/property/components/card/property-portfolio-overview-card';
import { usePropertyPortfolioDefaultFilters } from 'modules/property/hooks/usePropertyPortfolioDefaultFilters';
import { usePropertyPortfolioFiltersActive } from 'modules/property/hooks/usePropertyPortfolioFiltersActive';
import {
  IPropertyPortfolio,
  IPropertyPortfolioFilterFormField,
} from 'modules/property/interfaces';
import { scrollToProperty } from 'modules/property/redux/dynamicMapUtilsSlice';
import { generatePropertyPortfolioList } from 'modules/property/utils/generate-property-portfolio-list';
import {
  calculatePropertyPrice,
  getPropertyTitle,
  toPropertyFilterInput,
} from 'modules/property/utils/property.utils';
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { useConvertEURtoCHF } from '../../../common/utils/convertCHF';
import { useCurrency } from '../../hooks/useCurrency';
import { formatNumber } from '../../utils/format-number';
import { FilterButton, FilterButtonRef } from './filter-button/filter-button';
import { ClearFilterButton } from './filter-button/filter-button-styles';
import {
  ActionsContent,
  ContentWithPadding,
  Count,
  FilterContent,
  FilterResetBox,
  FilterResetBoxContent,
  FilterResetBoxText,
  FlexContainer,
  Header,
  ItemContainer,
  ListContainer,
  LoaderContainer,
  MobileHeadContainer,
  PropertiesContent,
  PropertiesEmptyState,
  PropertiesEmptyStateText,
  PropertiesEmptyStateTitle,
  SearchField,
  Title,
} from './property-portfolio-page-styles';
import { SortButton } from './sort-button/sort-button';

const LoadMoreButtonContainer = styled.div<{
  isMobileSize?: boolean;
}>`
  margin: ${(props) => (props.isMobileSize ? '40px 0 0 0' : '40px 6px 0 6px')};
`;

const LoadMoreButton = styled(SecondaryButton)`
  justify-content: center;
`;

const useUserProperties = ({
  ownerId,
  order,
  defaultTitleTranslation,
}: {
  ownerId?: string;
  order: 'asc' | 'desc';
  defaultTitleTranslation: string;
}) => {
  const { data: userPropertiesData, isLoading } = usePropertyPortfolioQuery({
    ownerId,
  });

  const userProperties = useMemo(
    () => userPropertiesData?.properties ?? [],
    [userPropertiesData]
  );

  const propertyPortfolioList = useMemo(() => {
    const compareFn = (
      a: PropertyPortfolioFieldsFragment,
      b: PropertyPortfolioFieldsFragment
    ) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

    const sortedProperties = [...userProperties].sort((a, b) =>
      order === 'asc' ? compareFn(a, b) : compareFn(b, a)
    );

    return generatePropertyPortfolioList(
      sortedProperties,
      order,
      defaultTitleTranslation
    );
  }, [userProperties, order, defaultTitleTranslation]);

  return {
    userProperties: propertyPortfolioList,
    userPropertiesLoading: isLoading,
  };
};

const usePaginatedUserProperties = ({
  defaultTitleTranslation,
  allProperties,
  ownerId,
  filters,
  search,
  order,
}: {
  defaultTitleTranslation: string;
  allProperties: IPropertyPortfolio[];
  ownerId?: string;
  filters: IPropertyPortfolioFilterFormField[];
  search: string;
  order: 'asc' | 'desc';
}) => {
  const [userProperties, setUserProperties] = useState<
    PropertyPortfolioFieldsFragment[]
  >([]);
  const [userPropertiesCount, setUserPropertiesCount] = useState<number>(0);
  const [userPropertiesLoading, setUserPropertiesLoading] =
    useState<boolean>(true);

  const [currentFilters, setCurrentFilters] = useState(filters);
  const [currentSearch, setCurrentSearch] = useState(search);
  const [currentOrder, setCurrentOrder] = useState(order);

  const filter = useMemo<PropertyFilterInput>(() => {
    return {
      ownerId,
      ...toPropertyFilterInput(currentFilters ?? []),
      search: currentSearch,
      sortBy: 'createdAt',
      sortOrder: currentOrder,
    };
  }, [ownerId, currentFilters, currentSearch, currentOrder]);

  const [pagination, setPagination] = useState<PaginationArgs>({
    limit: 8,
    offset: 0,
  });

  const { data: userPropertiesData } = usePaginatedPropertyPortfolioQuery({
    filter,
    pagination,
  });

  useEffect(() => {
    setUserPropertiesLoading(true);
    setPagination({
      limit: 8,
      offset: 0,
    });
    setCurrentFilters(filters);
    setCurrentSearch(search);
    setCurrentOrder(order);
  }, [filters, search, order]);

  useEffect(() => {
    if (!userPropertiesData) {
      return;
    }

    const {
      readProperties: { page, pageData },
    } = userPropertiesData;

    setUserProperties((currentUserProperties) => {
      if (pagination.offset === 0) {
        // If offset is 0, reset the properties
        return [...page];
      }

      const propertySet = new Set(page.map((p) => p._id));
      const uniqueProperties = [
        ...currentUserProperties.filter((p) => !propertySet.has(p._id)),
        ...page,
      ];

      return uniqueProperties;
    });
    setUserPropertiesCount(pageData.count ?? 0);
    setUserPropertiesLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPropertiesData]);

  const propertyPortfolioList = useMemo(
    () =>
      generatePropertyPortfolioList(
        userProperties,
        currentOrder,
        defaultTitleTranslation,
        allProperties
      ),
    [userProperties, currentOrder, defaultTitleTranslation, allProperties]
  );

  const userPropertiesHasMore = userProperties.length < userPropertiesCount;

  const userPropertiesNext = () => {
    if (!userPropertiesHasMore) {
      return;
    }

    setUserPropertiesLoading(true);
    setPagination((currentPagination) => ({
      ...currentPagination,
      offset: (currentPagination.offset ?? 0) + 8,
    }));
  };

  return {
    paginatedUserProperties: propertyPortfolioList,
    paginatedUserPropertiesCount: userPropertiesCount,
    paginatedUserPropertiesLoading: userPropertiesLoading,
    paginatedUserPropertiesHasMore: userPropertiesHasMore,
    paginatedUserPropertiesNext: userPropertiesNext,
  };
};

const PropertyPortfolioBase = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const priceCurrency = useCurrency();
  const convertEURtoCHF = useConvertEURtoCHF();
  const themeContext = useTheme();
  const [searchInput, setSearchInput] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [showClearSearch, setShowClearSearch] = useState(false);
  const [filters, setFilters] = useState<IPropertyPortfolioFilterFormField[]>(
    []
  );

  const defaultTitleTranslation = t('property-portfolio.property-card.title');
  const propertyIndexForScrolling = useAppSelector(
    (state) => state.dynamicMapUtils.propertyIndexForScrolling
  );

  const ownerId = useAppSelector((state) => state.auth.user?._id);

  const { userProperties } = useUserProperties({
    ownerId,
    order,
    defaultTitleTranslation,
  });
  const {
    paginatedUserProperties,
    paginatedUserPropertiesCount,
    paginatedUserPropertiesHasMore,
    paginatedUserPropertiesLoading,
    paginatedUserPropertiesNext,
  } = usePaginatedUserProperties({
    defaultTitleTranslation,
    allProperties: userProperties,
    ownerId,
    filters,
    search,
    order,
  });

  const [lastPropertyIndexForScrolling, setLastPropertyIndexForScrolling] =
    useState<number | null>(null);

  useEffect(() => {
    if (
      userProperties.length === 0 ||
      propertyIndexForScrolling === undefined ||
      propertyIndexForScrolling === null
    ) {
      return;
    }

    if (propertyIndexForScrolling === lastPropertyIndexForScrolling) {
      dispatch(
        changeViewport({
          ...calculateBestMapView(
            userProperties.map(
              (property) => property.propertyData.location.coordinates
            ),
            1200,
            640
          ),
          transitionDuration: 1000,
        })
      );
    } else {
      const { latitude, longitude } =
        userProperties[propertyIndexForScrolling].propertyData.location
          .coordinates;

      dispatch(
        changeViewport({
          ...calculateBestMapView([{ latitude, longitude }], 1200, 640),
          transitionDuration: 1000,
        })
      );
    }

    setLastPropertyIndexForScrolling(propertyIndexForScrolling);
    dispatch(scrollToProperty(null));
  }, [
    dispatch,
    lastPropertyIndexForScrolling,
    propertyIndexForScrolling,
    userProperties,
  ]);

  const filterButton = useRef<FilterButtonRef>(null);

  const { valuationType, isValuationWizardOpen } = useAppSelector(
    (state) => state.valuationWizard
  );

  useEffect(() => {
    return () => {
      dispatch(removeAllMarkers());
    };
  }, [dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(
        changeViewport({
          ...calculateBestMapView(
            userProperties.map(
              (property) => property.propertyData.location.coordinates
            ),
            1200,
            640
          ),
          transitionDuration: 1000,
        })
      );
      dispatch(
        setSettings({
          ...defaultMapSettings,
          dragPan: true,
          dragRotate: true,
          scrollZoom: true,
          touchZoom: true,
          touchRotate: true,
        })
      );
      dispatch(
        addMarkers(
          userProperties.map((property, index) => ({
            longitude:
              property.propertyData.location.coordinates.longitude ?? 0,
            latitude: property.propertyData.location.coordinates.latitude ?? 0,
            category: 'property',
            marker: 'similarProperty',
            index,
            info: getPropertyTitle(property) || property.defaultTitle,
          }))
        )
      );
    });
  }, [dispatch, userProperties]);

  const propertiesPrice = useMemo(() => {
    const price: number = userProperties.reduce(
      (res: number, property) => res + calculatePropertyPrice(property),
      0
    );

    return priceCurrency === ' CHF'
      ? `${formatNumber(convertEURtoCHF(price))}${priceCurrency}`
      : `${formatNumber(price)}${priceCurrency}`;
  }, [userProperties, convertEURtoCHF, priceCurrency]);

  // const defaultFilters = usePropertyPortfolioDefaultFilters();
  // const filtersActive = usePropertyPortfolioFiltersActive(
  //   defaultFilters,
  //   filters
  // );

  // useEffect(() => {
  //   setFilters(defaultFilters);
  // }, [defaultFilters]);

  type PropertyById = Record<string, IPropertyPortfolio>;

  const [propertyById, setPropertyById] = useState<PropertyById>({});

  const initPropertyById = useCallback(
    (properties: IPropertyPortfolio[], override?: boolean) => {
      setPropertyById((currentPropertyById) => {
        const newPropertyById: PropertyById = {
          ...currentPropertyById,
        };

        // eslint-disable-next-line no-restricted-syntax
        for (const property of properties) {
          if (!override && currentPropertyById[property._id]) {
            // eslint-disable-next-line no-continue
            continue;
          }

          newPropertyById[property._id] = property;
        }

        return newPropertyById;
      });
    },
    []
  );

  useEffect(() => {
    initPropertyById(paginatedUserProperties);
  }, [initPropertyById, paginatedUserProperties]);

  const itemContent = useCallback(
    (id: string) => {
      const onPropertyChange = (property: IPropertyPortfolio) => {
        initPropertyById([property], true);
      };

      if (!propertyById[id]) {
        return <></>;
      }

      return (
        <PortfolioProperty
          property={propertyById[id]}
          onPropertyChange={onPropertyChange}
        />
      );
    },
    [initPropertyById, propertyById]
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (showClearSearch) {
        setShowClearSearch(false);
      }
      setSearchInput(event.target.value);
    },
    [showClearSearch]
  );

  const onClearSearch = useCallback(() => {
    setSearch('');
    setSearchInput('');
    setShowClearSearch(false);
  }, []);

  const onConfirmSearch = useCallback(() => {
    setSearch(searchInput);
    setShowClearSearch(true);
  }, [searchInput]);

  const onApplyFilters = useCallback((data) => {
    setFilters(data);
  }, []);

  // const onResetFilters = useCallback(() => {
  //   setFilters(defaultFilters);
  // }, [defaultFilters]);

  const resetFilters = useCallback(() => {
    if (filterButton.current) {
      filterButton.current.resetFilters();
    }
  }, []);

  return (
    <>
      <>
        {!isMobileSize && (
          <Hero>
            <Mapbox isVisible />

            <HeroSlider topOffset={-56} showAddressBar={false}>
              <PropertyPortfolioOverviewCard
                header="property-portfolio-overview-card.header"
                title="property-portfolio-overview-card.title"
                content="property-portfolio-overview-card.content"
                properties={userProperties.length}
                priceLabel={propertiesPrice}
              />
            </HeroSlider>
            <PropertyValuationWidgetContainer
              pageType={PageType.propertyPortfolio}
            />
          </Hero>
        )}
      </>
      <>
        {isMobileSize && (
          <MobileHeadContainer>
            <PropertyPortfolioOverviewCard
              header="property-valuation-overview-card.header"
              title="property-valuation-overview-card.title"
              content="property-valuation-overview-card.content"
              properties={userProperties.length}
              priceLabel="€14.570.000"
            />

            <CTAButtons
              skipBorderRadius
              pageType={PageType.propertyPortfolio}
            />

            {isValuationWizardOpen &&
              valuationType === ValuationType.Online && (
                <OnlineValuationContainer />
              )}
            {isValuationWizardOpen &&
              valuationType === ValuationType.Personal && (
                <PersonalValuationContainer />
              )}
          </MobileHeadContainer>
        )}
      </>
      <ContentWithPadding>
        <Header>
          <FlexContainer>
            <Title content={t('property-portfolio.content.title')} />
            <Count>{paginatedUserPropertiesCount}</Count>
          </FlexContainer>
          <FilterContent>
            {/* {filtersActive && ( */}
            {/*  <FilterResetBox> */}
            {/*    <FilterResetBoxContent> */}
            {/*      <Icon */}
            {/*        icon={WarningIcon} */}
            {/*        width={16} */}
            {/*        height={16} */}
            {/*        color="currentColor" */}
            {/*      /> */}
            {/*      <FilterResetBoxText */}
            {/*        content={t('property-portfolio.reset-filter.text')} */}
            {/*      /> */}
            {/*    </FilterResetBoxContent> */}
            {/*    <ClearFilterButton */}
            {/*      style={{ */}
            {/*        padding: '2px 24px', */}
            {/*        fontSize: '10px', */}
            {/*        height: 'auto', */}
            {/*      }} */}
            {/*      label={t('marketing-dashboard.filter.clear')} */}
            {/*      onClick={resetFilters} */}
            {/*      borderColor={themeContext.blue} */}
            {/*      color={themeContext.blue} */}
            {/*      type={'button'} */}
            {/*    /> */}
            {/*  </FilterResetBox> */}
            {/* )} */}

            <ActionsContent>
              <SearchField>
                <InputSearch
                  placeholder={t(
                    'property-portfolio.content.search-placeholder'
                  )}
                  onChange={onSearchInputChange}
                  value={searchInput}
                  onConfirmSearch={
                    showClearSearch ? undefined : onConfirmSearch
                  }
                  onClearSearch={showClearSearch ? onClearSearch : undefined}
                  containerStyle={{ marginTop: 0 }}
                />
              </SearchField>
              {/* <SortButton */}
              {/*  order={order} */}
              {/*  onChangeOrder={(e) => { */}
              {/*    setOrder(e); */}
              {/*  }} */}
              {/* /> */}
              {/* <FilterButton */}
              {/*  ref={filterButton} */}
              {/*  filters={filters} */}
              {/*  isActive={filtersActive} */}
              {/*  onSubmit={onApplyFilters} */}
              {/*  onReset={onResetFilters} */}
              {/* /> */}
            </ActionsContent>
          </FilterContent>
        </Header>
        <PropertiesContent>
          {paginatedUserPropertiesLoading ? (
            <ValuationLoader maxWidth="500px" />
          ) : (
            <>
              {paginatedUserProperties.length === 0 ? (
                <>
                  {paginatedUserPropertiesLoading ? (
                    <>
                      <ValuationLoader maxWidth="500px" />
                    </>
                  ) : (
                    <>
                      <PropertiesEmptyState>
                        <Icon
                          icon={EmptyStateIcon}
                          width={32}
                          height={32}
                          color="currentColor"
                        />
                        <PropertiesEmptyStateTitle
                          content={t('property-portfolio-empty-state.title')}
                        />
                        <PropertiesEmptyStateText
                          content={
                            search
                              ? t('property-portfolio-search-empty-state.text')
                              : t('property-portfolio-filter-empty-state.text')
                          }
                        />
                      </PropertiesEmptyState>
                    </>
                  )}
                </>
              ) : (
                <>
                  <ListContainer>
                    {paginatedUserProperties.map((item) => (
                      <ItemContainer key={item._id}>
                        {itemContent(item._id)}
                      </ItemContainer>
                    ))}
                  </ListContainer>

                  {paginatedUserPropertiesLoading ? (
                    <>
                      <LoaderContainer isLoadingMore>
                        <GlobalLoader size={50} isAbsolute />
                      </LoaderContainer>
                    </>
                  ) : (
                    <>
                      {paginatedUserPropertiesHasMore && (
                        <LoadMoreButtonContainer isMobileSize={isMobileSize}>
                          <LoadMoreButton
                            fluid
                            onClick={paginatedUserPropertiesNext}
                            label={t('button.load-more')}
                            color={themeContext.blue}
                            borderColor={themeContext.blue}
                          />
                        </LoadMoreButtonContainer>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </PropertiesContent>
      </ContentWithPadding>
    </>
  );
};

const PropertyPortfolioPage = memo(PropertyPortfolioBase);

export { PropertyPortfolioPage };
