import { useTranslation } from 'react-i18next';

import {
  RatingDotsWrapperBorder,
  FlexColumnWrapper,
  FlexColumnWrapperCentered,
  FlexRowWrapper,
  MainRating,
  MainTitle,
  RatingDotsWrapper,
  MarginWrapper,
  LeftDotPart,
  RightDotPart,
  PaddingWrapper,
  MainLocation,
  SpaceBetweenWrapper,
  FlexRowColumnWrapper,
  MainAmount,
  FlexRowWrapperLeft,
} from './micro-situation-components-styles';

interface IMainHeader {
  title: string;
  location?: string;
  icon: unknown;
  rating?: number;
  totalBuildings?: string;
  color?: string;
  isShrinked: boolean;
  noConstuctions?: boolean;
}

interface ISimpleRatingDots {
  rating: number;
  color: string;
}

export const getPercent = (value: number, index: number): number => {
  switch (true) {
    case value >= index:
      return 100;
    case value > index - 1 && value < index:
      return Math.round((value % 1) * 100);
    default:
      return 0;
  }
};

const SimpleRatingDots = ({
  rating,
  color,
}: ISimpleRatingDots): JSX.Element => {
  return (
    <>
      {[...Array.from({ length: 5 })].map((_, index: number) => {
        return (
          <RatingDotsWrapperBorder
            key={`rd-${index}`}
            percentage={getPercent(rating, index + 1)}
            backgroundColor={color}
          >
            <RatingDotsWrapper>
              <LeftDotPart
                percentage={getPercent(rating, index + 1)}
                backgroundColor={color}
              />
              <RightDotPart
                percentage={100 - getPercent(rating, index + 1)}
                backgroundColor={'#fff'}
              />
            </RatingDotsWrapper>
          </RatingDotsWrapperBorder>
        );
      })}
    </>
  );
};

export const MainHeader = ({
  title,
  location,
  icon,
  rating,
  totalBuildings,
  color,
  isShrinked,
  noConstuctions,
}: IMainHeader): JSX.Element => {
  const { t } = useTranslation();
  return (
    <SpaceBetweenWrapper
      isConstructions={!!totalBuildings}
      noConstuctions={noConstuctions}
    >
      <FlexRowWrapper>
        <FlexRowWrapperLeft>
          <MarginWrapper margin={'0 12px 0 0'}>
            <img src={icon as string} alt={title} width={28} height={32} />
          </MarginWrapper>
        </FlexRowWrapperLeft>
        <FlexColumnWrapper>
          <MainTitle isShrinked={isShrinked} content={title} />
          <FlexRowWrapper>
            {location ? (
              <>
                <MainLocation
                  content={t('expose-page.tabs.overview.micro-situation.score')}
                  margin={'0 4px 0 0'}
                />
                <MainLocation fontWeight={900} content={`  ${location}`} />
              </>
            ) : (
              <MainLocation content={'Projekte im Radius 5 km gefunden'} />
            )}
          </FlexRowWrapper>
        </FlexColumnWrapper>
      </FlexRowWrapper>
      <PaddingWrapper padding={'0'}>
        {rating && color && (
          <FlexColumnWrapperCentered>
            <MainRating color={color} content={rating?.toFixed(1)} />
            <FlexRowColumnWrapper>
              <SimpleRatingDots rating={rating} color={color} />
            </FlexRowColumnWrapper>
          </FlexColumnWrapperCentered>
        )}
        {totalBuildings && <MainAmount content={totalBuildings} />}
      </PaddingWrapper>
    </SpaceBetweenWrapper>
  );
};
