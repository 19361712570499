import { forwardRef } from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation } from 'swiper';
import { SliderNavButton } from './nav-button';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import {
  SliderContainer,
  SliderImage,
} from './financing-gallery-slider-styles';
import { VonPollPropertyImage } from '../../../../../generated';
import { Mapbox } from '../../../../map/components/mapbox';
import { toggleFinancingPortalModalGallery } from '../../../../forms/form-financing/redux/financingSlice';
import { useAppDispatch } from '../../../../common/hooks';

SwiperCore.use([Navigation]);

interface IProps {
  isGallery?: boolean;
  isPortrait?: boolean;
  isWithMap: boolean;
  images: VonPollPropertyImage[];
  activeSlide: number;
  onClickNavButton: (isPrev: boolean) => () => unknown;
  hideFirstImageBanners?: boolean;
}

export const FinancingGallerySlider = forwardRef<unknown, IProps>(
  (
    {
      isGallery,
      isWithMap,
      isPortrait,
      activeSlide,
      images,
      hideFirstImageBanners,
      onClickNavButton,
    },
    swiperRef
  ) => {
    const dispatch = useAppDispatch();
    const isMobileSize = useIsMobileSize();
    const onClickGalleryModal = () => {
      dispatch(toggleFinancingPortalModalGallery(true));
    };

    return (
      <SliderContainer isGallery={isGallery}>
        {activeSlide !== 0 && (
          <SliderNavButton
            isGallery={isGallery}
            onClick={onClickNavButton(true)}
            isPrev
          />
        )}
        {activeSlide < images.length - (isWithMap ? 0 : 1) && (
          <SliderNavButton
            isGallery={isGallery}
            onClick={onClickNavButton(false)}
          />
        )}
        <Swiper
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={swiperRef}
          id="controller"
          allowTouchMove
          tag="section"
          wrapperTag="ul"
          speed={600}
          initialSlide={activeSlide}
        >
          {isWithMap && (
            <SwiperSlide key={`map`}>
              <div
                className={
                  isGallery && !isMobileSize ? 'drag-wrapper' : 'wrapper'
                }
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <Mapbox isVisible />
              </div>
            </SwiperSlide>
          )}
          {images.map((item, i) => {
            return (
              <SwiperSlide key={`${item}${i}`}>
                <div
                  className={
                    isGallery && !isMobileSize ? 'drag-wrapper' : 'wrapper'
                  }
                  style={{
                    overflow: 'hidden',
                    ...(i === 0 &&
                      hideFirstImageBanners && {
                        position: 'absolute',
                        top: '-11vw',
                        left: 0,
                        right: 0,
                        bottom: '-5vw',
                      }),
                    ...(!(i === 0 && hideFirstImageBanners) && {
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }),
                  }}
                >
                  <SliderImage
                    onClick={onClickGalleryModal}
                    src={`https://erp.von-poll.com/import/images/${item.link}`}
                    alt={item.alt}
                    isPortrait={
                      isPortrait ||
                      item.group === 'GRUNDRISS' ||
                      (item.width ?? 0) < (item.height ?? 0)
                    }
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderContainer>
    );
  }
);
