import styled from 'styled-components';

export const Container = styled.div``;

export const InfoContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
  & > div {
    margin-bottom: 0 !important;
  }
`;
