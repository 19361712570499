import { FC, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import { Poi, Property, PropertyPoi } from '../../../../../../../generated';

import { Infrastructure } from '../../../../../../common/components/infrastructure/infrastructure';
import { useExposeDetailsPriceHubble } from '../../../hooks/useExposeDetailsQueries';
import { removeAllMarkersExceptHome } from '../../../../../../map/redux/mapSlice';
import { clearPropertiesCache } from '../../../../../../property/redux/similarPropertiesSlice';
import { useMapInfrastructure } from './useMapInfrastructure';
import { addMapMarkers, removeMapMarkers } from '../../../redux/exposeSlice';

type PropertyPoiWithIndex = PropertyPoi & {
  [key: string]: Record<string, Poi[]>;
};

interface IProps {
  property: Partial<Property>;
}

const InfrastructurePropertyWrapper: FC<IProps> = ({ property }) => {
  // const { poi, location, locationScores } = useExposeDetailsPriceHubble();
  const { poi, locationScores, propertyData } = property;
  // const { location } = propertyData;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(removeAllMarkersExceptHome());
        dispatch(clearPropertiesCache());
      });
    };
  }, [dispatch]);
  const { currentMapboxMarkers } = useMapInfrastructure({
    location: propertyData?.location,
    propertyPois: poi as PropertyPoiWithIndex,
  });

  return (
    <Infrastructure
      isShrinked
      isNewScoreFormat
      propertyPois={poi as PropertyPoiWithIndex}
      locationScores={locationScores}
      currentMapboxMarkers={currentMapboxMarkers}
      addMarkers={addMapMarkers}
      removeMarkers={removeMapMarkers}
    />
  );
};

export { InfrastructurePropertyWrapper };
