import styled from 'styled-components';
import logoDE from '../../../../../assets/logo.svg';
import logoCH from '../../../../../assets/logo1.svg';
import { device } from '../../../../../style/theme';
import useGetLocalization from '../../../../localization/get-localization';

const Container = styled.a`
  && {
    display: flex;
    flex: 0 0 auto;
  }
`;

const LogoWrapper = styled.div`
  max-width: 143px;
  width: 143px;
  max-height: 41px;

  @media ${device.bigScreen} {
    max-width: 133px;
    width: 133px;
    height: 39px;
  }

  @media ${device.laptop} {
    max-width: 100px;
    width: 100px;
    height: 29px;
  }

  @media ${device.tablet} {
    max-width: 133px;
    width: 133px;
    height: 39px;
  }
`;

const StyledLogo = styled.img`
  width: 100%;
  height: auto;
`;

const Logo = (): JSX.Element => {
  const lang = useGetLocalization();

  const icon = lang === 'de-ch' ? logoCH : logoDE;

  return (
    <Container href="http://von-poll.com/">
      <LogoWrapper>
        <StyledLogo src={icon} />
      </LogoWrapper>
    </Container>
  );
};

export { Logo };
