import { ReactComponent as Heart } from 'assets/streamline-light/social-medias-rewards-rating/love-it/love-it.svg';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as HouseChimney1 } from 'assets/streamline-light/interface-essential/home/house-chimney-1.svg';
import { ReactComponent as DocumentSettings } from 'assets/streamline-light/files-folders/common-files/common-file-text-settings.svg';
import { easeCubic } from 'd3-ease';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Helmet } from 'react-helmet-async';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { AddressBar } from '../../../common/components/ui/hero-slider/address-bar';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import { Europe } from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import {
  clearSuggestedPropertiesCache,
  setFilters,
  filtersInitialState,
} from '../../redux/suggestedPropertiesSlice';
import { MultipleSearchProfiles } from '../../components/multiple-search-profiles/multiple-search-profiles';
import PurchaseOffers from '../../components/tabs/purchase-offers';
import { LngRedirect } from '../../../localization/lng-redirect';
import {
  IFrameContainer,
  MobileHeadContainer,
  MobileMapContainer,
  ContentWithPadding,
  HeaderWrapper,
} from './search-profile-page-styles';
import { SearchProfileWidgetContainer } from '../../../forms/form-search-profile/components/containers/search-profile-widget-container/search-profile-widget-container';
import { SearchProfileOverviewCard } from '../../components/card/search-profile-overview-card/search-profile-overview-card';
import { HeaderTab } from '../../../property/components/header/valuation-header-tab';
import { VariationHeaderActions } from '../../components/header/variation-header-actions';
import { setSelectedTab } from '../../../property/redux/dynamicMapUtilsSlice';
import { Drawer } from '../../../property/components/drawer/drawer';
import { CTAButtons } from '../../../forms/form-search-profile/components/containers/search-profile-widget-container/cta-buttons/cta-buttons';
import { SearchProfileContainer } from '../../../forms/form-search-profile/components/containers/search-profile/search-profile-container';
import { EditSearchProfileModal } from '../../../forms/form-search-profile/components/modals/edit-search-profile-modal';
import { SuggestedProperties } from '../../components/tabs/suggested-properties';
import { OnlineExposes } from '../../components/tabs/online-exposes';
import { Favourites } from '../../components/tabs/favourites';
import { NotificationsSearchProfileModal } from '../../../forms/form-search-profile/components/modals/notifications-search-profile-modal';
import { useCheckSearchParams } from '../../hooks/useCheckSearchParams';
import { toggleFloatingBar } from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { useGetCountry } from '../../../localization/get-country';
import { defaultMapSettings } from '../../../map/constants';
import { useGetSearchProfile } from '../../hooks/useGetSearchProfile';
import { useGetSearchProfiles } from '../../hooks/useGetSearchProfiles';

const SearchProfilePageBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  useCheckSearchParams();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const { t } = useTranslation();
  const { getCountry } = useGetCountry();

  const selectedTab = useAppSelector(
    (state) => state.dynamicMapUtils.selectedTab
  );
  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const isSPCOpen = useAppSelector((state) => state.searchProfile.isSPCOpen);
  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress?.place_name
  );
  const activeStep = useAppSelector((state) => state.searchProfile.activeStep);
  const floatingBar = useAppSelector(
    (state) => state.valuationWizard.floatingBar
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const userId = useAppSelector((state) => state.auth.user?._id);

  const {
    overviewProps,
    searchProfileAddress,
    isSearchProfileLoading,
    isFetchingDone,
    searchProfileErrors,
    searchProfileCoordinates,
    countryCode,
    newPropertiesCount,
  } = useGetSearchProfile({ searchProfileId });

  const { isDeAtCh } = getCountry(countryCode);

  const { searchProfilesData, searchProfilesIsLoading, searchProfilesError } =
    useGetSearchProfiles({ userId });

  useEffect(() => {
    dispatch(toggleFloatingBar(false));
  }, [dispatch, floatingBar]);

  // init map
  useEffect(() => {
    dispatch(
      changeViewport({
        ...Europe,
        transitionDuration: 1000,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setFilters(filtersInitialState));
    dispatch(setSelectedTab(0));
    return () => {
      batch(() => {
        dispatch(setFilters(filtersInitialState));
        dispatch(clearSuggestedPropertiesCache());
      });
    };
    // batch(() => {
    //   // dispatch(clearSuggestedPropertiesCache());
    //   dispatch(setSelectedTab(0));
    // });
  }, [dispatch, searchProfileId]);

  // map
  useEffect(() => {
    setTimeout(() => {
      if (searchProfileCoordinates && !isSPCOpen) {
        batch(() => {
          if (selectedTab === 2) {
            dispatch(
              setSettings({
                dragPan: true,
                dragRotate: true,
                scrollZoom: true,
                touchZoom: true,
                touchRotate: true,
                keyboard: true,
                doubleClickZoom: true,
                radius: overviewProps.radius,
              })
            );
          } else {
            dispatch(
              setSettings({
                ...defaultMapSettings,
                radius: overviewProps.radius,
              })
            );
          }
          dispatch(
            changeViewport({
              longitude:
                (Number(searchProfileCoordinates?.longitude) ?? 0) + 0.005,
              latitude:
                (Number(searchProfileCoordinates?.latitude) ?? 0) - 0.001,
              zoom: 12,
              transitionDuration: 'auto',
              transitionInterpolator: new FlyToInterpolator({
                speed: 1.8,
              }),
              transitionEasing: easeCubic,
            })
          );
          dispatch(setMarkers([searchProfileCoordinates]));
        });
      }
    }, 0);
  }, [
    selectedTab,
    searchProfileCoordinates,
    isSPCOpen,
    dispatch,
    overviewProps.radius,
  ]);

  if (isSearchProfileLoading || searchProfilesIsLoading) {
    return <GlobalLoader />;
    // return <ValuationLoader maxWidth="500px" />;
  }

  if (
    !isSearchProfileLoading &&
    (searchProfileErrors ||
      (isFetchingDone && !searchProfileAddress) ||
      searchProfilesError)
  ) {
    return <LngRedirect to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>{t('buyer-portal.title')}</title>
      </Helmet>
      {isIframe && (
        <IFrameContainer>
          <SearchProfileContainer />
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          {searchProfilesData.length > 1 && (
            <MultipleSearchProfiles
              searchProfilesData={searchProfilesData}
              activeId={searchProfileId}
            />
          )}
          {/* <PropertiesTab shouldShrink={isTabListNearTop} /> */}
          <>
            {!isMobileSize ? (
              <Hero>
                <Mapbox
                  isVisible
                  // coordinates={searchProfileCoordinates}
                />
                <HeroSlider
                  showAddressBar={isSPCOpen ? activeStep > 0 : true}
                  topOffset={-56}
                  address={isSPCOpen ? selectedAddress : searchProfileAddress}
                  addressBarTitle={overviewProps.title}
                  addressBarRadiusInfo={`+${overviewProps.radius} km`}
                  newPropertiesCount={newPropertiesCount}
                  code={overviewProps.code}
                  isSPCreatedViaAnotherPortal={
                    overviewProps.isCreatedVia3rdPartyPortal
                  }
                >
                  <SearchProfileOverviewCard
                    header="search-profile-overview-card.header"
                    content="search-profile-overview-card.content"
                    {...overviewProps}
                    title="search-profile-overview-card.title"
                  />
                  <Card
                    titleFirstLine={t(
                      'search-profile-page.hero-slider.first-card.header'
                    )}
                    content={t(
                      'search-profile-page.hero-slider.first-card.content'
                    )}
                    showAddressBar={isSPCOpen ? activeStep > 0 : true}
                  />
                  <Card
                    titleType="h2"
                    titleFirstLine={t(
                      'search-profile-page.hero-slider.second-card.online.title.first-line'
                    )}
                    content={t(
                      'search-profile-page.hero-slider.second-card.online.content'
                    )}
                    showAddressBar={isSPCOpen ? activeStep > 0 : true}
                  />
                </HeroSlider>
                <SearchProfileWidgetContainer />
              </Hero>
            ) : (
              <MobileHeadContainer>
                <SearchProfileOverviewCard
                  header="search-profile-overview-card.header"
                  content="search-profile-overview-card.content"
                  {...overviewProps}
                  title="search-profile-overview-card.title"
                />
                <AddressBar
                  title={overviewProps.title}
                  radiusInfo={`+${overviewProps.radius} km`}
                  show={isSPCOpen ? activeStep > 0 : true}
                  address={isSPCOpen ? selectedAddress : searchProfileAddress}
                  showEditSPButton={overviewProps.isCreatedVia3rdPartyPortal}
                />
                <MobileMapContainer>
                  <Mapbox isVisible />
                </MobileMapContainer>

                <Drawer
                  drawerTopContent={<CTAButtons />}
                  drawerBottomContent={
                    <VariationHeaderActions searchProfileId={searchProfileId} />
                  }
                  drawerBottomButtonFlex=""
                  drawerBottomContainerPadding=""
                />

                {isSPCOpen && <SearchProfileContainer />}
              </MobileHeadContainer>
            )}
          </>
          <ContentWithPadding>
            <Tabs
              selectedIndex={selectedTab}
              onSelect={(index) => {
                dispatch(setSelectedTab(index));
              }}
            >
              <HeaderWrapper
                isTabListNearTop={isTabListNearTop}
                floatingBar={floatingBar}
                noMultipleSearchProfiles={searchProfilesData.length <= 1}
              >
                <TabList style={{ height: '100%', position: 'relative' }}>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('search-profile-navigation.first-tab')}
                      icon={HouseChimney1}
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('search-profile-navigation.second-tab')}
                      icon={Heart}
                    />
                  </Tab>
                  {isDeAtCh && (
                    <Tab tabIndex="0">
                      <HeaderTab
                        title={t('search-profile-navigation.third-tab')}
                        icon={CommonFileText}
                      />
                    </Tab>
                  )}
                  {/* <Tab tabIndex="0"> */}
                  {/*  <HeaderTab */}
                  {/*    title={t('search-profile-navigation.fourth-tab')} */}
                  {/*    icon={Calendar} */}
                  {/*  /> */}
                  {/* </Tab> */}
                  {isDeAtCh && (
                    <Tab tabIndex="0">
                      <HeaderTab
                        title={t('search-profile-navigation.fifth-tab')}
                        icon={DocumentSettings}
                      />
                    </Tab>
                  )}
                </TabList>
                <>
                  {!isMobileSize && (
                    <VariationHeaderActions searchProfileId={searchProfileId} />
                  )}
                </>
              </HeaderWrapper>
              <TabPanel>
                <SuggestedProperties />
              </TabPanel>
              <TabPanel>
                <Favourites />
              </TabPanel>
              {isDeAtCh && (
                <TabPanel>
                  <OnlineExposes />
                </TabPanel>
              )}

              {/* <TabPanel> */}
              {/*  <Appointments /> */}
              {/* </TabPanel> */}
              {isDeAtCh && (
                <TabPanel>
                  <PurchaseOffers />
                </TabPanel>
              )}
            </Tabs>
          </ContentWithPadding>
          <EditSearchProfileModal />
          <NotificationsSearchProfileModal />
        </>
      )}
    </>
  );
};

const SearchProfilePage = memo(SearchProfilePageBase);

export { SearchProfilePage };
