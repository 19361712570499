import {
  ChangeEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { device } from 'style/theme';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as Business1 } from 'assets/streamline-light/building-construction/buildings/building-1.svg';
import { ReactComponent as HouseGarage } from 'assets/streamline-light/building-construction/houses/house-garage.svg';
import { ReactComponent as BuildingYear } from 'assets/streamline-light/building-construction/utilities/building-year.svg';
import { ReactComponent as CashShield } from 'assets/streamline-light/business-products/business/cash-shield.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as Energy } from 'assets/streamline-light/ecology/renewable-energy/renewable-energy-charging.svg';
import { ReactComponent as HotelDoubleBed2 } from 'assets/streamline-light/hotels-spas/hotel-bedrooms/hotel-double-bed-2.svg';
import { ReactComponent as Pen } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/remove-circle.svg';
import { ReactComponent as AccountingBills } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as RoadSignParking } from 'assets/streamline-light/transportation/signs/road-sign-parking.svg';

import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import {
  PropertyCode,
  PropertyStatus,
  UserStatus,
  useUpdatePropertyMutation,
} from 'generated';
import { Tooltip } from 'modules/common/components/tooltip';
import {
  FlatButton,
  MainButton,
  SecondaryButton,
  WhiteButton,
} from 'modules/common/components/ui/buttons';
import { ParagraphTitle } from 'modules/common/components/ui/typography';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';
import { hexToRGB } from 'modules/common/utils/hex-to-rgb';
import { InputField } from 'modules/forms/components/common/input-v2/input-v2-styles';
import { IPropertyPortfolio } from 'modules/property/interfaces';
import { implodeAddress } from 'modules/property/utils/address';
import { extractPropertyTypeLabelAndIcon } from 'modules/property/utils/property-type-normalizer';
import { Mapbox } from 'modules/seller/components/map/map';
import { formatPrice } from 'modules/seller/utils/formatPrice';
import { siteMap } from 'routes/site-map';

import { getFileExtension } from 'modules/common/components/form/input/input-file/utils/getFileExtension';
import { WarningModal } from 'modules/common/components/modal/warning-modal';
import { useConvertEURtoCHF } from 'modules/common/utils/convertCHF';
import { getCurrencyByLocale } from 'modules/localization/constants';
import useGetLocalization from 'modules/localization/get-localization';
import { getPropertyTitle } from 'modules/property/utils/property.utils';
import { toast } from 'react-toastify';
import { useUpdatePropertyPortfolioMutation } from '../../../../services/graphql/enhanced';
import Icon from '../../../common/components/ui/icon';
import PropertyValuationIcon from '../icon/property-valuation-icon';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  min-height: 100%;

  @media ${device.tablet} {
    max-width: none;
  }
`;

const ImageWrapper = styled.div<{ isMobileSize?: boolean }>`
  height: ${(props) => (props.isMobileSize ? '160px' : '256px')};
  /* padding-top: calc(152 / 306 * 100%); */
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ActionsContent = styled.div`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .tooltip-container {
    .tooltip-inner-container {
      width: 100%;
    }
  }
`;

const Title = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  @media ${device.mobile} {
    white-space: normal;
  }
`;

export const TooltipTitle = styled(ParagraphTitle)`
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 24px;
  max-width: 320px;
  overflow-wrap: break-word;
`;

const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
}>`
  display: flex;
  align-self: stretch;
  .icon {
    min-width: 24px;
    min-height: 24px;
  }
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
`;

const Price = styled.span`
  padding: 12px;
  background: ${(props) => props.theme.greyBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .property-valuation-icon {
    flex: inherit;
  }

  .property-valuation-icon-value {
    font-size: 20px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  flex: 1;
  border-top: 1px solid ${(props) => props.theme.grey};
  padding: 16px 0;
  width: 100%;
`;

const IconRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const Location = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  height: 20px;
  opacity: 0.8;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-left: 6px;

  @media ${device.mobile} {
    white-space: normal;
    height: auto;
    max-width: none;
  }
`;

const LocationIcon = styled(Icon)`
  &&& > svg {
    max-height: 16px;
  }
`;

const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: unset;
`;

const MapWrapper = styled.div<{ isMobileSize?: boolean }>`
  position: relative;
  height: ${(props) => (props.isMobileSize ? '160px' : '256px')};
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
`;

const CoverButton = styled(WhiteButton)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  border: 1px solid ${(props) => props.theme.borderColor};
  padding: 10px 16px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  width: 100%;

  > .tooltip-container {
    min-width: 0;

    .tooltip-inner-container {
      width: 100%;
    }
  }

  @media ${device.mobile} {
    input {
      min-width: 0;
    }
  }
`;

const InputWrap = styled.div`
  position: relative;

  @media ${device.mobile} {
    flex: 1;
  }
`;

const IconTrigger = styled.a`
  cursor: pointer;
`;

const SaveButton = styled(FlatButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;
`;

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 12px;

  > * {
    flex: 1;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const BaseMainButton = styled(MainButton)`
  text-transform: uppercase;
  height: 40px;
  padding: 10px;
  justify-content: center;

  @media ${device.mobile} {
    font-size: 11px;

    grid-column: span 2 / span 2;
  }
`;

const BaseSecondaryButton = styled(SecondaryButton)`
  text-transform: uppercase;
  height: 40px;
  padding: 10px;
  justify-content: center;

  @media ${device.mobile} {
    font-size: 11px;

    grid-column: span 2 / span 2;
  }
`;

const EvaluationButton = styled(BaseMainButton)`
  grid-column: span 2 / span 2;
  width: 100%;

  ${(props) =>
    !props.isDisabled &&
    `
    background-color: ${props.theme.blue};

    &:hover {
      background-color: ${hexToRGB(props.theme.blue, 0.9)};
    }
  `}
`;

const SalesSimulationButton = styled(BaseSecondaryButton)`
  border-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.blue};
  width: 100%;
`;

const StartSellingButton = styled(BaseMainButton)`
  width: 100%;
`;

const SaleOverviewButton = styled(BaseMainButton)`
  grid-column: span 2 / span 2;
`;

const fileValidation = {
  types: 'JPEG, JPG, PNG, WebP',
  maxSize: {
    value: 5242880,
    label: '5MB',
  },
};

const uploadErrorToast = (message: string) => {
  toast(message, { hideProgressBar: true, type: 'error' });
};

interface IProps {
  property: IPropertyPortfolio;
  onPropertyChange: (property: IPropertyPortfolio) => void;
}

const PortfolioPropertyBase: React.FC<IProps> = ({
  property,
  onPropertyChange,
}): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement | null>(null);
  const isMobileSize = useIsMobileSize();
  const [editTitle, setEditTitle] = useState(false);

  const isOwner = useMemo(() => {
    return property.userStatus === UserStatus.Owner;
  }, [property]);

  const isEditor = useMemo(() => {
    return property.userStatus === UserStatus.Editor;
  }, [property]);

  const isSpectator = useMemo(() => {
    return property.userStatus === UserStatus.Spectator;
  }, [property]);

  const title = getPropertyTitle(property);

  const [titleValue, setTitleValue] = useState('');

  const [coverImage, setCoverImage] = useState<File | undefined>();

  const [updatePropertyPortfolio] = useUpdatePropertyPortfolioMutation();

  const {
    buildingYear,
    propertyType,
    livingArea,
    landArea,
    numberOfRooms,
    numberOfBathrooms,
    numberOfIndoorParkingSpaces,
    numberOfOutdoorParkingSpaces,
    numberOfUnits,
    annualRentIncome,
    location,
  } = property?.propertyData || {};

  const { _id: propertyId } = property || {};

  const lang = useGetLocalization();
  const convertEURtoCHF = useConvertEURtoCHF();
  const currency = getCurrencyByLocale(lang);

  const localizePrice = useCallback(
    (price: number): number => {
      const priceInCHF = convertEURtoCHF(price);
      return currency === 'CHF' ? priceInCHF : price;
    },
    [convertEURtoCHF, currency]
  );

  const address = useMemo(() => {
    return implodeAddress(location?.address) || '';
  }, [location]);

  const { code } = propertyType ?? {};

  const { icon, label } = extractPropertyTypeLabelAndIcon(code);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [warningModalText, setWarningModalText] = useState<string>('');

  const priceLabelValue = useMemo(() => {
    const price = localizePrice(property.price);

    switch (property.status) {
      case PropertyStatus.Initial:
      case PropertyStatus.SellingSimulation:
        return formatPrice(price, currency);

      case PropertyStatus.Selling:
        return property?.onOfficePrice
          ? formatPrice(price, currency)
          : t(
              'seller-properties-list.evaluation-price.in-consultation-with-broker'
            );

      case PropertyStatus.Sold:
        return t('seller-properties-list.sold.label').toLowerCase();

      default:
        return '';
    }
  }, [
    localizePrice,
    property.price,
    property.status,
    property?.onOfficePrice,
    currency,
    t,
  ]);

  const priceLabelType = useMemo(() => {
    switch (property.status) {
      case PropertyStatus.Initial:
      case PropertyStatus.SellingSimulation:
        return t('seller-properties-list.evaluation-price');

      case PropertyStatus.Selling:
      case PropertyStatus.Sold:
        return t('property-overview.price');

      default:
        return '';
    }
  }, [property.status, t]);

  const annualRentIncomeLabel = useMemo(() => {
    if (
      annualRentIncome === undefined ||
      annualRentIncome === null ||
      !Number.isFinite(annualRentIncome)
    ) {
      return '-';
    }

    return formatPrice(localizePrice(annualRentIncome), currency);
  }, [annualRentIncome, currency, localizePrice]);

  const imageSrc = coverImage
    ? URL.createObjectURL(coverImage)
    : property?.propertyPortfolioData?.coverImageUrl ??
      property?.mapboxImageUrl;

  const onCoverButtonClick = () => {
    if (ref.current) {
      ref.current?.click();
    }
  };

  const handleFileUpload = useCallback(
    async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files && files?.length) {
        const file = files[0];
        const ext = getFileExtension(file.name).toLowerCase();

        if (
          !fileValidation.types
            .split(', ')
            .map((e) => e.toLowerCase())
            .includes(ext)
        ) {
          uploadErrorToast(
            t('error.unsupported-file-formats', {
              formats: fileValidation.types,
            })
          );
          await new Promise<void>((resolve) => {
            resolve();
          });
          return;
        }

        if (file.size > fileValidation.maxSize.value) {
          uploadErrorToast(
            t('error.max-file-size', {
              value: fileValidation.maxSize.label,
            })
          );
          await new Promise<void>((resolve) => {
            resolve();
          });
          return;
        }

        setCoverImage(file);
        await updatePropertyPortfolio({
          input: {
            _id: propertyId,
            propertyPortfolioData: {
              coverImage: file,
              coverImageFilename: file.name,
            },
          },
        });
      }
    },
    [propertyId, t, updatePropertyPortfolio]
  );

  const saveTitle = useCallback(async () => {
    await updatePropertyPortfolio({
      input: {
        _id: propertyId,
        propertyPortfolioData: {
          title: titleValue.trim(),
        },
      },
    });
    setEditTitle(false);
    onPropertyChange({
      ...property,
      propertyPortfolioData: {
        ...property.propertyPortfolioData,
        title: titleValue.trim(),
      },
    });
  }, [
    onPropertyChange,
    property,
    propertyId,
    titleValue,
    updatePropertyPortfolio,
  ]);

  useEffect(() => {
    const escapeHandler = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return;
      }

      setEditTitle(false);
      setTitleValue(title || property.defaultTitle);
    };

    const enterHandler = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (!editTitle) {
        return;
      }

      saveTitle();
    };

    window.addEventListener('keydown', escapeHandler, true);
    window.addEventListener('keydown', enterHandler, true);

    return () => {
      window.removeEventListener('keydown', escapeHandler, true);
      window.removeEventListener('keydown', enterHandler, true);
    };
  }, [saveTitle, title, property.defaultTitle, editTitle]);

  const CoverSnippet = useMemo(() => {
    return () => {
      return (
        <>
          <CoverButton
            label={
              !imageSrc
                ? `+ ${t('property-portfolio.add-cover')}`
                : t('property-portfolio.update-cover')
            }
            onClick={onCoverButtonClick}
          />
          <input
            ref={ref}
            type="file"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </>
      );
    };
  }, [imageSrc, t, handleFileUpload]);

  const evaluationButtonClickHandler = useCallback(() => {
    window.open(
      `${siteMap.PropertyPage.pathWithoutParams}/${propertyId}`,
      '_blank'
    );
  }, [propertyId]);

  const evaluationButton = useCallback(
    (isDisabled?: boolean) => {
      return (
        <>
          <EvaluationButton
            label={t('property-portfolio.property-card.evaluation-button')}
            isDisabled={isDisabled}
            style={
              isMobileSize && isDisabled
                ? {
                    pointerEvents: 'all',
                    backgroundColor: themeContext.ctaDisabledBackground,
                    cursor: 'pointer',
                  }
                : {}
            }
            onClick={() => {
              if (isMobileSize && isDisabled) {
                setWarningModalText(
                  t('property-portfolio.not-available-option-tooltip')
                );
                setIsWarningModalOpen(true);
                return;
              }

              evaluationButtonClickHandler();
            }}
          />
        </>
      );
    },
    [
      evaluationButtonClickHandler,
      isMobileSize,
      t,
      themeContext.ctaDisabledBackground,
    ]
  );

  const [updateProperty] = useUpdatePropertyMutation();

  const salesSimulationButtonClickHandler = useCallback(async () => {
    if (property.status !== PropertyStatus.SellingSimulation) {
      await updateProperty({
        input: {
          _id: propertyId,
          status: PropertyStatus.SellingSimulation,
        },
      });
    }

    window.open(
      `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}?openTab=4`,
      '_blank'
    );
  }, [property.status, propertyId, updateProperty]);

  const saleSimulationButton = useCallback(
    (isDisabled?: boolean) => {
      return (
        <>
          <SalesSimulationButton
            label={t(
              'property-portfolio.property-card.sales-simulation-button'
            )}
            isDisabled={isDisabled}
            onClick={salesSimulationButtonClickHandler}
          />
        </>
      );
    },
    [salesSimulationButtonClickHandler, t]
  );

  const saleOverviewButtonClickHandler = useCallback(() => {
    window.open(
      `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}?openTab=3`,
      '_blank'
    );
  }, [propertyId]);

  const saleOverviewButton = useCallback(
    (isDisabled?: boolean) => {
      return (
        <>
          <SaleOverviewButton
            label={t('property-portfolio.property-card.sale-overview-button')}
            isDisabled={isDisabled}
            onClick={saleOverviewButtonClickHandler}
          />
        </>
      );
    },
    [saleOverviewButtonClickHandler, t]
  );

  const saleOverviewButtonWithTooltip = useCallback(() => {
    return (
      <>
        {!isMobileSize && (
          <Tooltip
            id="evaluation-button-tooltip"
            content={evaluationButton(true)}
          >
            <TooltipTitle
              content={t('property-portfolio.not-available-option-tooltip')}
            />
          </Tooltip>
        )}
        {isMobileSize && evaluationButton(true)}
        {saleOverviewButton()}
      </>
    );
  }, [evaluationButton, saleOverviewButton, t, isMobileSize]);

  const startSellingButtonClickHandler = useCallback(() => {
    window.open(
      `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}`,
      '_blank'
    );
  }, [propertyId]);

  const startSellingButton = useCallback(
    (isDisabled?: boolean) => {
      return (
        <>
          <StartSellingButton
            label={t('property-portfolio.property-card.start-selling-button')}
            isDisabled={isDisabled}
            style={
              isMobileSize && isDisabled
                ? {
                    pointerEvents: 'all',
                    backgroundColor: themeContext.ctaDisabledBackground,
                    cursor: 'pointer',
                  }
                : {}
            }
            onClick={() => {
              if (isMobileSize && isDisabled) {
                setWarningModalText(
                  t('property-portfolio.missing-permissions-tooltip')
                );
                setIsWarningModalOpen(true);
                return;
              }

              startSellingButtonClickHandler();
            }}
          />
        </>
      );
    },
    [
      isMobileSize,
      startSellingButtonClickHandler,
      t,
      themeContext.ctaDisabledBackground,
    ]
  );

  const Actions = useMemo(() => {
    // e.g. onOffice
    if (property.isCreatedVia3rdPartyPortal) {
      return <>{saleOverviewButtonWithTooltip()}</>;
    }

    if (property.isRealSellingProcessStarted) {
      if (isOwner) {
        return <>{saleOverviewButtonWithTooltip()}</>;
      }

      if (isEditor) {
        return <>{saleOverviewButtonWithTooltip()}</>;
      }

      if (isSpectator) {
        return <>{saleOverviewButtonWithTooltip()}</>;
      }
    } else {
      if (isOwner) {
        return (
          <>
            {evaluationButton()}
            <FlexContainer>
              {saleSimulationButton()}
              {startSellingButton()}
            </FlexContainer>
          </>
        );
      }

      if (isEditor) {
        return (
          <>
            {evaluationButton()}
            <FlexContainer>
              {saleSimulationButton()}
              {startSellingButton()}
            </FlexContainer>
          </>
        );
      }

      if (isSpectator) {
        return (
          <>
            {evaluationButton()}
            <FlexContainer>
              {saleSimulationButton()}
              <Tooltip
                id="start-selling-button-tooltip"
                content={startSellingButton(true)}
              >
                <TooltipTitle
                  content={t('property-portfolio.missing-permissions-tooltip')}
                />
              </Tooltip>
            </FlexContainer>
          </>
        );
      }
    }

    return <></>;
  }, [
    evaluationButton,
    isEditor,
    isOwner,
    isSpectator,
    property.isCreatedVia3rdPartyPortal,
    property.isRealSellingProcessStarted,
    saleOverviewButton,
    saleSimulationButton,
    startSellingButton,
    t,
  ]);

  return (
    <Container>
      {!imageSrc ? (
        <MapWrapper isMobileSize={isMobileSize}>
          {property.coordinates && (
            <Mapbox isVisible coordinates={property.coordinates} />
          )}
          <CoverSnippet />
        </MapWrapper>
      ) : (
        <ImageWrapper isMobileSize={isMobileSize}>
          <CardImage src={imageSrc} />
          <CoverSnippet />
        </ImageWrapper>
      )}
      <Content>
        <TitleWrap>
          {!editTitle && (
            <>
              <Tooltip
                className="title-tooltip"
                id={`title-tt-${property._id}`}
                textAlign="left"
                maxWidth={'92%'}
                isDisabled={isMobileSize}
                content={<Title>{title || property.defaultTitle}</Title>}
              >
                <TooltipTitle content={title || property.defaultTitle} />
              </Tooltip>

              {property.status !== PropertyStatus.Selling && isOwner && (
                <IconTrigger
                  className="edit"
                  onClick={() => {
                    setTitleValue(title);
                    setEditTitle(true);
                  }}
                >
                  <Icon icon={Pen} width={16} height={16} color={'#00305E'} />
                </IconTrigger>
              )}
            </>
          )}
          {editTitle && (
            <>
              <TitleWrap style={{ marginBottom: 0 }}>
                <InputWrap>
                  <InputField
                    name="title"
                    isInputAdornment
                    placeholder={property.defaultTitle}
                    defaultValue={titleValue}
                    autoFocus
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      setTitleValue(input.value);
                    }}
                  />
                  <CloseButton
                    type="button"
                    onClick={() => {
                      setEditTitle(false);
                      setTitleValue(title || property.defaultTitle);
                    }}
                  >
                    <Icon
                      icon={Close}
                      style={{ minHeight: 20, minWidth: 20 }}
                      width={20}
                      height={20}
                      color={themeContext.red}
                    />
                  </CloseButton>
                </InputWrap>
                <SaveButton
                  onClick={saveTitle}
                  isDisabled={titleValue === title}
                  // isError={!titleValue}
                  label={
                    <Icon
                      icon={Check}
                      style={{ minHeight: 16, minWidth: 16 }}
                      width={16}
                      height={16}
                      color={titleValue === title ? '#979797' : '#ffffff'}
                    />
                  }
                />
              </TitleWrap>
            </>
          )}
        </TitleWrap>
        {address && (
          <Tooltip
            className="location-tooltip"
            id={`location-tt-${property._id}`}
            textAlign="left"
            maxWidth={'92%'}
            isDisabled={isMobileSize}
            content={
              <Row>
                <LocationIcon
                  icon={LocationMarker}
                  color={themeContext.blue}
                  style={{
                    minWidth: '16px',
                    minHeight: '16px',
                    maxWidth: '16px',
                    maxHeight: '16px',
                  }}
                />
                <Location>{address}</Location>
              </Row>
            }
          >
            <TooltipTitle content={address} />
          </Tooltip>
        )}

        <IconContainer>
          <IconRow>
            <PropertyValuationIcon
              icon={icon}
              value={`${t(label)}`}
              type={t('property-overview.type')}
            />
            <PropertyValuationIcon
              icon={BuildingYear}
              value={buildingYear ?? 0}
              type={t('property-overview.building-year')}
            />
            <PropertyValuationIcon
              icon={RealEstateDimensionsBlock}
              value={`${livingArea ?? 0} m²`}
              type={t('property-overview.living-area')}
            />
            {code !== PropertyCode.Apartment && (
              <PropertyValuationIcon
                icon={ResizeExpandSides}
                value={`${landArea ?? 0} m²`}
                type={t('property-overview.land-area')}
              />
            )}
            {code !== PropertyCode.MultiFamilyHouse && (
              <>
                <PropertyValuationIcon
                  icon={HotelDoubleBed2}
                  value={numberOfRooms ?? 0}
                  type={t('property-overview.number-of-rooms')}
                />
                <PropertyValuationIcon
                  icon={RealEstateDimensionsPlan}
                  value={numberOfBathrooms ?? 0}
                  type={t('property-overview.number-of-bathrooms')}
                />
                <PropertyValuationIcon
                  icon={HouseGarage}
                  value={numberOfIndoorParkingSpaces ?? 0}
                  type={t('property-overview.number-of-indoor-parking-spaces')}
                />
                <PropertyValuationIcon
                  icon={RoadSignParking}
                  value={numberOfOutdoorParkingSpaces ?? 0}
                  type={t('property-overview.number-of-outdoor-parking-spaces')}
                />
              </>
            )}
            {code === PropertyCode.MultiFamilyHouse && (
              <>
                <PropertyValuationIcon
                  icon={Business1}
                  value={numberOfUnits ?? '-'}
                  type={t('property-overview.number-of-units')}
                />
                <PropertyValuationIcon
                  icon={CashShield}
                  value={annualRentIncomeLabel}
                  type={t('property-overview.annual-rent-income')}
                />
              </>
            )}
            {property.energyLabelData && (
              <Tooltip
                id="energy-label"
                textAlign="left"
                maxWidth={150}
                content={
                  <PropertyValuationIcon
                    icon={Energy}
                    value={t(property.energyLabelData).toString()}
                    type={t('property-overview.energy-label')}
                  />
                }
              >
                <TooltipTitle content={t(property.energyLabelData)} />
              </Tooltip>
            )}
          </IconRow>
        </IconContainer>
      </Content>
      <Price>
        <PropertyValuationIcon
          icon={AccountingBills}
          value={priceLabelValue}
          type={priceLabelType}
        />
      </Price>
      <ActionsContent>{Actions}</ActionsContent>
      <WarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        text={warningModalText}
      />
    </Container>
  );
};

const PortfolioProperty = memo(PortfolioPropertyBase);

export { PortfolioProperty };
