import styled from 'styled-components';
import { device } from '../../../../../../../../../style/theme';
import { ParagraphTitle } from '../../../../../../../../common/components/ui/typography';

export const Container = styled.div<{ isConstructions?: boolean }>`
  &&& {
    min-width: 240px;
    flex: 1 1 49%;
    padding: ${({ isConstructions }) =>
      isConstructions ? '16px 0 12px 0' : '12px'};
    border-radius: 3px;
    border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
    background-color: #fff;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .outer-accordion {
      border: none !important;
      background: transparent;

      .rc-collapse-item {
        border: none !important;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .outer-header {
          cursor: pointer;
          padding: 0;
          margin: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 1px;
          color: ${(props) => props.theme.blue};

          .icon {
            margin-left: auto;
            order: 2;
            path {
              stroke-width: 1.5px;
            }
          }
        }
        &.rc-collapse-item-active {
          .outer-header {
            // Active
          }
        }
        .rc-collapse-content {
          padding: 0;
          margin: 16px 0 0 0;
          .rc-collapse-content-box {
            margin: 0;
            @media ${device.tablet} {
            }

            .inner-accordion {
              border-radius: 3px;
              border: solid 1px ${(props) => props.theme.lightBlue};
              background-color: ${(props) => props.theme.greyBackground};
              margin-bottom: 12px;
              .rc-collapse-item {
                .inner-header {
                  padding: 12px;
                  .icon {
                    margin-left: auto;
                    order: 2;
                  }
                }
                .rc-collapse-content {
                  padding: 0 12px 12px 12px;
                  margin: 0;
                  border-radius: 0;
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ConstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px #d9d9d9;
  padding: 12px 12px 0;
  gap: 8px;
`;

export const ConstructionItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  line-height: 20px;
  letter-spacing: 1px;
`;

export const ConstructionInfo = styled(ParagraphTitle)`
  color: ${({ theme }) => theme.blue};
  &:first-child {
    flex: 1 1 60%;
  }
`;
