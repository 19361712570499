import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import {
  BuyerBenefits as BuyerBenefitsType,
  BuyerLpSection,
  BuyerLpSectionType,
  OverviewLpSection,
  OverviewLpSectionType,
  OwnerBenefits as OwnerBenefitsType,
  OwnerLpSection,
  SellerBenefits as SellerBenefitsType,
  SellerLpSection,
  SellerLpSectionType,
  useReadBuyerBenefitQuery,
  useReadOwnerBenefitQuery,
  useReadSellerBenefitQuery,
} from '../../../../../generated';
import { siteMap } from '../../../../../routes/site-map';
import { SecondaryButton } from '../../../../common/components/ui/buttons';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { Link } from '../cta-buttons/cta-buttons-styles';
import { BgColor } from '../overview-section/overview-section-styles';
import { BenefitCard } from './benefit-card/benefit-card';
import {
  BenefitsContainer,
  Container,
  ContainerItem,
  Content,
  CTAButtonWrapper,
  Description,
  Image,
  ImageContainer,
  ImageContentContainer,
  Line,
  Title,
  VerticalLine,
} from './benefits-section-styles';
// import { PlusContainer } from './plus-container/plus-container';

// import { mockOverviewBenefits } from './mock-overview-benefits';

interface BenefitItemProps {
  isRevert?: boolean;
  isProportional?: boolean;
  isGray?: boolean;
  isFit?: boolean;
  isBuyer?: boolean;
  title: string;
  description: string;
  buttonLabel: string;
  imageUrl: string;
  imageAlt: string;
  imageKey: string;
  benefits: {
    iconUrl: string;
    iconAlt: string;
    iconKey: string;
    title: string;
  }[];
}

interface BuyerBenefitsProps {
  id: string;
  isGray?: boolean;
}

const BenefitItem: FC<BenefitItemProps> = ({
  title,
  description,
  buttonLabel,
  imageUrl,
  imageKey,
  imageAlt,
  benefits,
  isRevert,
  isProportional,
  isGray,
  isFit,
  isBuyer,
}) => {
  const themeContext = useContext(ThemeContext);

  const isMobileSize = useIsMobileSize();

  return (
    <ContainerItem>
      <Line isTop={!isMobileSize} />
      <ImageContentContainer isRevert={isRevert}>
        <ImageContainer
          isFit={isFit}
          isProportional={isProportional}
          isRevert={isRevert}
        >
          <Image
            isFit={isFit}
            isProportional={isProportional}
            key={imageKey}
            src={imageUrl}
            alt={imageAlt}
          />
        </ImageContainer>
        <Content isFit={isFit} isProportional={isProportional}>
          <Title content={title} />
          <Description content={description} />
          <BenefitsContainer>
            <VerticalLine />
            {benefits.map((item) => (
              <BenefitCard
                isGray={isGray}
                isProportional={isProportional}
                key={item.iconKey}
                imageSrc={item.iconUrl}
                imageAlt={item.iconAlt}
                title={item.title}
              />
            ))}
          </BenefitsContainer>
          <Link
            absolute
            isOpenNewPage
            to={
              isRevert
                ? siteMap.LoginBuyerPage.path
                : isProportional
                ? siteMap.LoginSellerPage.path
                : siteMap.LoginPage.path
            }
          >
            <CTAButtonWrapper>
              <SecondaryButton
                backgroundColor={'transparent'}
                label={buttonLabel.toUpperCase()}
                onClick={() => {}}
                color={themeContext.blue}
                borderColor={themeContext.blue}
              />
            </CTAButtonWrapper>
          </Link>
        </Content>
      </ImageContentContainer>
      {/* <PlusContainer isBlue={isRevert} /> */}
      <Line isTop={false} />
    </ContainerItem>
  );
};

interface OwnerBenefitsProps {
  id: string;
}

interface OwnerBenefitsResponse {
  data: { data: OwnerBenefitsType };
  isLoading: boolean;
}

const OwnerBenefits: FC<OwnerBenefitsProps> = ({ id }) => {
  const { data, isLoading } = useReadOwnerBenefitQuery<OwnerBenefitsResponse>({
    id,
  });

  if (isLoading) return null;

  const benefit = data?.data;

  const props = {
    title: benefit.title || '',
    description: benefit.expandedText || '',
    buttonLabel: benefit.propertyEvaluateLabel || '',
    imageAlt: benefit.imageAlt || '',
    imageKey: benefit.imageKey || '',
    imageUrl: benefit.imageUrl || '',
    benefits: [
      {
        iconAlt: benefit.icon1Alt || '',
        iconKey: benefit.icon1Key || '',
        iconUrl: benefit.icon1Url || '',
        title: benefit.text1 || '',
      },
      {
        iconAlt: benefit.icon2Alt || '',
        iconKey: benefit.icon2Key || '',
        iconUrl: benefit.icon2Url || '',
        title: benefit.text2 || '',
      },
      {
        iconAlt: benefit.icon3Alt || '',
        iconKey: benefit.icon3Key || '',
        iconUrl: benefit.icon3Url || '',
        title: benefit.text3 || '',
      },
    ],
  };

  return <BenefitItem {...props} />;
};

interface BuyerBenefitsResponse {
  data: { data: BuyerBenefitsType };
  isLoading: boolean;
}

const BuyerBenefits: FC<BuyerBenefitsProps> = ({ id, isGray }) => {
  const { data, isLoading } = useReadBuyerBenefitQuery<BuyerBenefitsResponse>({
    id,
  });

  if (isLoading) return null;

  const benefit = data?.data;

  const props = {
    title: benefit.title || '',
    description: benefit.expandedText || '',
    buttonLabel: benefit.createSearchProfileLabel || '',
    imageAlt: benefit.imageAlt || '',
    imageKey: benefit.imageKey || '',
    imageUrl: benefit.imageUrl || '',
    benefits: [
      {
        iconAlt: benefit.icon1Alt || '',
        iconKey: benefit.icon1Key || '',
        iconUrl: benefit.icon1Url || '',
        title: benefit.text1 || '',
      },
      {
        iconAlt: benefit.icon2Alt || '',
        iconKey: benefit.icon2Key || '',
        iconUrl: benefit.icon2Url || '',
        title: benefit.text2 || '',
      },
      {
        iconAlt: benefit.icon3Alt || '',
        iconKey: benefit.icon3Key || '',
        iconUrl: benefit.icon3Url || '',
        title: benefit.text3 || '',
      },
      {
        iconAlt: benefit.icon4Alt || '',
        iconKey: benefit.icon4Key || '',
        iconUrl: benefit.icon4Url || '',
        title: benefit.text4 || '',
      },
    ],
  };

  return <BenefitItem isBuyer isRevert isGray={isGray} {...props} />;
};

interface SellerBenefitsProps {
  id: string;
  isFit?: boolean;
}

interface SellerBenefitsResponse {
  data: { data: SellerBenefitsType };
  isLoading: boolean;
}

const SellerBenefits: FC<SellerBenefitsProps> = ({ id, isFit }) => {
  const { data, isLoading } = useReadSellerBenefitQuery<SellerBenefitsResponse>(
    {
      id,
    }
  );

  if (isLoading) return null;

  const benefit = data?.data;

  const props = {
    title: benefit.title || '',
    description: benefit.expandedText || '',
    buttonLabel: benefit.propertyEvaluateLabel || '',
    imageAlt: benefit.imageAlt || '',
    imageKey: benefit.imageKey || '',
    imageUrl: benefit.imageUrl || '',
    benefits: [
      {
        iconAlt: benefit.icon1Alt || '',
        iconKey: benefit.icon1Key || '',
        iconUrl: benefit.icon1Url || '',
        title: benefit.text1 || '',
      },
      {
        iconAlt: benefit.icon2Alt || '',
        iconKey: benefit.icon2Key || '',
        iconUrl: benefit.icon2Url || '',
        title: benefit.text2 || '',
      },
      {
        iconAlt: benefit.icon3Alt || '',
        iconKey: benefit.icon3Key || '',
        iconUrl: benefit.icon3Url || '',
        title: benefit.text3 || '',
      },
      {
        iconAlt: benefit.icon4Alt || '',
        iconKey: benefit.icon4Key || '',
        iconUrl: benefit.icon4Url || '',
        title: benefit.text4 || '',
      },
      {
        iconAlt: benefit.icon5Alt || '',
        iconKey: benefit.icon5Key || '',
        iconUrl: benefit.icon5Url || '',
        title: benefit.text5 || '',
      },
    ],
  };

  return <BenefitItem isProportional isFit={isFit} {...props} />;
};

interface IProps {
  section:
    | OverviewLpSection
    | BuyerLpSection
    | OwnerLpSection
    | SellerLpSection;
  isRevert?: boolean;
  bgWhite?: boolean;
}

const BenefitsSection: FC<IProps> = ({ section, bgWhite }) => {
  const { recordIds, type } = section;

  const isGray = type === OverviewLpSectionType.BuyerBenefit && !bgWhite;
  const isFit = type === OverviewLpSectionType.SellerBenefit;

  return (
    <Container>
      {isGray && <BgColor />}
      {(type === OverviewLpSectionType.BuyerBenefit ||
        type === BuyerLpSectionType.BuyerBenefit) &&
        recordIds.map((id: string) => {
          return <BuyerBenefits isGray={isGray} key={id} id={id} />;
        })}
      {type === OverviewLpSectionType.OwnerBenefit &&
        recordIds.map((id: string) => {
          return <OwnerBenefits key={id} id={id} />;
        })}
      {(type === OverviewLpSectionType.SellerBenefit ||
        type === SellerLpSectionType.SellerBenefit) &&
        recordIds.map((id: string) => {
          return <SellerBenefits isFit={isFit} key={id} id={id} />;
        })}
    </Container>
  );
};

export { BenefitsSection };
