import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddressBarContainer,
  CommissionContainer,
  CommissionLabel,
  CommissionValue,
  FullPrice,
  Header,
  PriceContainer,
  Row,
  SquareMeterPrice,
  Tag,
} from './head-info-styles';

import { AddressBar } from '../../../../../common/components/ui/hero-slider/address-bar';
import { Buttons } from './buttons/buttons';
import { PropertyPurchaseIntent } from '../../../../../../generated';
import { useAppSelector } from '../../../../../common/hooks';
import { useExposeDetailsHeadInfo } from '../../hooks/useExposeDetailsQueries';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { siteMap } from '../../../../../../routes/site-map';
import { useGetCountry } from '../../../../../localization/get-country';

interface IProps {
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  contactFormRef?: RefObject<HTMLDivElement>;
}

const HeadInfo: FC<IProps> = ({ purchaseIntents, refetch, contactFormRef }) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { language } = useGetCountry();
  const isOpenAccordion = useAppSelector(
    (state) => state.exposeDetails.isOpenHeadAccordion
  );

  const {
    headInfo: {
      externalId,
      propertyId,
      title,
      price,
      pricePerSqm,
      currency,
      provision,
      fullAddress,
    },
  } = useExposeDetailsHeadInfo();

  const onClickTag = () => {
    window.open(
      `https://www.von-poll.com/${language}/expose/immobilie/${propertyId}`,
      '_blank'
    );
  };

  return (
    <>
      {isMobileSize && (
        <AddressBarContainer>
          <AddressBar show address={fullAddress} />
        </AddressBarContainer>
      )}
      <Header isColumn={isOpenAccordion} content={title || ''} />
      <Row
        isWrap
        isColumn={isOpenAccordion}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Row
          isWrap
          alignItems={'center'}
          justifyContent={'space-between'}
          style={{ flexGrow: 1 }}
        >
          <Row
            alignItems={'center'}
            style={{ flexGrow: 1 }}
            justifyContent={'space-between'}
          >
            <PriceContainer>
              <FullPrice>
                {price} {currency}
              </FullPrice>
              <SquareMeterPrice>({pricePerSqm} €/m²)</SquareMeterPrice>
            </PriceContainer>
            <Tag onClick={onClickTag}># {externalId}</Tag>
          </Row>
          {provision?.value && (
            <CommissionContainer>
              <CommissionLabel isBold={!!provision?.isAgreedWithSeller}>
                {t('expose-page.head-info.provision', {
                  provision: provision.value,
                })}
              </CommissionLabel>
              {provision?.isAgreedWithSeller && (
                <CommissionLabel>
                  {t('expose-page.head-info.provision.agreed-with-seller')}
                </CommissionLabel>
              )}
            </CommissionContainer>
          )}
        </Row>
        <Buttons
          id={Number(propertyId)}
          price={Number(price)}
          purchaseIntents={purchaseIntents}
          refetch={refetch}
          contactFormRef={contactFormRef}
        />
      </Row>
    </>
  );
};

export { HeadInfo };
