import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { getMicroSituationMeta } from '../micro-situation-meta';
import { ILocationScore } from '../../../../../../../../common/interfaces';
import {
  ConstructionItem,
  ConstructionsContainer,
  ConstructionInfo,
  Container,
} from './micro-situation-card-styles';
import { MainHeader } from '../micro-situation-components/micro-situation-components';
import { BuildingProjectsItemEntity } from '../../../../../../../../../generated';

interface IProps {
  title: string;
  score?: ILocationScore;
  constructions?: Partial<BuildingProjectsItemEntity>[];
  totalBuildings?: string;
}

const MicroSituationCardBase = ({
  title,
  score,
  constructions,
  totalBuildings,
}: IProps): JSX.Element => {
  const { title: generatedTitle, icon } = getMicroSituationMeta(title);
  const { t } = useTranslation();

  return (
    <Container isConstructions={title === 'construction'}>
      <MainHeader
        title={t(generatedTitle)}
        location={
          score?.searchProfileLabel &&
          t(score?.searchProfileLabel).toUpperCase()
        }
        icon={icon}
        rating={score?.score}
        color={score?.color}
        isShrinked={false}
        totalBuildings={totalBuildings}
        noConstuctions={!constructions || constructions.length === 0}
      />
      {constructions && constructions.length > 0 && (
        <ConstructionsContainer>
          {constructions.map(({ title: constructionTitle, distance }) => (
            <ConstructionItem key={constructionTitle}>
              {constructionTitle && (
                <ConstructionInfo content={constructionTitle} />
              )}
              <ConstructionInfo content={`${distance} m`} />
            </ConstructionItem>
          ))}
        </ConstructionsContainer>
      )}
    </Container>
  );
};

const MicroSituationCard = memo(MicroSituationCardBase);

export { MicroSituationCard };
