import ReactTooltip from 'react-tooltip';
import styled, { CSSProperties, useTheme } from 'styled-components';
import { FC } from 'react';
import { useIsMobileSize } from '../../../hooks/useIsMobileSize';

const StyledReactTooltip = styled(ReactTooltip)<{ tipLeftPosition?: number }>`
  opacity: 1 !important;
  padding: 0 12px !important;
  ${({ tipLeftPosition }) =>
    tipLeftPosition &&
    `
    &:before {
      left: ${tipLeftPosition}px !important;
    }
    &:after {
      left: ${tipLeftPosition}px !important;
    }
  `}
`;

const Container = styled.div<{ isMobileSize?: boolean }>`
  text-align: center;
  width: ${(props) => (props.isMobileSize ? '100%' : 'fit-content')};
  .extraClass {
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
    }
  }
`;

interface Offset {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

interface IProps {
  id: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  stayOnHover?: boolean;
  Button: React.ReactNode;
  Tooltip: React.ReactNode;
  isVisible?: boolean;
  offset?: Offset;
  tipLeftPosition?: number;
  style?: CSSProperties;
}

const ButtonWithTooltip: FC<IProps> = ({
  Tooltip,
  id,
  position = 'top',
  stayOnHover = false,
  Button,
  isVisible,
  offset,
  style,
  tipLeftPosition,
}) => {
  const isMobileSize = useIsMobileSize();
  const theme = useTheme();

  if (!Tooltip) {
    return (
      <Container isMobileSize={isMobileSize} style={style}>
        {Button}
      </Container>
    );
  }

  return (
    <Container isMobileSize={isMobileSize} style={style}>
      <div data-tip="tooltip" data-for={id} role="presentation">
        {Button}
      </div>
      <StyledReactTooltip
        tipLeftPosition={tipLeftPosition}
        // tooltip doesn't hide after click
        // update key to rerender it
        key={isVisible ? `${id}-visible` : `${id}-hidden`}
        {...(stayOnHover && { delayHide: 1000 })}
        place={position}
        offset={offset}
        {...(stayOnHover && { className: 'extraClass' })}
        id={id}
        effect="solid"
        type="light"
        borderColor={theme.ctaDisabledBackground}
        border
      >
        {Tooltip}
      </StyledReactTooltip>
    </Container>
  );
};

export { ButtonWithTooltip };
