import { FC } from 'react';
import styled from 'styled-components';

import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';
import {
  Testimonials as TestimonialsType,
  useReadTestimonialQuery,
} from '../../../../../generated';
import { RatingsIcon } from './rating-icon';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 336px;
  padding: 48px 0 0;

  @media ${device.mobile} {
    max-width: 100%;
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  max-width: 116px;
  max-height: 116px;
  position: absolute;
  top: 0;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  background-color: ${({ theme }) => theme.white};
  padding: 92px 24px 24px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
`;

const Title = styled.h4`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
`;

const Text = styled.div`
  color: ${(props) => props.theme.blue};
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 16px;
  flex-grow: 1;
`;

const Author = styled.div`
  opacity: 0.5;
  font-family: Source Serif Pro, serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 1px;
    background: ${(props) => hexToRGB(props.theme.blue, 0.25)};
  }
  &:before {
    left: -44px;
    top: 11px;
  }
  &:after {
    right: -44px;
    top: 11px;
  }
`;

interface TestimonialCardProps {
  id: string;
  rating: 0 | 1 | 2 | 3 | 4 | 5;
}

interface TestimonialResponse {
  data: { data: TestimonialsType };
  isLoading: boolean;
}

export const TestimonialCard: FC<TestimonialCardProps> = ({ id, rating }) => {
  const { data, isLoading } = useReadTestimonialQuery<TestimonialResponse>({
    id,
  });

  if (isLoading) return null;

  const section = data?.data;

  return (
    <Container>
      {section.imageUrl && (
        <ImageWrapper>
          <Image
            src={section.imageUrl}
            alt={section.imageAlt || ''}
            key={section.imageKey}
          />
        </ImageWrapper>
      )}
      <Content>
        <Title>{section.title}</Title>
        <RatingsIcon rating={rating} />
        <Text
          dangerouslySetInnerHTML={{ __html: section.expandedText ?? '' }}
        />
        <Author>{section.name}</Author>
      </Content>
    </Container>
  );
};
