import { VirtuosoGrid, VirtuosoHandle } from 'react-virtuoso';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { batch, useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as HouseRemove } from 'assets/streamline-light/real-estate/actions-houses/real-estate-action-house-remove.svg';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import {
  Container,
  ListContainer,
  Row,
  Title,
} from './seller-property-card-styles';
import { useAppSelector } from '../../../common/hooks';
import { SellerPropertyCard } from './seller-property-card';
import { InputSearch } from '../../../common/components/form/input/input-search';
import {
  Currency,
  OnOfficeUser,
  PropertyStatus,
  useGetOnOfficeUserQuery,
  useReadPropertiesQuery,
  ValuationType,
} from '../../../../generated';
import {
  addMarkers,
  changeViewport,
  removeAllMarkersExceptHome,
  setSettings,
} from '../../../map/redux/mapSlice';
import {
  addSellerProperties,
  clearSellerPropertiesCache,
  EvaluatedProperty,
  mergeFilters,
  PropertyFilters,
  resetFilters,
  setInitialLoader,
  setTotalArea,
  setTotalItems,
  toggleIsPreview,
} from '../../redux/sellerPropertiesSlice';
import { scrollToProperty } from '../../../property/redux/dynamicMapUtilsSlice';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import EmptyStatePlaceholder from '../../../common/components/empty-state-placeholder';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { LoaderContainer } from '../../pages/seller-properties-page/seller-properties-page-styles';
import { useHandleVWOpen } from '../../../forms/form-property-valuation-wizard/hooks/useHandleVWOpen';
import { Germany } from '../../../map/constants/points-of-interest';
import { implodeAddress } from '../../../property/utils/address';
import { buildPropertyTitle } from '../../utils/buildPropertyTitle';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { WarningMessage } from '../offers-statistics-card/filter/warning-message';

const LoadMoreButtonContainer = styled.div<{
  isMobileSize?: boolean;
}>`
  margin: ${(props) => (props.isMobileSize ? '40px 0 0 0' : '40px 6px 0 6px')};
`;

const LoadMoreButton = styled(SecondaryButton)`
  justify-content: center;
`;

const SellerPropertiesContainerBase = () => {
  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const themeContext = useTheme();
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const { handleVWOpen } = useHandleVWOpen({ type: ValuationType.Online });
  const [searchInput, setSearchInput] = useState<string>('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showClearSearch, setShowClearSearch] = useState(false);

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const filters = useAppSelector(
    (state) => state.sellerProperties.filters
  ) as PropertyFilters;
  const sellerProperties = useAppSelector(
    (state) => state.sellerProperties.sellerProperties
  );
  const totalItems = useAppSelector(
    (state) => state.sellerProperties.totalItems
  );
  const initialLoader = useAppSelector(
    (state) => state.sellerProperties.initialLoader
  );
  const propertyIndexForScrolling = useAppSelector(
    (state) => state.dynamicMapUtils.propertyIndexForScrolling
  );

  const {
    data,
    isLoading: isSellerPropertiesLoading,
    error: sellerPropertiesError,
  } = useReadPropertiesQuery(
    {
      filter: {
        ownerId: userId,
        propertyOnOfficeId: propertyOnOfficeId ?? '',
        search: filters.search ?? '',
      },
      pagination: {
        offset: filters.offset ?? 0,
        limit: filters.limit ?? 8,
      },
      calculateTotalInfo: true,
      isEvaluationChanges: true,
      includeRelatedProperties: true,
    },
    {
      skip: isBrokerView
        ? !propertyOnOfficeId || !brokerId
        : !userId || isAnonymousUser,
    }
  );

  const properties = useMemo(() => {
    return data?.readProperties?.page?.map((property) => {
      const {
        valuationsLastSync,
        countryCode,
        actualValuations,
        mapboxImageUrl,
        valuations,
        title,
      } = property ?? {};
      const { livingArea, location, propertyType, numberOfRooms } =
        property?.propertyData ?? {};
      const currentValuationIndex =
        valuations?.findIndex(
          (valuation) =>
            new Date(valuation.date).toDateString() ===
            new Date(valuationsLastSync).toDateString()
        ) ?? -1;
      // const priceCurrency =
      //   countryCode?.toLowerCase() === CountryCodes.SWITZERLAND ? 'CHF' : '€';

      const priceCurrency =
        property.priceCurrency === Currency.Chf ? Currency.Chf : Currency.Eur;

      return {
        currentValuationIndex,
        currentValuation: valuations?.[currentValuationIndex],
        livingArea,
        address: implodeAddress(location.address),
        countryCode,
        priceCurrency,
        _id: property._id,
        status: property.status,
        title:
          title ||
          buildPropertyTitle(
            propertyType.code,
            numberOfRooms ?? 0,
            location.address.city,
            livingArea
          ),
        imageSrc: property?.photos?.[0],
        mapboxImageUrl,
        highestBid:
          property?.marketingStatistics?.generalStatistics?.highestBid,
        newBidsNumber:
          property?.marketingStatistics?.statisticsPerWeek?.[0]?.bids?.length ??
          0,
        onOfficePrice: property?.onOfficePrice,
        externalId: property?.externalId,
        isDigitalServiceActivated: property?.isDigitalServiceActivated,
        isDigitalServiceLockedByBroker:
          property?.isDigitalServiceLockedByBroker,
        isPreview: property?.isPreview,
      } as EvaluatedProperty;
    });
  }, [data?.readProperties?.page]);

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (showClearSearch) {
        setShowClearSearch(false);
      }
      setSearchInput(event.target.value);
    },
    [showClearSearch]
  );

  const onConfirmSearch = useCallback(() => {
    if (showWarningMessage) {
      setShowWarningMessage(false);
    }
    if (filters.search !== searchInput) {
      setShowClearSearch(true);
      batch(() => {
        dispatch(
          mergeFilters({
            offset: 0,
            limit: 8,
            search: searchInput,
          })
        );
        dispatch(clearSellerPropertiesCache());
        dispatch(removeAllMarkersExceptHome());
        dispatch(setInitialLoader(true));
      });
    }
  }, [dispatch, filters.search, searchInput, showWarningMessage]);

  const onClearSearch = useCallback(() => {
    setShowClearSearch(false);
    setSearchInput('');
    batch(() => {
      dispatch(
        mergeFilters({
          offset: 0,
          limit: 8,
          search: '',
        })
      );
      dispatch(clearSellerPropertiesCache());
      dispatch(removeAllMarkersExceptHome());
      dispatch(setInitialLoader(true));
    });
  }, [dispatch]);

  useEffect(() => {
    if (properties) {
      const isPreview = properties?.some((property) => property.isPreview);
      batch(() => {
        dispatch(toggleIsPreview(isPreview));
        dispatch(
          changeViewport({
            ...properties?.[0]?.currentValuation?.valuation?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator({
              speed: 1.8,
              curve: 1,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(
          setSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
            radius: 0,
          })
        );
        dispatch(
          addMarkers(
            properties?.map((item, index) => ({
              longitude:
                item?.currentValuation?.valuation?.coordinates?.longitude ?? 0,
              latitude:
                item?.currentValuation?.valuation?.coordinates?.latitude ?? 0,
              category: 'property',
              marker: 'similarProperty',
              index,
            }))
          )
        );
      });
    }
  }, [properties, dispatch]);

  useEffect(() => {
    if (data?.readProperties?.page && !isEmpty(properties)) {
      batch(() => {
        dispatch(addSellerProperties(properties as EvaluatedProperty[]));
        dispatch(setTotalItems(data?.readProperties?.pageData.count));
        dispatch(setTotalArea(data?.readProperties?.totalArea ?? 0));
        dispatch(setInitialLoader(false));
      });
      setIsLoadingMore(false);
    } else {
      batch(() => {
        dispatch(
          changeViewport({
            ...Germany,
            transitionDuration: 1000,
          })
        );
        dispatch(
          setSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
            radius: 0,
          })
        );
        dispatch(setInitialLoader(false));
      });
    }
  }, [data, dispatch, properties]);

  useEffect(() => {
    if (
      propertyIndexForScrolling !== null &&
      propertyIndexForScrolling !== undefined &&
      virtuosoRef?.current
    ) {
      dispatch(scrollToProperty(null));
      try {
        setTimeout(() => {
          virtuosoRef?.current?.scrollToIndex({
            index: propertyIndexForScrolling,
            align: 'center',
            behavior: 'smooth',
          });
        }, 0);
      } catch (error) {
        console.error('Error ->', error);
      }
    }
  }, [dispatch, propertyIndexForScrolling]);

  useEffect(() => {
    if (filters.search && isEmpty(data?.readProperties?.page)) {
      onClearSearch();
      setShowWarningMessage(true);
    }
  }, [data?.readProperties?.page, filters.search, onClearSearch]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetFilters());
        dispatch(clearSellerPropertiesCache());
        dispatch(removeAllMarkersExceptHome());
      });
    };
  }, []);

  const loadMore = useCallback(() => {
    if (totalItems > (filters?.offset ?? 0)) {
      setIsLoadingMore(true);
      dispatch(
        mergeFilters({
          limit: 8,
          offset: +(filters.offset ?? 0) + 8,
        })
      );
    }
  }, [dispatch, filters.offset, totalItems]);

  const onCloseWarningMessage = useCallback(() => {
    setShowWarningMessage(false);
  }, []);

  if (initialLoader || (isSellerPropertiesLoading && !isLoadingMore)) {
    return (
      <Container>
        <Row
          margin={'0 0 20px 0'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Title content={t('seller-properties-list.title')} />
          <InputSearch
            placeholder={t('seller-properties-list.search-input.placeholder')}
            onChange={onSearchInputChange}
            value={searchInput}
            animateWidth={{
              from: '160px',
              to: isMobileSize ? '200px' : '300px',
            }}
            onConfirmSearch={showClearSearch ? undefined : onConfirmSearch}
            onClearSearch={showClearSearch ? onClearSearch : undefined}
          />
        </Row>
        {sellerPropertiesError?.message && (
          <GlobalError
            title={t(sellerPropertiesError?.message?.split(':')[0])}
          />
        )}
        <LoaderContainer isLoadingMore>
          <ValuationLoader maxWidth="500px" />
        </LoaderContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Row
        margin={'0 0 20px 0'}
        alignItems={'center'}
        justifyContent={'space-between'}
        style={{ flexDirection: isMobileSize ? 'column' : 'row' }}
      >
        <Title content={t('seller-properties-list.title')} />
        <InputSearch
          placeholder={t('seller-properties-list.search-input.placeholder')}
          onChange={onSearchInputChange}
          value={searchInput}
          animateWidth={
            isMobileSize
              ? { from: '100%', to: '100%' }
              : { from: '180px', to: '300px' }
          }
          onConfirmSearch={showClearSearch ? undefined : onConfirmSearch}
          onClearSearch={showClearSearch ? onClearSearch : undefined}
        />
      </Row>
      {sellerPropertiesError?.message && (
        <GlobalError title={t(sellerPropertiesError?.message?.split(':')[0])} />
      )}
      {showWarningMessage && (
        <WarningMessage
          containerStyles={{ marginBottom: '16px' }}
          text={t('seller-properties.message.no-results')}
          onClose={onCloseWarningMessage}
        />
      )}
      {!isEmpty(sellerProperties) ? (
        <>
          <ListContainer>
            {/* <VirtuosoGrid */}
            {/*  useWindowScroll */}
            {/*  totalCount={sellerProperties?.length} */}
            {/*  overscan={200} */}
            {/*  components={{ */}
            {/*    Item: ItemWithShrinkProps, */}
            {/*    List: ListContainer, */}
            {/*  }} */}
            {/*  itemContent={itemContent} */}
            {/* /> */}{' '}
            {sellerProperties?.map((sellerProperty) => (
              <SellerPropertyCard
                key={sellerProperty?._id}
                id={sellerProperty?._id}
                title={sellerProperty?.title ?? ''}
                currency={sellerProperty?.priceCurrency ?? Currency.Eur}
                imageSrc={
                  sellerProperty?.status === PropertyStatus.Initial
                    ? sellerProperty?.mapboxImageUrl ?? ''
                    : sellerProperty?.imageSrc ??
                      sellerProperty?.mapboxImageUrl ??
                      ''
                }
                propertyId={sellerProperty?.externalId ?? ''}
                address={sellerProperty?.address}
                highestBid={sellerProperty?.highestBid ?? 0}
                evaluation={
                  sellerProperty?.currentValuation?.valuation?.value ?? 0
                }
                coordinates={{
                  latitude:
                    sellerProperty?.currentValuation?.valuation?.coordinates
                      ?.latitude ?? 0,
                  longitude:
                    sellerProperty?.currentValuation?.valuation?.coordinates
                      ?.longitude ?? 0,
                }}
                sellingPrice={sellerProperty?.onOfficePrice ?? 0}
                status={sellerProperty?.status ?? PropertyStatus.Initial}
                newBidsNumber={sellerProperty?.newBidsNumber ?? 0}
                isDigitalServiceActivated={
                  sellerProperty?.isDigitalServiceActivated
                }
                isDigitalServiceLockedByBroker={
                  sellerProperty?.isDigitalServiceLockedByBroker
                }
              />
            ))}
          </ListContainer>
          {totalItems > sellerProperties?.length && !isLoadingMore && (
            <LoadMoreButtonContainer isMobileSize={isMobileSize}>
              <LoadMoreButton
                fluid
                onClick={loadMore}
                label={t('button.load-more')}
                color={themeContext.blue}
                borderColor={themeContext.blue}
              />
            </LoadMoreButtonContainer>
          )}
          {isLoadingMore && (
            <LoaderContainer isLoadingMore>
              <GlobalLoader size={50} isAbsolute />
            </LoaderContainer>
          )}
        </>
      ) : (
        <EmptyStatePlaceholder
          icon={HouseRemove}
          title={t('seller-properties.empty-list.header')}
          description={t('seller-properties.empty-list.text')}
          ctaLabel={t('seller-properties.empty-list.button')}
          onCtaClick={handleVWOpen}
        />
      )}
    </Container>
  );
};

const SellerProperties = memo(SellerPropertiesContainerBase);

export { SellerProperties };
