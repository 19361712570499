import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';

import { ReactComponent as Building } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as BuildingYear } from 'assets/streamline-light/building-construction/utilities/building-year.svg';
import { ReactComponent as RollerIcon } from 'assets/streamline-light/design/colors-painting/color-rolling-brush.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as FloorsIcon } from 'assets/streamline-light/wayfinding/lifts/lift-two-people.svg';
import { ReactComponent as HotelDoubleBed2 } from 'assets/streamline-light/hotels-spas/hotel-bedrooms/hotel-double-bed-2.svg';
import { ReactComponent as Bathroom } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as HouseGarage } from 'assets/streamline-light/building-construction/houses/house-garage.svg';
import { ReactComponent as ParkingIcon } from 'assets/streamline-light/shopping-ecommerce/discount-coupons/discount-parking-2.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';

import PropertyValuationIcon from '../../../../../../../property/components/icon/property-valuation-icon';
import { InfoContainer, IconRow } from './property-details-styles';
import { useExposeDetailsPropertyData } from '../../../../hooks/useExposeDetailsQueries';
import {
  changeMapViewport,
  removeAllMapMarkersExceptHome,
  setMapSettings,
} from '../../../../redux/exposeSlice';
import {
  Property,
  PropertyCode,
  PropertyData,
} from '../../../../../../../../generated';
import { extractPropertyTypeLabelAndIcon } from '../../../../../../../property/utils/property-type-normalizer';
import {
  OutdoorParkingSpaceKeys,
  ParkingSpaceLabelByKey,
} from '../../../../../../../property/constants';

interface IProps {
  property: PropertyData;
}

const PropertyDetails: FC<IProps> = ({ property }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    propertyType: { code },
    buildingYear,
    renovationYear,
    livingArea,
    landArea,
    floorNumber,
    numberOfRooms,
    numberOfBedrooms,
    numberOfBathrooms,
    numberOfOutdoorParkingSpaces,
    numberOfIndoorParkingSpaces,
    location,
    numberOfFloorsInBuilding,
    parkingSpaces,
  } = property;

  const { icon, label } = extractPropertyTypeLabelAndIcon(code);

  const parkingSpacesWithLabel = useMemo(() => {
    if (isEmpty(parkingSpaces)) {
      return [];
    }

    return Object.keys(parkingSpaces || {})
      ?.filter(
        (key) =>
          !OutdoorParkingSpaceKeys.includes(key) &&
          parkingSpaces?.[key as keyof typeof parkingSpaces] !== 0
      )
      ?.map((key) => ({
        label: ParkingSpaceLabelByKey.get(key),
        value: parkingSpaces?.[key as keyof typeof parkingSpaces],
      }));
  }, [parkingSpaces]);

  useEffect(() => {
    if (location?.coordinates) {
      batch(() => {
        dispatch(
          changeMapViewport({
            ...location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setMapSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
      });
    }

    return () => {
      batch(() => {
        dispatch(removeAllMapMarkersExceptHome());
      });
    };
  }, [dispatch, location?.coordinates]);

  return (
    <InfoContainer>
      <IconRow>
        <PropertyValuationIcon
          icon={icon}
          value={String(t(label))}
          type={t('expose-page.tabs.overview.property-details.type')}
        />
        <PropertyValuationIcon
          icon={BuildingYear}
          value={buildingYear || '-'}
          type={t(
            'expose-page.tabs.overview.property-details.construction-year'
          )}
        />
        <PropertyValuationIcon
          icon={RollerIcon}
          value={renovationYear || '-'}
          type={t('expose-page.tabs.overview.property-details.modernization')}
        />
        <PropertyValuationIcon
          icon={RealEstateDimensionsBlock}
          value={`${t('approx')} ${livingArea} m²`}
          type={t('expose-page.tabs.overview.property-details.living-space')}
        />
        <PropertyValuationIcon
          icon={ResizeExpandSides}
          value={landArea ? `${t('approx')} ${landArea} m²` : '-'}
          type={t('expose-page.tabs.overview.property-details.land-area')}
        />

        <PropertyValuationIcon
          icon={FloorsIcon}
          value={
            code === PropertyCode.Apartment
              ? floorNumber
                ? String(floorNumber)
                : '-'
              : numberOfFloorsInBuilding
              ? String(numberOfFloorsInBuilding)
              : '-'
          }
          type={t(
            code === PropertyCode.Apartment
              ? 'expose-page.tabs.overview.property-details.floor'
              : 'property-valuation.form.redesign.number-of-floors-in-building.label'
          )}
        />
        <PropertyValuationIcon
          icon={RealEstateDimensionsPlan}
          value={numberOfRooms || '-'}
          type={t('expose-page.tabs.overview.property-details.number-of-rooms')}
        />
        <PropertyValuationIcon
          icon={HotelDoubleBed2}
          value={numberOfBedrooms || '-'}
          type={t(
            'expose-page.tabs.overview.property-details.number-of-bedrooms'
          )}
        />
        <PropertyValuationIcon
          icon={Bathroom}
          value={numberOfBathrooms || '-'}
          type={t('expose-page.tabs.overview.property-details.bathroom')}
        />
        {parkingSpacesWithLabel?.map((parkingSpace) => (
          <PropertyValuationIcon
            icon={HouseGarage}
            value={parkingSpace.value ?? 0}
            type={t(parkingSpace.label || '')}
          />
        ))}
        <PropertyValuationIcon
          icon={ParkingIcon}
          value={numberOfOutdoorParkingSpaces || '-'}
          type={t('expose-page.tabs.overview.property-details.parking-space')}
        />
      </IconRow>
    </InfoContainer>
  );
};

export { PropertyDetails };
