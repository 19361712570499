import { useCallback } from 'react';
import { useGetChfRateQuery } from '../../../generated';

export const useGetCHFRate = (): number => {
  const { data } = useGetChfRateQuery();

  return data?.rate || 1;
};

export const useConvertEURtoCHF = () => {
  const rate = useGetCHFRate();

  return useCallback(
    (amount: number) => {
      return amount * rate;
    },
    [rate]
  );
};
