import { FC } from 'react';
import { isEmpty } from 'lodash';

import {
  Container,
  InfoContainer,
  SectionContainer,
} from './micro-situation-styles';
import { Header } from '../energy-efficiency/energy-efficiency-styles';
import { scoreNormalizer } from '../../../../../../../common/utils/score-normalizer';
import { MicroSituationCard } from './micro-situation-card/micro-situation-card';
import { useExposeDetailsPriceHubble } from '../../../../hooks/useExposeDetailsQueries';

const MicroSituation: FC = () => {
  const { poi, locationScores, buildingProjects } =
    useExposeDetailsPriceHubble();

  if (!poi) {
    return <></>;
  }

  const lifeQualityScore =
    locationScores &&
    (locationScores.shopping + locationScores.leisure + locationScores.health) /
      3;

  const buildingsWithTitle = buildingProjects?.items
    .filter((item) => item.title)
    .slice(0, 5);

  return (
    <Container>
      <Header content={'Mikrosituation'} />
      <InfoContainer>
        <SectionContainer>
          {!isEmpty(locationScores?.shopping) && (
            <MicroSituationCard
              title="shopping"
              score={scoreNormalizer(locationScores?.shopping || 0)}
            />
          )}
          {!isEmpty(locationScores?.family) && (
            <MicroSituationCard
              title="family"
              score={scoreNormalizer(locationScores?.family || 0)}
            />
          )}
        </SectionContainer>
        <SectionContainer>
          {!isEmpty(lifeQualityScore) && (
            <MicroSituationCard
              title="life-quality"
              score={scoreNormalizer(lifeQualityScore || 0)}
            />
          )}
          {!isEmpty(locationScores?.noise) && (
            <MicroSituationCard
              title="environment"
              score={scoreNormalizer(locationScores?.noise || 0)}
            />
          )}
        </SectionContainer>
        <SectionContainer>
          {buildingProjects && (
            <MicroSituationCard
              title="construction"
              constructions={buildingsWithTitle}
              totalBuildings={buildingProjects.totalItems?.toString()}
            />
          )}
        </SectionContainer>
      </InfoContainer>
    </Container>
  );
};

export { MicroSituation };
