import { FC } from 'react';

import {
  BuyerLpSection,
  BuyerLpSectionType,
  OverviewLpSection,
  OverviewLpSectionType,
  OwnerLpSection,
  SellerLpSection,
  SellerLpSectionType,
} from '../../../../../generated';
import {
  FunctionsContainer,
  FunctionsGrid,
  Header,
} from './functions-section-styles';
import {
  FunctionCardBuyer,
  FunctionCardOwner,
  FunctionCardSeller,
} from './function-card-item';
import { BgColor } from '../overview-section/overview-section-styles';

interface IProps {
  section:
    | OverviewLpSection
    | BuyerLpSection
    | OwnerLpSection
    | SellerLpSection;
  bgWhite?: boolean;
}

const FunctionsSection: FC<IProps> = ({ section, bgWhite }) => {
  const { recordIds, type, title } = section;
  return (
    <FunctionsContainer>
      {type === OverviewLpSectionType.BuyerFunction && !bgWhite && <BgColor />}
      <Header content={title} />
      <FunctionsGrid isSeller={type === OverviewLpSectionType.SellerFunction}>
        {type === OverviewLpSectionType.OwnerFunction &&
          recordIds.map((id: string) => {
            return <FunctionCardOwner key={id} id={id} />;
          })}
        {(type === OverviewLpSectionType.BuyerFunction ||
          type === BuyerLpSectionType.BuyerFunction) &&
          recordIds.map((id: string) => {
            return <FunctionCardBuyer key={id} id={id} />;
          })}
        {(type === OverviewLpSectionType.SellerFunction ||
          type === SellerLpSectionType.SellerFunction) &&
          recordIds.map((id: string) => {
            return <FunctionCardSeller key={id} id={id} />;
          })}
      </FunctionsGrid>
    </FunctionsContainer>
  );
};

export { FunctionsSection };
