import styled from 'styled-components';
import { device } from 'style/theme';
import {
  BoxTitle,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { MainButton } from '../../../../common/components/ui/buttons';

export const MarketPriceCardElementsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0 12px;
`;

export const MarketDataUpdateMessageContainer = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.33;
  /* border-radius: 3px;
  padding: 12px 12px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2); */
`;

export const MarketDataUpdateDate = styled(ParagraphText)`
  font-weight: bold;
  font-size: 12px;
  padding: 0 12px 0 0;
`;
export const MarketDataUpdateMessage = styled(ParagraphText)`
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  #market-price.tooltip {
    max-width: 95%;
  }
  > div:last-child {
    margin-left: 12px;
    margin-bottom: 8px;
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 10px 0 10px 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const TooltipMail = styled.a``;

export const FeedbackWrapper = styled.div`
  .outer-accordion {
    margin: 32px -32px -32px -32px;
    border: none;

    .outer-header {
      background: rgb(229, 233, 236, 0.4);
      transition: all 0.3s;
      border-radius: 0;
      padding: 16px 16px 16px 32px;

      &:hover {
        background: rgb(229, 233, 236, 1);
        text-decoration: underline;
      }

      .icon {
        order: 2;
        margin-left: auto;
      }
    }

    .rc-collapse-content {
      background: rgb(229, 233, 236, 0.4);
      color: #00305e;
      padding: 8px 32px 32px;

      .rc-collapse-content-box {
        margin: 0;
      }
    }

    @media ${device.tablet} {
      margin: 16px -16px -16px -16px;

      .outer-header {
        padding-left: 16px;
      }

      .rc-collapse-content {
        padding: 8px 16px 16px;
      }
    }
  }
`;

export const FeedbackHeader = styled(BoxTitle)`
  font-size: 14px;
  font-weight: 500;
`;

export const RatingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 450px;
`;

export const RatingItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.grey};
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  background: ${(props) => props.theme.white};

  &.active {
    svg {
      path {
        stroke: ${(props) => props.theme.blue};
        fill: ${(props) => props.theme.blue};
      }
    }
  }

  &:hover {
    &:not(.active) {
      svg {
        path {
          stroke: ${(props) => props.theme.ctaBlue};
        }
      }
    }
  }
`;

export const RatingLegend = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  max-width: 450px;
`;

export const Form = styled.form`
  padding: var(--main-padding);
`;

export const TextareaWrap = styled.div``;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--main-padding);
  margin-top: 24px;
`;

export const InputWrapper = styled.div`
  max-width: 450px;

  .label {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.blue};
  }

  .input-wrapper {
    input {
      min-width: 160px;
    }
  }
`;

export const Delimiter = styled.div`
  background: ${(props) => props.theme.blue};
  height: 2px;
  margin: 16px 0;
`;

export const SubmitButton = styled(MainButton)``;

export const SwitchWrapper = styled.div`
  margin: 16px 0 0;
  display: flex;
  align-items: center;
  gap: 8px;

  .switch {
    margin: 0;
  }
`;

export const SwitchLabel = styled(ParagraphText)``;
