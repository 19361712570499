import 'mapbox-gl/dist/mapbox-gl.css';

import circle from '@turf/circle';
import { isBoolean } from 'lodash';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGL, {
  Layer,
  NavigationControl,
  Popup,
  Source,
} from 'react-map-gl';
import { MapLoadEvent } from 'react-map-gl/src/mapbox/mapbox';
import styled from 'styled-components';

import { defaultTheme, device } from '../../../style/theme';
import {
  useAppDispatch,
  useAppSelector,
  useWindowSize,
} from '../../common/hooks';
import { useIsMobileSize } from '../../common/hooks/useIsMobileSize';
import { changeViewport } from '../redux/mapSlice';
import { CommuteTime } from './commute-time';
import { Legend } from './legend';
import { Pins } from './pins';
import { ReachabilityLegend } from './reachability-legend';

interface IProps {
  isVisible: boolean;
  isMapAtBottomProp?: boolean;
}

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 106px;
  left: 4px;
  z-index: 0;
  color: #dfdfdf;
  display: flex;
  flex-direction: column;

  > div {
    position: static !important;
    border: none;
    border-radius: 2px;

    > div {
      margin: 0 0 10px 10px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
      border-radius: 2px;

      > button {
        width: 34px;
        height: 34px;
        border: none;
        margin: 0;
        border-radius: 2px;

        &:nth-child(2) {
          border-radius: 0;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
`;

const Container = styled.div<{
  width?: string;
  left?: string;
  top?: string;
  isVisible?: boolean;
  position?: string;
  hasTopRadius?: boolean;
  isFullWidth?: boolean;
}>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: ${({ position }) => position};
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  transform: ${({ top }) => `translateY(${top})`};
  left: ${({ left }) => left};
  z-index: 0;
  transition: transform 665ms ease-in-out, opacity 665ms ease;
  will-change: transform, opacity;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;
  max-width: 100vw;

  ${({ hasTopRadius }) => hasTopRadius && `border-radius: 3px;`};
  ${({ isFullWidth }) => isFullWidth && `width: 100%;`};

  @media ${device.tablet} {
    width: 100%;
  }

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib-inner {
    display: none !important;
  }
`;

const StyledPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: 8px;
    font-family: 'Source Serif Pro', serif;
    color: ${(props) => props.theme.blue};
    font-size: 14px;
    background-color: ${(props) => props.theme.white};
  }
  .mapboxgl-popup-tip {
    border-top-color: ${(props) => props.theme.white};
  }
`;

const radiusStyle = {
  paint: {
    'fill-outline-color': defaultTheme.blue,
    'fill-color': defaultTheme.blue,
    'fill-opacity': 0.1,
  },
};

const MapboxBase = ({ isVisible, isMapAtBottomProp }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isValuationWizardOpen } = useAppSelector(
    (state) => state.valuationWizard
  );
  const { viewport, settings, markers, popup } = useAppSelector(
    (state) => state.map
  );

  const {
    showMap: isMapAtBottomState,
    showLegend,
    isFullScreenMapOpen,
    showReachabilityLegend,
    commuteTimeCoordinates,
    isReachabilityTab,
  } = useAppSelector((state) => state.dynamicMapUtils);

  const isMapAtBottom = isBoolean(isMapAtBottomProp)
    ? isMapAtBottomProp
    : isMapAtBottomState;

  const isMobileSize = useIsMobileSize();
  const windowSize = useWindowSize();

  const { longitude, latitude } = viewport;
  const { radius } = settings;
  const fixRadiusLongitude = (longitude as number) - 0.005;
  const fixRadiusLatitude = (latitude as number) + 0.001;

  const [radiusData, setRadiusData] = useState(
    circle([fixRadiusLongitude, fixRadiusLatitude], radius, {
      units: 'kilometers',
    })
  );

  const changeMapLanguage = (e: MapLoadEvent) => {
    e?.target.getStyle()?.layers?.forEach((layer) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ('layout' in layer && layer.layout['text-field']) {
        e.target.setLayoutProperty(layer.id, 'text-field', ['get', 'name_de']);
      }
    });
  };

  const [innerViewport, setInnerViewport] =
    useState<Record<string, unknown>>(viewport);

  const onViewportChange = (newViewport: Record<string, unknown>) => {
    setInnerViewport(newViewport);
  };

  const left = useMemo(
    () => `${((windowSize?.width ?? 0) - 690 - 400 - 14) / 2}px`,
    [windowSize?.width]
  );

  useEffect(() => {
    setInnerViewport(viewport);
  }, [viewport]);

  useEffect(() => {
    dispatch(
      changeViewport({
        width: isMobileSize ? '100%' : isMapAtBottom ? '690px' : '1200px',
      })
    );
  }, [dispatch, isMapAtBottom, isMobileSize]);

  useEffect(() => {
    setRadiusData(
      circle([fixRadiusLongitude, fixRadiusLatitude], radius, {
        units: 'kilometers',
      })
    );
  }, [fixRadiusLatitude, fixRadiusLongitude, radius]);

  const mapStyle = `mapbox://styles/mapbox/${
    isReachabilityTab && !commuteTimeCoordinates
      ? 'navigation-day-v1'
      : 'light-v10'
  }`;
  const isMobileFullScreen =
    (isFullScreenMapOpen && isMobileSize) || !isMobileSize;

  const mapboxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapboxContainerRef.current) {
      return () => {};
    }

    const mapContainer = mapboxContainerRef.current;

    const wheelHandler = (e: Event) => {
      const wheelEvent = e as WheelEvent;

      if (wheelEvent.ctrlKey || wheelEvent.metaKey || wheelEvent.altKey) {
        return;
      }

      e.stopPropagation();
    };

    mapContainer.addEventListener('wheel', wheelHandler, true);

    return () => {
      mapContainer.removeEventListener('wheel', wheelHandler);
    };
  });

  return (
    <Container
      id="mapbox-container"
      ref={mapboxContainerRef}
      isVisible={isVisible}
      left={isMapAtBottom && !isReachabilityTab ? left : '0'}
      top={isMapAtBottom && !isReachabilityTab ? '50px' : '0'}
      position={isMapAtBottom && !isReachabilityTab ? 'fixed' : 'absolute'}
      hasTopRadius={isMapAtBottom && !isReachabilityTab}
      isFullWidth={!isMapAtBottom && !isReachabilityTab}
    >
      {isMobileFullScreen && !isValuationWizardOpen && showLegend && <Legend />}
      {isMobileFullScreen &&
        !isValuationWizardOpen &&
        showReachabilityLegend && (
          <ReachabilityLegend isTabNearTopProp={isMapAtBottomProp} />
        )}
      <ReactMapGL
        height={isMobileSize ? '160px' : '640px'}
        {...innerViewport}
        {...settings}
        width={isMobileSize ? '100%' : isMapAtBottom ? '690px' : '1200px'}
        getCursor={() => 'default'}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
        mapStyle={mapStyle}
        asyncRender
        style={{ color: 'transparent' }}
        onLoad={changeMapLanguage}
      >
        {radius && (
          <Source id="radiusCircle" type="geojson" data={radiusData}>
            <Layer id="radius" {...radiusStyle} type="fill" />
          </Source>
        )}
        {commuteTimeCoordinates && (
          <CommuteTime commuteTimeCoordinates={commuteTimeCoordinates} />
        )}
        <Pins markers={markers} />
        {isVisible && !isValuationWizardOpen && (
          <ControlsContainer>
            {/* <GeoLocateController /> */}
            <NavigationControl />
          </ControlsContainer>
        )}
        {popup?.info && (
          <StyledPopup
            latitude={popup.latitude}
            longitude={popup.longitude}
            anchor="bottom"
            closeButton={false}
            offsetTop={-32}
          >
            {popup.info}
          </StyledPopup>
        )}
      </ReactMapGL>
    </Container>
  );
};

const Mapbox = memo(MapboxBase);

export { Mapbox };
