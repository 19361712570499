import { FC } from 'react';

import { EnergyEfficiency } from './energy-efficiency/energy-efficiency';
import { MicroSituation } from './micro-situation/micro-situation';
import { Tour360 } from './tour-360/tour-360';
import { PropertyDetails } from './property-details/property-details';
import { Gallery } from '../../slider/gallery';
import { Property, PropertyData } from '../../../../../../../generated';
import { Description } from './description/description';
// import { ValueDevelopment } from './value-development/value-development';

interface IProps {
  property: Partial<Property>;
}

const Overview: FC<IProps> = ({ property }) => {
  return (
    <>
      <Gallery images={property?.photos ?? []} />
      {property?.propertyData && (
        <PropertyDetails property={property.propertyData} />
      )}

      {property.energyCertificate && (
        <EnergyEfficiency certificate={property.energyCertificate} />
      )}

      <MicroSituation property={property} />

      {/* <ValueDevelopment /> */}

      {property?.tour360Link && <Tour360 link={property.tour360Link} />}

      <Description property={property} />
    </>
  );
};

export { Overview };
