import { MouseEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { device } from '../../../../../style/theme';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { useAppSelector } from '../../../hooks';

interface IProps {
  onClick: (e?: MouseEvent) => void;
  label: string;
  id?: string;
  isDisabled?: boolean;
  type?: 'button' | 'submit';
  isUpperCase?: boolean;
}

const BorderButtonBase = styled.button<{
  isDisabled?: boolean;
  isiframe?: boolean;
}>`
  box-sizing: content-box;
  padding: 12px 24px;
  border-radius: 3px;
  background-color: transparent;

  border: 2px solid ${(props) => props.theme.ctaBlue};
  outline: none;

  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${(props) => props.theme.ctaBlue};
  cursor: pointer;
  transition: background-color 225ms ease !important;
  &:hover {
    border-radius: 3px;
    background-color: ${(props) => hexToRGB(props.theme.ctaBlue, 0.2)};
  }
  @media ${(isiframe) => (!isiframe ? device.tablet : '0')} {
    min-width: 288px;
    margin: 0 auto;
  }
  ${(props) =>
    props.isDisabled &&
    `
    background-color: ${props.theme.ctaDisabledBackground};
    pointer-events: none;
    color: ${hexToRGB('#000000', 0.3)};
  `}
`;

const BorderButton = ({
  onClick,
  label,
  isUpperCase,
  id,
  ...rest
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  return (
    <BorderButtonBase id={id} onClick={onClick} isiframe={isIframe} {...rest}>
      {isUpperCase ? t(label).toUpperCase() : label}
    </BorderButtonBase>
  );
};

export { BorderButton };
