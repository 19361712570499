import { FC } from 'react';
import styled from 'styled-components';
import { BaseButton } from './base-button';

const WhiteGhostButtonBase = styled(BaseButton)`
  border: solid 2px ${({ theme }) => theme.white};
  background-color: transparent;
  color: ${({ theme }) => theme.white};
  transition: background-color 225ms ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

interface IProps {
  label: string;
  onClick: () => void;
  id?: string;
}

const WhiteGhostButton: FC<IProps> = ({ label, onClick, ...rest }) => {
  return (
    <WhiteGhostButtonBase onClick={onClick} {...rest}>
      {label}
    </WhiteGhostButtonBase>
  );
};

export { WhiteGhostButton };
