import { ChangeEvent, FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  CollapseButton,
  Header,
  TotalCount,
  SearchInputWrapper,
  Title,
  TitleWrapper,
} from '../offers-statistics-card-styles';
import { InputSearch } from '../../../../common/components/form/input/input-search';
import { FilterButton } from '../../marketing/filter-button/filter-button';
import { ReactComponent as ArrowDown } from '../../../../../assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  searchInput: string;
  onSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  onApplyFilters: () => void;
  onResetFilters: () => void;
  isFilterActive: boolean;
  isAccordionsOpen: boolean;
  onCollapseAllAccordions: () => void;
  totalCount: string;
  startDate?: string;
  endDate?: string;
  isSimulation?: boolean;
}

const FilterHeaderBase: FC<IProps> = ({
  searchInput,
  onSearchInputChange,
  onClearSearch,
  onApplyFilters,
  onResetFilters,
  isFilterActive,
  isAccordionsOpen,
  onCollapseAllAccordions,
  totalCount,
  startDate,
  endDate,
  isSimulation,
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  return (
    <Header>
      <TitleWrapper>
        <Title content={t('selling-simulation.offers-statistics-card.title')} />
        <TotalCount content={totalCount} />
      </TitleWrapper>
      <SearchInputWrapper>
        {!isSimulation && (
          <InputSearch
            placeholder={t('seller-properties-list.search-input.placeholder')}
            animateWidth={
              isMobileSize
                ? undefined
                : {
                    from: '160px',
                    to: '240px',
                  }
            }
            onChange={onSearchInputChange}
            value={searchInput}
            onClearSearch={onClearSearch}
            containerStyle={{ ...(isMobileSize ? { width: '100%' } : {}) }}
          />
        )}
        <FilterButton
          onSubmit={onApplyFilters}
          onReset={onResetFilters}
          isActive={isFilterActive}
          startDate={startDate}
          endDate={endDate}
          isSimulation={isSimulation}
        />
        <CollapseButton
          label={''}
          onClick={onCollapseAllAccordions}
          icon={ArrowDown}
          color={theme.grey2}
          iconStyle={{
            width: '16px',
            height: '8px',
            margin: 0,
            transition: `transform 500ms ease`,
            transform: isAccordionsOpen ? 'rotate(-180deg)' : 'none',
            strokeWidth: '3px',
          }}
          borderColor={theme.borderColor}
        />
      </SearchInputWrapper>
    </Header>
  );
};

const FilterHeader = memo(FilterHeaderBase);

export { FilterHeader };
