import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PropertyFilterInput,
  PaginationArgs,
  Coordinates,
  PropertyStatus,
  Direction,
  Currency,
} from '../../../generated';
import { UploadedFile } from '../../common/components/form/input/input-file/input-file';
import {
  NewPurchaseOffer,
  OrderStatisticsBy,
  StatisticsFilterForm,
} from '../interfaces';

export type PropertyFilters = PropertyFilterInput & PaginationArgs;

export interface EvaluatedProperty {
  currentValuationIndex: number;
  currentValuation: {
    valuation: {
      coordinates: Coordinates | null;
      value: number;
    };
  };
  livingArea: number;
  address: string;
  countryCode: string;
  priceCurrency: Currency;
  _id: string;
  status: PropertyStatus;
  title: string;
  imageSrc?: string;
  highestBid?: number;
  newBidsNumber?: number;
  onOfficePrice?: number;
  externalId?: string;
  isDigitalServiceActivated?: boolean;
  isDigitalServiceLockedByBroker?: boolean;
  mapboxImageUrl?: string;
  isPreview?: boolean;
}

interface InitialState {
  filters: PropertyFilters | unknown;
  sellerProperties: EvaluatedProperty[];
  totalItems: number;
  initialLoader: boolean;
  totalArea: number;
  propertyDocuments: UploadedFile[];
  propertyPhotos: UploadedFile[];
  propertyPlans: UploadedFile[];
  selectedPurchaseOffer: NewPurchaseOffer;
  isAcceptPurchaseOfferModalOpen: boolean;
  marketingStatisticsFilter: StatisticsFilterForm;
  isFilterOpen: boolean;
  isServiceExampleOpen: boolean;
  isContactFormOpen: boolean;
  isUpdateContactsModalOpen: boolean;
  shouldResetFilter: boolean;
  propertiesTabScrollLeft: number;
  isPreview: boolean;
  portalGallerySettings: {
    isOpenModal: boolean;
    activeSlide: number;
  };
  isReadOnlyAccess: boolean;
}

export const filtersInitialState: PropertyFilters = {
  offset: 0,
  limit: 8,
  search: '',
};

const initialState: InitialState = {
  filters: filtersInitialState,
  sellerProperties: [],
  totalItems: 0,
  initialLoader: true,
  totalArea: 0,
  propertyDocuments: [],
  propertyPhotos: [],
  propertyPlans: [],
  selectedPurchaseOffer: {
    bidOnOfficeId: '',
    customerId: 0,
    fromCity: '',
    price: '',
    date: new Date().toISOString(),
  },
  isAcceptPurchaseOfferModalOpen: false,
  marketingStatisticsFilter: {
    dateFrom: '',
    dateTo: '',
    // orderBy: {
    //   field: OrderStatisticsBy.Date,
    //   direction: Direction.Desc,
    // },
  },
  isFilterOpen: false,
  isServiceExampleOpen: false,
  isContactFormOpen: false,
  isUpdateContactsModalOpen: false,
  shouldResetFilter: false,
  propertiesTabScrollLeft: 0,
  isPreview: false,
  portalGallerySettings: {
    isOpenModal: false,
    activeSlide: 1,
  },
  isReadOnlyAccess: false,
};

export const sellerPropertiesSlice = createSlice({
  name: 'sellerProperties',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<PropertyFilters>) {
      state.filters = action.payload;
    },
    resetFilters(state) {
      state.filters = filtersInitialState;
    },
    mergeFilters(state, action: PayloadAction<Partial<PropertyFilters>>) {
      state.filters = {
        ...(state.filters as PropertyFilters),
        ...action.payload,
      };
    },
    clearSellerPropertiesCache(state) {
      state.sellerProperties = [];
    },
    addSellerProperties(state, action: PayloadAction<EvaluatedProperty[]>) {
      state.sellerProperties = [...state.sellerProperties, ...action.payload];
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setInitialLoader(state, action: PayloadAction<boolean>) {
      state.initialLoader = action.payload;
    },
    setTotalArea(state, action: PayloadAction<number>) {
      state.totalArea = action.payload;
    },
    setPropertyDocuments(state, action: PayloadAction<UploadedFile[]>) {
      state.propertyDocuments = action.payload;
    },
    setPropertyPhotos(state, action: PayloadAction<UploadedFile[]>) {
      state.propertyPhotos = action.payload;
    },
    setPropertyPlans(state, action: PayloadAction<UploadedFile[]>) {
      state.propertyPlans = action.payload;
    },
    clearFiles(state) {
      state.propertyDocuments = [];
      state.propertyPhotos = [];
      state.propertyPlans = [];
    },
    setSelectedPurchaseOffer(state, action: PayloadAction<NewPurchaseOffer>) {
      state.selectedPurchaseOffer = action.payload;
    },
    toggleAcceptPurchaseOfferModalOpen(state, action: PayloadAction<boolean>) {
      state.isAcceptPurchaseOfferModalOpen = action.payload;
    },
    mergeMarketingStatisticsFilter(
      state,
      action: PayloadAction<Partial<StatisticsFilterForm>>
    ) {
      state.marketingStatisticsFilter = {
        ...state.marketingStatisticsFilter,
        ...action.payload,
      };
    },
    clearMarketingStatisticsFilter(state) {
      state.marketingStatisticsFilter = initialState.marketingStatisticsFilter;
    },
    toggleFilterOpen(state, action: PayloadAction<boolean>) {
      state.isFilterOpen = action.payload;
    },
    toggleServiceExampleOpen(state, action: PayloadAction<boolean>) {
      state.isServiceExampleOpen = action.payload;
    },
    toggleContactFormOpen(state, action: PayloadAction<boolean>) {
      state.isContactFormOpen = action.payload;
    },
    toggleUpdateContactsModalOpen(state, action: PayloadAction<boolean>) {
      state.isUpdateContactsModalOpen = action.payload;
    },
    toggleShouldResetFilter(state, action: PayloadAction<boolean>) {
      state.shouldResetFilter = action.payload;
    },
    setPropertiesTabScrollLeft(state, action: PayloadAction<number>) {
      state.propertiesTabScrollLeft = action.payload;
    },
    toggleIsPreview(state, action: PayloadAction<boolean>) {
      state.isPreview = action.payload;
    },
    toggleSellerPortalModalGallery(state, action: PayloadAction<boolean>) {
      state.portalGallerySettings.isOpenModal = action.payload;
    },
    setSellerPortalGalleryActiveSlide(state, action: PayloadAction<number>) {
      state.portalGallerySettings.activeSlide = action.payload;
    },
    setIsReadOnlyAccess(state, action: PayloadAction<boolean>) {
      state.isReadOnlyAccess = action.payload;
    },
  },
});

export const {
  setFilters,
  mergeFilters,
  clearSellerPropertiesCache,
  addSellerProperties,
  setTotalItems,
  setInitialLoader,
  setTotalArea,
  resetFilters,
  setPropertyDocuments,
  setPropertyPhotos,
  setPropertyPlans,
  clearFiles,
  setSelectedPurchaseOffer,
  toggleAcceptPurchaseOfferModalOpen,
  mergeMarketingStatisticsFilter,
  clearMarketingStatisticsFilter,
  toggleFilterOpen,
  toggleServiceExampleOpen,
  toggleContactFormOpen,
  toggleUpdateContactsModalOpen,
  toggleShouldResetFilter,
  setPropertiesTabScrollLeft,
  toggleIsPreview,
  toggleSellerPortalModalGallery,
  setSellerPortalGalleryActiveSlide,
  setIsReadOnlyAccess,
} = sellerPropertiesSlice.actions;

export const { reducer, name } = sellerPropertiesSlice;

export default sellerPropertiesSlice;
