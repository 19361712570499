import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import { BoxText, BoxTitle } from '../../../../ui/typography';
import { SecondaryButton } from '../../../../ui/buttons';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div<{ showPreview?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-areas:
    '${(props) => props.showPreview && 'preview'} title label size actions'
    '${(props) => props.showPreview && 'preview'} fileName label size actions';
  grid-template-columns: ${(props) =>
    props.showPreview
      ? 'max-content 1fr auto 70px auto'
      : '1fr auto 70px auto'};
  grid-template-rows: 1fr 1fr;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 3px;
  padding-left: ${(props) => (props.showPreview ? 0 : '4px')};

  @media ${device.mobile} {
    grid-template-areas:
      '${(props) => props.showPreview && 'preview'} title label actions'
      '${(props) => props.showPreview && 'preview'} fileName label actions';
    grid-template-columns: ${(props) =>
      props.showPreview ? 'max-content 1fr auto auto' : '1fr auto auto'};
  }
`;

export const PreviewImage = styled.img`
  width: 82px;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media ${device.tablet} {
    width: 52px;
  }
`;

export const TitleWrapper = styled.div`
  grid-area: title;
  align-self: end;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    margin: 8px 0 0 0;
  }
`;

export const Title = styled(BoxTitle)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;

  @media ${device.mobile} {
    font-size: 11px;
    width: 120px;
  }
`;

export const FileName = styled(BoxText)`
  opacity: 0.6;
  font-weight: bold;
  line-height: 1.33;
  grid-area: fileName;
  align-self: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;

  @media ${device.mobile} {
    margin-left: 0;
    font-size: 11px;
    width: 120px;
  }
`;

export const FileSize = styled(BoxText)`
  opacity: 0.5;
  line-height: 1.33;
  grid-area: size;
  justify-self: end;
  margin-right: 12px;

  @media ${device.tablet} {
    font-size: 10px;
    margin-right: 8px;
  }
`;

export const Preview = styled.div<{ isImage?: boolean }>`
  grid-area: preview;
  border-radius: 3px;
  padding: ${(props) => (props.isImage ? 0 : '15px 14px')};
  background-color: ${(props) => props.theme.greyBackground};
  margin: 4px 12px 4px 4px;
  ${(props) => props.isImage && 'height: 54px; overflow: hidden'};
`;

export const ActionsContainer = styled.div`
  grid-area: actions;
  display: flex;
  gap: 8px;
  padding: 12px;
  border-left: 1px solid ${(props) => props.theme.borderColor};
  height: 100%;
  align-items: center;

  @media ${device.tablet} {
    padding: 8px;
  }
`;

export const DeleteFileButton = styled(SecondaryButton)`
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media ${device.tablet} {
    width: 24px;
    height: 24px;
  }
`;

export const OpenFileButton = styled(SecondaryButton)`
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media ${device.tablet} {
    width: 24px;
    height: 24px;
  }
`;

export const OpenAccessManagerButton = styled(SecondaryButton)`
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media ${device.tablet} {
    width: 24px;
    height: 24px;
  }
`;

export const EditFileName = styled(ContentEditable)`
  margin: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  width: fit-content;

  @media ${device.tablet} {
    font-size: 11px;
    width: 120px;
  }
`;

const BaseLabel = styled.div`
  grid-area: label;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.blue};
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 10px;
`;

export const NotReleasedLabel = styled(BaseLabel)`
  background-color: #f0e9e9;
  border: ${(props) => `1px solid ${props.theme.lightRed}`};
  color: ${(props) => props.theme.red};
`;

export const ReleasedForSomeUsersLabel = styled(BaseLabel)`
  background-color: ${(props) => props.theme.tagGoldBackground};
  border: ${(props) => `1px solid ${props.theme.tagGoldBorder}`};
  color: ${(props) => props.theme.gold};
`;

export const ReleasedForAllLabel = styled(BaseLabel)`
  background-color: ${(props) => props.theme.paleGreen};
  border: ${(props) => `1px solid ${props.theme.tagGreenBorder}`};
  color: ${(props) => props.theme.green};
`;
