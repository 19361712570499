import {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { VirtuosoGrid, VirtuosoHandle, Virtuoso } from 'react-virtuoso';
import styled, { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';

import { ReactComponent as GridView } from 'assets/streamline-light/interface-essential/layouts/layout-module-1.svg';
import { ReactComponent as ListView } from 'assets/streamline-light/interface-essential/menu/navigation-menu-1.svg';
import { LoaderContainer } from 'modules/search-profile/pages/search-profile-page/search-profile-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import {
  ExposeRequest,
  ExposeStatus,
  PropertyCode,
  SuggestedPropertiesFilterInput,
  useExposeRequestsQuery,
  useSuggestedPropertiesQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { Headline2 } from '../../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { scrollToProperty } from '../../../property/redux/dynamicMapUtilsSlice';
import {
  addSuggestedProperties,
  clearSuggestedPropertiesCache,
  mergeFilters,
  setInitialLoader,
  setTotalItems,
} from '../../redux/suggestedPropertiesSlice';
import { OrderBy } from './order-by';
import Icon from '../../../common/components/ui/icon';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { SuggestedPropertyCard } from './suggested-property-card';
import { SuggestedPropertiesResponse } from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties';
import { OnlineExposesResponse } from '../online-exposes/container';
import {
  useSearchProfileQuery,
  useUpdateSearchProfileMutation,
} from '../../../../services/graphql/enhanced';
import { useGetCountry } from '../../../localization/get-country';
import { useOrderBy } from '../../hooks/useOrderBy';
import {
  setActiveTab,
  setExternalPropertyId,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
  setSelectedPropertyCode,
  setSelectedPropertyId,
  toggleExposeOverlay,
  setFinancingId,
} from '../../pages/expose-details-overlay/redux/exposeSlice';
import { getPropertyCodeFromType } from '../../../property/utils/property-type-normalizer';
import EmptyStatePlaceholder from './empty-state-placeholder';

const ItemContainer = styled.div<{ isShrinked: boolean; isListView?: boolean }>`
  padding: 0;
  margin: ${(props) => (props.isListView ? '0 0 12px 0' : '0 6px 12px 6px')};
  width: ${({ isShrinked, isListView }) =>
    isShrinked || isListView ? '100%' : 'calc(50% - 12px)'};
  min-width: ${(props) => (props.isListView ? '100%' : 'unset')};

  @media ${device.tablet} {
    width: 100%;
    margin: 0 0 12px 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const LoadMoreButtonContainer = styled.div<{
  isListView?: boolean;
  isMobileSize?: boolean;
}>`
  margin: ${(props) =>
    props.isListView || props.isMobileSize ? '40px 0 0 0' : '40px 6px 0 6px'};
`;

const LoadMoreButton = styled(SecondaryButton)`
  justify-content: center;
`;

const Header = styled.div<{ isShrinked: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  ${({ isShrinked }) =>
    isShrinked &&
    `
    >div:last-child {
      margin-left: 0;
      flex: 1;
      justify-content: space-between;
    }
  `} @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled(Headline2)<{ isListView?: boolean }>`
  margin-left: ${(props) => (props.isListView ? 0 : '6px')};

  @media ${device.tablet} {
    margin-left: unset;
  }
`;

const Count = styled.div`
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.ctaBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #fff;
`;

enum Views {
  LIST = 'List',
  GRID = 'Grid',
}

const SuggestedPropertiesContainerBase = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [currentView, setCurrentView] = useState<Views>(Views.GRID);
  const isMobileSize = useIsMobileSize();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const { language } = useGetCountry();
  const { switchOrder } = useOrderBy();

  const searchProfileLocation = useAppSelector((state) => state.map.markers[0]);
  const searchProfileRadius = useAppSelector(
    (state) => state.map.settings.radius
  );

  const isShrinked = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const filters = useAppSelector(
    (state) => state.suggestedProperties.filters
  ) as SuggestedPropertiesFilterInput;
  const suggestedProperties = useAppSelector(
    (state) => state.suggestedProperties.suggestedProperties
  );
  const totalItems = useAppSelector(
    (state) => state.suggestedProperties.totalItems
  );
  const initialLoader = useAppSelector(
    (state) => state.suggestedProperties.initialLoader
  );
  const propertyIndexForScrolling = useAppSelector(
    (state) => state.dynamicMapUtils.propertyIndexForScrolling
  );

  const { data: onlineExposes, isLoading: isOnlineExposesLoading } =
    useExposeRequestsQuery<OnlineExposesResponse>({
      searchProfileId,
    });

  const [updateSearchProfile, { isLoading: isUpdateSearchProfileLoading }] =
    useUpdateSearchProfileMutation();

  const requestedPropertiesForExpose = useMemo(
    () =>
      onlineExposes?.exposeRequests?.page?.map(
        (expose: ExposeRequest) => expose.propertyDetails?.externalId
      ),
    [onlineExposes]
  );

  const unlockedExposes = useMemo(
    () =>
      onlineExposes?.exposeRequests?.page
        ?.filter(
          (expose: ExposeRequest) => expose.status === ExposeStatus.Unlocked
        )
        .map((expose: ExposeRequest) => ({
          exposeId: expose._id,
          propertyId: expose.propertyDetails?.externalId,
        })),
    [onlineExposes]
  );

  const {
    dislikedData: disliked,
    likedData: liked,
    latestSuggestedPropertiesData: latestSuggestedProperties,
    isLoading: isFavouriteListLoading,
    searchProfileDetails: searchProfileData,
    countryCodeBase: countryCode,
    priceOffersData: priceOffers,
  } = useSearchProfileQuery(
    {
      id: searchProfileId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        const dislikedData = data?.searchProfile?.disliked;
        const likedData = data?.searchProfile?.liked;
        const priceOffersData = data?.searchProfile?.priceOffers;
        const searchProfileDetails = data?.searchProfile?.searchProfileData;
        const latestSuggestedPropertiesData =
          data?.searchProfile?.latestSuggestedProperties;

        return {
          dislikedData,
          likedData,
          priceOffersData,
          error,
          isLoading,
          isSuccess,
          searchProfileDetails,
          latestSuggestedPropertiesData,
          countryCodeBase: data?.searchProfile?.countryCode,
        };
      },
      refetchOnMountOrArgChange: true,
    }
  );

  const latestSuggestedPropertiesIds = latestSuggestedProperties?.map(
    (item) => item.id
  );

  const favouritePropertiesIds = useMemo(() => {
    return liked?.map((item) => item.id) ?? [];
  }, [liked]);

  const { data, isLoading: suggestedPropertiesLoading } =
    useSuggestedPropertiesQuery<SuggestedPropertiesResponse>(
      {
        filter: {
          offset: filters.offset ?? 0,
          limit: filters.limit ?? 8,
          orderBy: {
            field: filters.orderBy?.field,
            direction: filters.orderBy?.direction,
            proximityParams: {
              latitude: searchProfileLocation?.latitude ?? 0,
              longitude: searchProfileLocation?.longitude ?? 0,
            },
          },
          unsuitablePropertiesIds: disliked ?? [],
          searchProfileData,
          countryCode,
        },
        searchProfileId,
      },
      {
        skip:
          !searchProfileId ||
          !disliked ||
          !searchProfileLocation?.latitude ||
          !searchProfileLocation?.longitude,
      }
    );

  const [isLoading, setIsLoading] = useState(false);
  const loadMore = useCallback(() => {
    if (totalItems > (filters?.offset ?? 0)) {
      setIsLoading(true);
      dispatch(
        mergeFilters({
          limit: 8,
          offset: +(filters.offset ?? 0) + 8,
        })
      );
    }
  }, [dispatch, filters.offset, totalItems]);

  const onExpandRadius = useCallback(async () => {
    await updateSearchProfile({
      input: {
        _id: searchProfileId,
        searchProfileData: {
          radius: searchProfileRadius + 35,
        },
      },
    });
    dispatch(setInitialLoader(true));
  }, [dispatch, searchProfileId, searchProfileRadius, updateSearchProfile]);

  useEffect(() => {
    batch(() => {
      dispatch(clearSuggestedPropertiesCache());
      dispatch(
        mergeFilters({
          offset: 0,
          limit: 8,
        })
      );
    });
  }, [searchProfileRadius]);

  useEffect(() => {
    if (
      searchProfileLocation?.latitude &&
      searchProfileLocation?.longitude &&
      suggestedProperties.length > 0
    ) {
      const currentLoadedCount = suggestedProperties.length;
      dispatch(setInitialLoader(true));
      batch(() => {
        dispatch(clearSuggestedPropertiesCache());
        dispatch(
          mergeFilters({
            limit: currentLoadedCount,
            offset: 0,
            orderBy: {
              field: filters.orderBy?.field,
              direction: filters.orderBy?.direction,
              proximityParams: {
                latitude: searchProfileLocation?.latitude,
                longitude: searchProfileLocation?.longitude,
              },
            },
          })
        );
      });
    }
  }, [
    dispatch,
    searchProfileLocation?.latitude,
    searchProfileLocation?.longitude,
  ]);

  useEffect(() => {
    if (data?.suggestedProperties?.page) {
      batch(() => {
        dispatch(addSuggestedProperties(data?.suggestedProperties?.page));
        dispatch(setTotalItems(data?.suggestedProperties?.pageData.count));
        dispatch(setInitialLoader(false));
      });
      setIsLoading(false);
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (
      propertyIndexForScrolling !== null &&
      propertyIndexForScrolling !== undefined &&
      virtuosoRef?.current
    ) {
      dispatch(scrollToProperty(null));
      try {
        setTimeout(() => {
          virtuosoRef?.current?.scrollToIndex({
            index: propertyIndexForScrolling,
            align: 'center',
            behavior: 'smooth',
          });
        }, 0);
      } catch (error) {
        console.error('Error ->', error);
      }
    }
  }, [dispatch, propertyIndexForScrolling]);

  // useEffect(() => {
  //   dispatch(clearSuggestedPropertiesCache());
  // }, [searchProfileData]);

  const onClickOpenOverlay = useCallback(
    (
      propertyId: string,
      externalPropertyId: string,
      propertyCode: PropertyCode
    ) => {
      const selectedExpose = onlineExposes?.exposeRequests?.page.find(
        (expose: ExposeRequest) =>
          String(expose?.propertyDetails?.externalId) === propertyId
      );
      batch(() => {
        if (selectedExpose?.financingId) {
          dispatch(setFinancingId(selectedExpose.financingId));
        }
        dispatch(setSelectedPropertyId(selectedExpose?._id ?? ''));
        dispatch(setExternalPropertyId(externalPropertyId));
        dispatch(setSelectedPropertyCode(propertyCode));
        dispatch(toggleExposeOverlay(true));
        dispatch(setIsAppointmentSelectOpen(false));
        dispatch(setIsOpenHeadAccordion(true));
        dispatch(setActiveTab(0));
      });
    },
    [dispatch, onlineExposes]
  );

  const ItemWithShrinkProps = useMemo(() => {
    return ({ children, ...props }: { children?: ReactNode }) => {
      return (
        <ItemContainer
          isShrinked={isShrinked}
          isListView={currentView === Views.LIST}
          {...props}
        >
          {children}
        </ItemContainer>
      );
    };
  }, [isShrinked, currentView]);

  const itemContent = useCallback(
    (index: number) => (
      <SuggestedPropertyCard
        id={suggestedProperties?.[index]?.externalId ?? ''}
        propertyId={suggestedProperties?.[index]?.propertyId ?? 0}
        internalId={suggestedProperties?.[index]?.internalId ?? ''}
        title={suggestedProperties?.[index]?.title ?? ''}
        price={suggestedProperties?.[index]?.price ?? 0}
        currency={suggestedProperties?.[index]?.currency ?? ''}
        numberOfRooms={suggestedProperties?.[index]?.numberOfRooms ?? 0}
        livingArea={suggestedProperties?.[index]?.livingArea ?? 0}
        landArea={suggestedProperties?.[index]?.landArea ?? 0}
        isNew={latestSuggestedPropertiesIds?.includes(
          suggestedProperties?.[index]?.propertyId
        )}
        imageSrc={
          `https://erp.von-poll.com/import/images/${suggestedProperties?.[index]?.image[0].name}` ??
          ''
        }
        location={suggestedProperties?.[index]?.city ?? ''}
        regionalAddition={suggestedProperties?.[index]?.regionalAddition ?? ''}
        postCode={suggestedProperties?.[index]?.postalCode ?? ''}
        isListView={currentView === Views.LIST}
        url={`https://www.von-poll.com/${language}/expose/immobilie/${suggestedProperties?.[index]?.propertyId}`}
        hasRequestExpose
        isRequestedForExpose={requestedPropertiesForExpose?.includes(
          suggestedProperties?.[index]?.externalId
        )}
        isLiked={favouritePropertiesIds?.includes(
          suggestedProperties?.[index]?.externalId
        )}
        offeredPrice={
          priceOffers?.find(
            (item) =>
              item.propertyInternalId ===
              suggestedProperties?.[index]?.internalId
          )?.offeredPrice
        }
        isDisabled={disliked?.includes(
          suggestedProperties?.[index]?.externalId
        )}
        isPlot={suggestedProperties?.[index]?.type === 'grundstueck'}
        isUnlocked={
          !!unlockedExposes?.find((item) => {
            return item.propertyId === suggestedProperties?.[index]?.externalId;
          })
        }
        exposeRequestId={
          unlockedExposes?.find(
            (item) =>
              item.propertyId === suggestedProperties?.[index]?.externalId
          )?.exposeId ?? undefined
        }
        countryCode={suggestedProperties?.[index]?.country}
        onClickOpenOverlay={() =>
          onClickOpenOverlay(
            String(suggestedProperties?.[index]?.externalId) ?? '',
            String(suggestedProperties?.[index]?.propertyId ?? 0) ?? '',
            getPropertyCodeFromType(suggestedProperties?.[index]?.type ?? '')
          )
        }
      />
    ),
    [
      suggestedProperties,
      currentView,
      language,
      requestedPropertiesForExpose,
      favouritePropertiesIds,
      disliked,
      unlockedExposes,
      countryCode,
      onClickOpenOverlay,
    ]
  );

  if (
    initialLoader ||
    suggestedPropertiesLoading ||
    // isLoading ||
    isOnlineExposesLoading ||
    isFavouriteListLoading ||
    isUpdateSearchProfileLoading
  ) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <>
      {suggestedProperties.length === 0 ? (
        <>
          <Header isShrinked={isShrinked}>
            <FlexContainer>
              <Title
                content={t('search-profile.suggested-properties.title')}
                isListView={currentView === Views.LIST}
              />
              <Count>0</Count>
            </FlexContainer>
          </Header>
          <EmptyStatePlaceholder onExpandRadius={onExpandRadius} />
        </>
      ) : (
        <>
          <Header isShrinked={isShrinked}>
            <FlexContainer>
              <Title
                content={t('search-profile.suggested-properties.title')}
                isListView={currentView === Views.LIST}
              />
              <Count>{data?.suggestedProperties?.pageData?.count}</Count>
            </FlexContainer>
            <OrderBy
              selectedOrderBy={filters.orderBy ?? {}}
              switchOrder={switchOrder}
            />
            {!isMobileSize && (
              <FlexContainer>
                <IconContainer onClick={() => setCurrentView(Views.GRID)}>
                  <Icon
                    icon={GridView}
                    width={16}
                    height={16}
                    color={
                      currentView === Views.GRID
                        ? themeContext.blue
                        : hexToRGB(themeContext.blue, 0.5)
                    }
                  />
                </IconContainer>
                <IconContainer onClick={() => setCurrentView(Views.LIST)}>
                  <Icon
                    icon={ListView}
                    color={
                      currentView === Views.LIST
                        ? themeContext.blue
                        : hexToRGB(themeContext.blue, 0.5)
                    }
                    style={{ marginLeft: '8px' }}
                  />
                </IconContainer>
              </FlexContainer>
            )}
          </Header>
          {/* <VirtuosoGrid
            useWindowScroll
            totalCount={suggestedProperties?.length}
            overscan={200}
            ref={virtuosoRef}
            components={{
              Item: ItemWithShrinkProps,
              List: ListContainer,
            }}
            itemContent={itemContent}
          /> */}

          <ListContainer>
            {suggestedProperties.map((item, i) => (
              <ItemWithShrinkProps key={i}>
                {itemContent(i)}
              </ItemWithShrinkProps>
            ))}
          </ListContainer>
          {totalItems <= 4 && !isLoading && (
            <LoadMoreButtonContainer
              isListView={currentView === Views.LIST}
              isMobileSize={isMobileSize}
            >
              <LoadMoreButton
                fluid
                onClick={onExpandRadius}
                label={t(
                  'search-profile.suggested-properties-tab.button.expand-search-radius'
                )}
                color={themeContext.blue}
                borderColor={themeContext.blue}
              />
            </LoadMoreButtonContainer>
          )}
          {totalItems > suggestedProperties?.length && !isLoading && (
            <LoadMoreButtonContainer
              isListView={currentView === Views.LIST}
              isMobileSize={isMobileSize}
            >
              <LoadMoreButton
                fluid
                onClick={loadMore}
                label={t('button.load-more')}
                color={themeContext.blue}
                borderColor={themeContext.blue}
              />
            </LoadMoreButtonContainer>
          )}
          {isLoading && (
            <LoaderContainer isLoadingMore>
              <GlobalLoader size={50} isAbsolute />
            </LoaderContainer>
          )}
        </>
      )}
    </>
  );
};

const SuggestedPropertiesContainer = memo(SuggestedPropertiesContainerBase);

export { SuggestedPropertiesContainer };
