import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import {
  Headline2,
  ParagraphText,
} from '../../../../../common/components/ui/typography';
import { MainButton } from '../../../../../common/components/ui/buttons';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const HeaderTitle = styled(Headline2)`
  font-size: 24px;

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const InfoBox = styled.div`
  font-size: 12px;
  line-height: 1.33;
  border-radius: 3px;
  padding: 12px 12px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${device.mobile} {
    button {
      display: none;
    }
    flex-direction: column;
  }
`;

export const MobileBtnContainer = styled.div`
  display: none;
  width: 100%;

  button {
    display: block;
  }

  @media ${device.mobile} {
    display: block;
  }
`;

export const InfoBoxText = styled(ParagraphText)`
  font-size: 12px;
  flex-grow: 1;
`;

export const SubmitButton = styled(MainButton)`
  white-space: nowrap;

  @media ${device.mobile} {
    padding: 14px 4px;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  padding-top: 32px;

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 16px;
  }
`;

export const LoadMoreButtonContainer = styled.div`
  margin-top: 32px;

  & > button {
    justify-content: center;
  }

  @media ${device.mobile} {
    margin-top: 16px;
  }
`;

export const EmptyStateContent = styled.div<{ src: string }>`
  margin-top: 32px;
  position: relative;
  padding-top: 55%;
  background: url('${({ src }) => src}') no-repeat center center;
  background-size: cover;

  border: 2px solid ${(props) => props.theme.lightBlue};
  border-radius: 5px;

  @media ${device.mobile} {
    margin-top: 16px;
  }
`;
export const EmptyStateIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 240px;
  height: 240px;
  margin-left: -120px;
  margin-top: -120px;
  background: ${(props) => props.theme.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobile} {
    transform: scale(0.5);
  }
`;
