import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  CardHeader,
  CollapseButton,
  Container,
  Description,
  Title,
  Info,
  TooltipContainer,
  TitleWrapper,
} from './overview-card-styles';
import { InputSearch } from '../../../../common/components/form/input/input-search';
import { FilesOverview } from '../files-overview/files-overview';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { Tooltip } from '../../../../common/components/tooltip';
import { ReactComponent as InformationCircle } from '../../../../../assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { SimpleList } from '../../../../common/components/ui/simple-list/simple-list';
import { preparationStepList } from '../../../constants';
import { AlertInfo } from '../../../../common/components/ui/alerts/info';
import Icon from '../../../../common/components/ui/icon';
import { ReactComponent as AlertTriangle } from '../../../../../assets/streamline-light/interface-essential/alerts/alert-triangle.svg';

interface IProps {
  isLocked?: boolean;
}

const OverviewCardBase: FC<IProps> = ({ isLocked }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { propertyId } = useParams<{ propertyId: string }>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchInputDebounced, setSearchInputDebounced] = useState<string>('');
  const isAccordionsOpen = useRef<boolean>(true);
  const [accordionsState, setAccordionsState] = useState<
    { id: string; isOpen: boolean }[]
  >([
    {
      id: 'overview_documents',
      isOpen: true,
    },
    {
      id: 'overview_images',
      isOpen: true,
    },
    {
      id: 'overview_3d_tours',
      isOpen: true,
    },
    {
      id: 'overview_drone_footage',
      isOpen: true,
    },
    {
      id: 'overview_property_plans',
      isOpen: true,
    },
  ]);
  const isMobileSize = useIsMobileSize();

  const onSearchInputChangeDebounced = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInputDebounced(event.target.value);
    },
    1000
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isLocked) {
        return;
      }
      setSearchInput(event.target.value);
      onSearchInputChangeDebounced(event);
    },
    [isLocked, onSearchInputChangeDebounced]
  );

  const onClearSearch = useCallback(() => {
    setSearchInput('');
    setSearchInputDebounced('');
  }, []);

  const onCollapseSingleAccordion = useCallback((id: string) => {
    setAccordionsState((prevState) => {
      const sid = prevState.find((accordion) => accordion.id === id);
      return prevState.map((accordion) =>
        accordion === sid
          ? { ...accordion, isOpen: !accordion.isOpen }
          : accordion
      );
    });
  }, []);

  const onCollapseAllAccordions = useCallback(() => {
    isAccordionsOpen.current = !isAccordionsOpen.current;
    setAccordionsState((prevState) =>
      prevState.map((accordion) => ({
        ...accordion,
        isOpen: isAccordionsOpen.current,
      }))
    );
  }, []);

  return (
    <Container>
      <CardHeader>
        <TitleWrapper>
          <Title content={t('seller.overview-card.title')} />
          <Info>
            <Tooltip
              icon={InformationCircle}
              id={'text'}
              position="top"
              width={16}
              height={16}
              className={'tooltip'}
              stayOnHover
              clickable
            >
              <TooltipContainer>
                <SimpleList
                  title={'seller.preparation-card.info-list.title'}
                  items={preparationStepList}
                />
              </TooltipContainer>
            </Tooltip>
          </Info>
        </TitleWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            gap: '8px',
            width: isMobileSize ? '100%' : 'unset',
          }}
        >
          <InputSearch
            placeholder={t('seller-properties-list.search-input.placeholder')}
            animateWidth={{
              from: isMobileSize ? '100%' : '160px',
              to: isMobileSize ? '100%' : '300px',
            }}
            onChange={onSearchInputChange}
            value={searchInput}
            onClearSearch={onClearSearch}
          />
          <CollapseButton
            label={''}
            onClick={onCollapseAllAccordions}
            icon={ArrowDown}
            color={theme.grey2}
            iconStyle={{
              width: '16px',
              height: '8px',
              margin: 0,
              transition: `transform 500ms ease`,
              transform: isAccordionsOpen.current ? 'rotate(-180deg)' : 'none',
            }}
            borderColor={theme.borderColor}
          />
        </div>
      </CardHeader>
      <Description content={t('seller.overview-card.description')} />
      <AlertInfo
        style={{
          border: 'solid 1px rgba(162, 87, 87, 0.2)',
          backgroundColor: 'rgba(162, 87, 87, 0.2)',
          margin: '0 0 16px 0',
        }}
        text={t('seller.overview-card.info-banner')}
        icon={
          <Icon
            icon={AlertTriangle}
            width={16}
            height={16}
            color={theme.blue}
            style={{
              alignSelf: 'center',
              marginRight: '12px',
              minWidth: '16px',
            }}
            strokeWidth={'1.5'}
          />
        }
      />
      <FilesOverview
        searchInput={searchInputDebounced}
        accordionsState={accordionsState}
        onCollapseAccordion={onCollapseSingleAccordion}
        isLocked={isLocked}
      />
    </Container>
  );
};

const OverviewCard = memo(OverviewCardBase);

export { OverviewCard };
