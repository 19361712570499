import RealEstatePortalsImg from 'assets/services/real-estate-portal-service.webp';
import PremiumPlacementImg from 'assets/services/premium-placement-service.webp';
import OfficialInformationImg from 'assets/services/official-information-service.webp';
import PhotosImg from 'assets/services/photos-service.webp';
import DronePhotosImg from 'assets/services/drone-photos-service.webp';
import VirtualTourImg from 'assets/services/virtual-tour-service.webp';
import FloorPlanImg from 'assets/services/floor-plan-service.webp';
import DigitalStagingImg from 'assets/services/digital-staging-service.webp';
import EnergyCertificateImg from 'assets/services/energy-certificate-service.webp';
import MagazinesImg from 'assets/services/magazines-service.webp';
import HighCirculationImg from 'assets/services/high-circulation-service.webp';
import SocialMediaImg from 'assets/services/social-media-service.png';

import Photo1 from 'assets/services/examples/photo-1.png';
import Photo2 from 'assets/services/examples/photo-2.png';
import Photo3 from 'assets/services/examples/photo-3.png';
import Photo4 from 'assets/services/examples/photo-4.png';
import Photo5 from 'assets/services/examples/photo-5.png';
import Photo6 from 'assets/services/examples/photo-6.png';
import Photo7 from 'assets/services/examples/photo-7.png';
import Photo8 from 'assets/services/examples/photo-8.png';
import Photo9 from 'assets/services/examples/photo-9.png';
import Photo10 from 'assets/services/examples/photo-10.png';
import Photo11 from 'assets/services/examples/photo-11.png';
import Photo12 from 'assets/services/examples/photo-12.png';
import Photo13 from 'assets/services/examples/photo-13.png';
import Photo14 from 'assets/services/examples/photo-14.png';
import Photo15 from 'assets/services/examples/photo-15.png';
import Photo16 from 'assets/services/examples/photo-16.png';
import Photo17 from 'assets/services/examples/photo-17.png';

import FloorPlan1 from 'assets/services/examples/floor-plan-1.jpg';
import FloorPlan2 from 'assets/services/examples/floor-plan-2.jpg';
import FloorPlan3 from 'assets/services/examples/floor-plan-3.jpg';
import FloorPlan4 from 'assets/services/examples/floor-plan-4.jpg';
import FloorPlan5 from 'assets/services/examples/floor-plan-5.jpg';

import {
  OnOfficeServices,
  PreferredAppointmentTime,
  SellingSteps,
} from '../../generated';

export enum ServicePackage {
  Public = 'Public',
  Private = 'Private',
}

export const servicesList = [
  OnOfficeServices.SecretSale,
  OnOfficeServices.RealEstatePortals,
  OnOfficeServices.PremiumPlacement,
  OnOfficeServices.OfficialInformation,
  OnOfficeServices.Photos,
  OnOfficeServices.DronePhotos,
  OnOfficeServices.VirtualTours,
  OnOfficeServices.FloorPlans,
  OnOfficeServices.DigitalStaging,
  OnOfficeServices.EnergyCertificate,
  OnOfficeServices.Magazines,
  OnOfficeServices.HighCirculation,
  OnOfficeServices.SocialMedia,
];

export const brokerSettingsServicesList = [
  OnOfficeServices.PremiumPlacement,
  OnOfficeServices.DronePhotos,
  OnOfficeServices.VirtualTours,
  OnOfficeServices.DigitalStaging,
];

export type BrokerSettingsServices =
  | 'PremiumPlacementProperty'
  | 'DronePhotosProperty'
  | 'VirtualToursProperty'
  | 'DigitalStagingProperty'
  | 'PremiumPlacementShop'
  | 'DronePhotosShop'
  | 'VirtualToursShop'
  | 'DigitalStagingShop';

export const SERVICE_FORM_KEYS = {
  secretSale: OnOfficeServices.SecretSale,
  realEstatePortals: OnOfficeServices.RealEstatePortals,
  premiumPlacement: OnOfficeServices.PremiumPlacement,
  officialInformation: OnOfficeServices.OfficialInformation,
  photos: OnOfficeServices.Photos,
  dronePhotos: OnOfficeServices.DronePhotos,
  virtualTours: OnOfficeServices.VirtualTours,
  floorPlans: OnOfficeServices.FloorPlans,
  digitalStaging: OnOfficeServices.DigitalStaging,
  energyCertificate: OnOfficeServices.EnergyCertificate,
  magazines: OnOfficeServices.Magazines,
  highCirculation: OnOfficeServices.HighCirculation,
  socialMedia: OnOfficeServices.SocialMedia,
};

export const services = [
  {
    id: OnOfficeServices.RealEstatePortals,
    title: 'seller.services-card.real-estate-portals.title',
    description: 'seller.services-card.real-estate-portals.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: RealEstatePortalsImg,
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.RealEstatePortals,
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.PremiumPlacement,
    title: 'seller.services-card.premium-placement.title',
    description: 'seller.services-card.premium-placement.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: PremiumPlacementImg,
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.PremiumPlacement,
    canBeDisabledByShopLeader: true,
    brokerSettingsPropertyCheckboxName:
      'PremiumPlacementProperty' as BrokerSettingsServices,
    brokerSettingsShopCheckboxName:
      'PremiumPlacementShop' as BrokerSettingsServices,
  },
  {
    id: OnOfficeServices.OfficialInformation,
    title: 'seller.services-card.official-information.title',
    description: 'seller.services-card.official-information.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: OfficialInformationImg,
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.OfficialInformation,
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.Photos,
    title: 'seller.services-card.photos.title',
    description: 'seller.services-card.photos.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: PhotosImg,
    buttonLabel: 'seller.services-card.example',
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.Photos,
    example: [
      Photo1,
      Photo2,
      Photo3,
      Photo4,
      Photo5,
      Photo6,
      Photo7,
      Photo8,
      Photo9,
      Photo10,
      Photo11,
      Photo12,
      Photo13,
      Photo14,
      Photo15,
      Photo16,
      Photo17,
    ],
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.DronePhotos,
    title: 'seller.services-card.drone-photos.title',
    description: 'seller.services-card.drone-photos.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: DronePhotosImg,
    buttonLabel: 'seller.services-card.example',
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.DronePhotos,
    example: 'https://www.youtube.com/watch?v=iLD3pW_3hAQ',
    canBeDisabledByShopLeader: true,
    brokerSettingsPropertyCheckboxName:
      'DronePhotosProperty' as BrokerSettingsServices,
    brokerSettingsShopCheckboxName: 'DronePhotosShop' as BrokerSettingsServices,
  },
  {
    id: OnOfficeServices.VirtualTours,
    title: 'seller.services-card.virtual-tour.title',
    description: 'seller.services-card.virtual-tour.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: VirtualTourImg,
    buttonLabel: 'seller.services-card.example',
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.VirtualTours,
    example: 'https://tour.ogulo.com/ASI0',
    canBeDisabledByShopLeader: true,
    brokerSettingsPropertyCheckboxName:
      'VirtualToursProperty' as BrokerSettingsServices,
    brokerSettingsShopCheckboxName:
      'VirtualToursShop' as BrokerSettingsServices,
  },
  {
    id: OnOfficeServices.FloorPlans,
    title: 'seller.services-card.floor-plans.title',
    description: 'seller.services-card.floor-plans.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: FloorPlanImg,
    buttonLabel: 'seller.services-card.example',
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.FloorPlans,
    example: [FloorPlan1, FloorPlan2, FloorPlan3, FloorPlan4, FloorPlan5],
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.DigitalStaging,
    title: 'seller.services-card.digital-staging.title',
    description: 'seller.services-card.digital-staging.description',
    checkboxLabel: 'seller.services-card.activate',
    imageSrc: DigitalStagingImg,
    buttonLabel: 'seller.services-card.example',
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.DigitalStaging,
    example: 'https://tour.ogulo.com/kFWQ',
    canBeDisabledByShopLeader: true,
    brokerSettingsPropertyCheckboxName:
      'DigitalStagingProperty' as BrokerSettingsServices,
    brokerSettingsShopCheckboxName:
      'DigitalStagingShop' as BrokerSettingsServices,
  },
  {
    id: OnOfficeServices.EnergyCertificate,
    title: 'seller.services-card.energy-certificate.title',
    description: 'seller.services-card.energy-certificate.description',
    checkboxLabel: 'seller.services-card.enquire',
    imageSrc: EnergyCertificateImg,
    buttonLabel: 'seller.services-card.order',
    isButtonFilled: true,
    servicePackages: [ServicePackage.Public, ServicePackage.Private],
    name: OnOfficeServices.EnergyCertificate,
    example: 'https://www.von-poll.com/de/immobilien/energieausweis',
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.Magazines,
    title: 'seller.services-card.magazines.title',
    description: 'seller.services-card.magazines.description',
    checkboxLabel: 'seller.services-card.enquire',
    imageSrc: MagazinesImg,
    servicePackages: [ServicePackage.Public],
    availableForPropertyWithPrice: 1_000_000,
    name: OnOfficeServices.Magazines,
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.HighCirculation,
    title: 'seller.services-card.high-circulation.title',
    description: 'seller.services-card.high-circulation.description',
    checkboxLabel: 'seller.services-card.enquire',
    imageSrc: HighCirculationImg,
    servicePackages: [ServicePackage.Public],
    availableForPropertyWithPrice: 1_000_000,
    name: OnOfficeServices.HighCirculation,
    canBeDisabledByShopLeader: false,
  },
  {
    id: OnOfficeServices.SocialMedia,
    title: 'seller.services-card.social-media.title',
    description: 'seller.services-card.social-media.description',
    checkboxLabel: 'seller.services-card.enquire',
    imageSrc: SocialMediaImg,
    servicePackages: [ServicePackage.Public],
    name: OnOfficeServices.SocialMedia,
    canBeDisabledByShopLeader: false,
  },
];

export const overlayServiceExamples = [
  OnOfficeServices.Photos,
  OnOfficeServices.FloorPlans,
];

export const linkServiceExamples = [
  OnOfficeServices.DronePhotos,
  OnOfficeServices.VirtualTours,
  OnOfficeServices.DigitalStaging,
  OnOfficeServices.EnergyCertificate,
];

export const sellingSteps = [
  {
    title: 'seller.progress-card.documents',
    name: SellingSteps.DocumentsUploaded,
  },
  {
    title: 'seller.progress-card.images',
    name: SellingSteps.ImagesUploaded,
  },
  {
    title: 'seller.progress-card.property-plans',
    name: SellingSteps.PropertyPlanUploaded,
  },
  {
    title: 'seller.progress-card.premium-marketing-services',
    name: SellingSteps.MarketingServicesSelected,
  },
  {
    title: 'seller.progress-card.expose',
    name: SellingSteps.ExposeApproved,
  },
];

export enum MarketingStatus {
  NotStarted = 'NotStarted',
  NoDashboardData = 'NoDashboardData',
  DashboardDataCollected = 'DashboardDataCollected',
}

export const preferredTimeOptions = [
  {
    value: PreferredAppointmentTime.NotSpecified,
    label: 'seller.preparation-card.contact-form.preferred-time.not-specified',
  },
  {
    value: PreferredAppointmentTime.Morning,
    label: 'seller.preparation-card.contact-form.preferred-time.morning',
  },
  {
    value: PreferredAppointmentTime.Noon,
    label: 'seller.preparation-card.contact-form.preferred-time.noon',
  },
  {
    value: PreferredAppointmentTime.Afternoon,
    label: 'seller.preparation-card.contact-form.preferred-time.afternoon',
  },
  {
    value: PreferredAppointmentTime.Evening,
    label: 'seller.preparation-card.contact-form.preferred-time.evening',
  },
];

export interface SimpleListItem {
  label: string;
  link?: string;
}

export const preparationStepList: SimpleListItem[] = [
  {
    label: 'seller.preparation-card.info-list.item.map',
  },
  {
    label: 'seller.preparation-card.info-list.item.construction-file',
  },
  {
    label: 'seller.preparation-card.info-list.item.property-plan',
  },
  {
    label: 'seller.preparation-card.info-list.item.official-site-plan',
  },
  {
    label: 'seller.preparation-card.info-list.item.land-registration',
  },
  {
    label: 'seller.preparation-card.info-list.item.energy-certificate',
    link: 'https://www.von-poll.com/de/immobilien/energieausweis',
  },
  {
    label: 'seller.preparation-card.info-list.item.renovation-evidence',
  },
  {
    label: 'seller.preparation-card.info-list.item.current-tax',
  },
];

export const OnOfficeMarketplaceReferrer = 'https://smart.onoffice.de';

export enum ExternalFileType {
  Expose = 'Expose',
  Notice = 'Aushang',
  RentalStatement = 'Mietaufstellung',
  EnergyCertificate = 'Energieausweis',
}

export const ExternalFileTypes = [
  'Expose',
  'Aushang',
  'Mietaufstellung',
  'Energieausweis',
];

export const ImageTypes = ['Foto', 'Titelbild'];
export const PlanTypes = ['Grundriss'];
export const LinkTypes = ['Film-Link', 'Link', 'Ogulo-Link'];
export const VirtualTourTypes = ['Link', 'Ogulo-Link'];
export const VideoTypes = ['Film-Link'];
export const PhotoGalleryTypes = ['Foto', 'Grundriss', 'Titelbild'];
export const AllDocumentTypes = [...ExternalFileTypes, 'Dokument', 'Grundriss'];
