import styled, { css } from 'styled-components';
import { ReactComponent as ArrowDown1 } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { OutsideClickHandler } from '../../outside-click-handler';

const OuterContainer = styled.div``;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: 8px;
  position: relative;
`;

const Label = styled.label<{ $hasError: boolean; $isRedesign?: boolean }>`
  display: inline-block;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.$hasError
      ? hexToRGB(props.theme.red, 0.5)
      : hexToRGB(props.theme.blue, props.$isRedesign ? 0.7 : 0.5)};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Info = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
  height: 16px;
`;

const InputField = styled.input<{
  $hasError: boolean;
  $hasSelect?: boolean;
  $selectWidth?: string;
  $hasInfoNode?: boolean;
  $isRedesign?: boolean;
}>`
  flex: 1;
  width: ${({ $selectWidth }) =>
    $selectWidth ? `calc(100% - ${$selectWidth})` : 'auto'};
  height: 40px;
  padding: ${({ $hasInfoNode }) =>
    $hasInfoNode ? '12px 40px 12px 12px' : '12px'};
  border-radius: ${({ $hasSelect }) => ($hasSelect ? '0 3px 3px 0' : '3px')};
  background-color: ${(props) =>
    props.$isRedesign
      ? props.$hasError
        ? props.theme.redBackground
        : props.theme.white
      : props.$hasError
      ? props.theme.paleRed
      : props.theme.greyBackground};
  outline: none;
  border: ${({ $isRedesign, theme }) =>
    $isRedesign ? `1px solid ${theme.grey}` : '1px solid transparent'};
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Edge */
  ::-ms-reveal {
    display: none !important;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.white};
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  &:disabled {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
  &:read-only {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
`;

const Error = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
  margin: 4px 0 0;
`;

const SelectWrapper = styled(OutsideClickHandler)<{ $width?: string }>`
  position: relative;
`;

const SelectIcon = styled(ArrowDown1)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 9px;
  height: 9px;
`;

const Select = styled.div<{
  isActive: boolean;
  width?: string;
  isDisabled?: boolean;
  isRedesign?: boolean;
}>`
  opacity: ${(props) => (props.isDisabled ? 0.45 : 1)};
  height: 40px;
  padding: 12px 30px 12px 12px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: ${({ isActive, theme, isRedesign }) =>
    isRedesign || isActive ? theme.white : theme.greyBackground};
  outline: none;
  border: 1px solid
    ${(props) =>
      props.isRedesign
        ? props.theme.grey
        : props.isActive
        ? props.theme.borderFocus
        : 'transparent'};
  border-right: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.borderFocus
        : hexToRGB(props.theme.blue, 0.1)};
  ${({ isRedesign }) => isRedesign && 'border-right: none;'}
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
      flex-basis: ${width};
    `}
  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    border-color: ${(props) =>
      props.isDisabled ? 'transparent' : props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
`;

const SelectDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  min-width: 100%;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.greyBackground};
  background-color: ${(props) => props.theme.white};
  z-index: 2;
  max-height: 165px;
  overflow-y: auto;
`;

const SelectOption = styled.div<{ isSelected: boolean }>`
  padding: 12px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  transition: 0.12s ease;
  max-height: 163px;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? props.theme.blue : hexToRGB(props.theme.blue, 0.5)};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.greyBackground};
  }
  &:hover {
    color: ${(props) => props.theme.blue};
    background: ${(props) => props.theme.greyBackground};
  }
`;

const HiddenInput = styled.input``;

export {
  Select,
  SelectDropdown,
  SelectIcon,
  SelectOption,
  SelectWrapper,
  Info,
  InputField,
  OuterContainer,
  Container,
  HiddenInput,
  Error,
  Label,
};
