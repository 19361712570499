import { FC } from 'react';
import { OverviewLpSection } from '../../../../../generated';
import {
  TestimonialsContainer,
  TestimonialsGrid,
  Header,
} from './testimonials-styles';
import { TestimonialCard } from './testimonial-card';

interface IProps {
  section: OverviewLpSection;
}

const Testimonials: FC<IProps> = ({ section }) => {
  const { recordIds, title } = section;

  return (
    <TestimonialsContainer>
      <Header content={title} />
      <TestimonialsGrid>
        {recordIds.map((id: string, index: number) => {
          return (
            <TestimonialCard key={id} id={id} rating={index === 0 ? 4 : 5} />
          );
        })}
      </TestimonialsGrid>
    </TestimonialsContainer>
  );
};

export { Testimonials };
