import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import motion from '../../../common/components/ui/faq-accordion/utils';
import Icon from '../../../common/components/ui/icon';

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.lightBlue};
  border-radius: 3px;
  margin-bottom: 16px;
`;

const Content = styled.div<{
  noTopPadding?: boolean;
  noBottomPadding?: boolean;
}>`
  padding: 32px 32px 16px;
  background: #f2f5f7;
  background: ${(props) => props.theme.greyBackground};
  ${(props) =>
    props.noTopPadding &&
    `
    padding-top: 0px;
  `}
  ${(props) =>
    props.noBottomPadding &&
    `
    padding-bottom: 0px;
  `}
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: 'Source Serif Pro';
  line-height: 32px;
  color: ${(props) => props.theme.blue};
`;

const Box = styled.div<{ noBorder?: boolean }>`
  padding: 8px 0;
  border-bottom: 1px solid ${(props) => props.theme.blue};

  ${(props) =>
    props.noBorder &&
    `
    border-bottom: none;
  `}
`;

const BoxHeader = styled.h5`
  font-family: 'Roboto';
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
`;

const BoxDescription = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.blue};
`;

const PropertyBenefitsWrapper = styled.div`
  .outer-accordion {
    border: none;

    .outer-content {
      display: flex;
      flex-direction: column;
    }

    .outer-header {
      order: 1;
      background: rgb(229, 233, 236, 0.4);
      transition: all 0.3s;
      border-radius: 0;
      padding: 16px 16px 16px 32px;

      &:hover {
        background: rgb(229, 233, 236, 1);
        text-decoration: underline;
      }

      .icon {
        order: 2;
        margin-left: auto;
      }
    }

    .rc-collapse-content {
      padding: 0;

      .rc-collapse-content-box {
        margin: 0;
      }
    }
  }
`;

const Header = styled.span`
  color: ${(props) => props.theme.blue};
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: Roboto;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  line-height: 1.67;
`;

const items = [
  {
    header: 'property-locked-page.benefits.content.regional-marketplace.header',
    description:
      'property-locked-page.benefits.content.regional-marketplace.description',
  },
  {
    header: 'property-locked-page.benefits.content.individual-potential.header',
    description:
      'property-locked-page.benefits.content.individual-potential.description',
  },
  {
    header: 'property-locked-page.benefits.content.targeted-marketing.header',
    description:
      'property-locked-page.benefits.content.targeted-marketing.description',
  },
  {
    header: 'property-locked-page.benefits.content.buyer-qualification.header',
    description:
      'property-locked-page.benefits.content.buyer-qualification.description',
  },
  {
    header: 'property-locked-page.benefits.content.negotiation-skills.header',
    description:
      'property-locked-page.benefits.content.negotiation-skills.description',
  },
  {
    header: 'property-locked-page.benefits.content.legal-expertise.header',
    description:
      'property-locked-page.benefits.content.legal-expertise.description',
  },
  {
    header: 'property-locked-page.benefits.content.time-saving.header',
    description:
      'property-locked-page.benefits.content.time-saving.description',
  },
  {
    header:
      'property-locked-page.benefits.content.professional-networks.header',
    description:
      'property-locked-page.benefits.content.professional-networks.description',
  },
  {
    header:
      'property-locked-page.benefits.content.problem-solving-experience.header',
    description:
      'property-locked-page.benefits.content.problem-solving-experience.description',
  },
  {
    header:
      'property-locked-page.benefits.content.objective-perspective.header',
    description:
      'property-locked-page.benefits.content.objective-perspective.description',
  },
  {
    header: 'property-locked-page.benefits.content.customer-service.header',
    description:
      'property-locked-page.benefits.content.customer-service.description',
  },
];

const PropertyValuationBenefits: FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Content noBottomPadding={isOpen}>
        <Title>{t('property-locked-page.benefits.title')}</Title>
        {items.slice(0, 3).map((item, index) => (
          <Box noBorder={index === 2 && !isOpen}>
            <BoxHeader>{t(item.header)}:</BoxHeader>
            <BoxDescription>{t(item.description)}</BoxDescription>
          </Box>
        ))}
      </Content>

      <PropertyBenefitsWrapper>
        <Collapse
          onChange={() => setIsOpen((prev) => !prev)}
          openMotion={motion}
          className="outer-accordion"
          expandIcon={({ isActive }: CollapsePanelProps) => (
            <Icon
              id="close-button"
              width={16}
              height={16}
              icon={ArrowDown}
              color={themeContext.blue}
              style={{
                transition: `transform 500ms ease`,
                transform: isActive ? 'rotate(-180deg)' : 'none',
              }}
            />
          )}
        >
          <Panel
            key="1"
            header={
              <Header>
                {t(
                  `property-locked-page.benefits.${
                    isOpen ? 'collapse' : 'expand'
                  }`
                )}
              </Header>
            }
            headerClass="outer-header"
            className="outer-content"
          >
            <Content noTopPadding>
              {items.slice(3).map((item, index) => (
                <Box noBorder={index === items.length - 4}>
                  <BoxHeader>{t(item.header)}:</BoxHeader>
                  <BoxDescription>{t(item.description)}</BoxDescription>
                </Box>
              ))}
            </Content>
          </Panel>
        </Collapse>
      </PropertyBenefitsWrapper>
    </Container>
  );
};

export default PropertyValuationBenefits;
