import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RealEstateBuildingHouse } from 'assets/streamline-light/real-estate/real-estate/real-estate-building-house.svg';
import { ReactComponent as Area } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { PropertyIcon } from '../icon/icon';
import {
  Container,
  Header,
  Title,
  Content,
  IconRow,
  IconContainer,
} from './property-general-info-card-styles';

interface IProps {
  numberOfObjects: number;
  totalArea: number;
  header: string;
  title: string;
  content: string;
}

const PropertyGeneralInfoCardBase = ({
  header,
  title,
  content,
  numberOfObjects,
  totalArea,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header content={t(header)} />
      <Title content={t(title)} />
      <Content content={t(content)} />
      <IconContainer>
        <IconRow>
          <PropertyIcon
            icon={RealEstateBuildingHouse}
            label={t('seller-properties-general-info-card.properties-count')}
            value={numberOfObjects.toString() ?? '0'}
          />
          <PropertyIcon
            icon={Area}
            label={t('seller-properties-general-info-card.total-area')}
            value={`${totalArea.toString()} m²` ?? '0'}
          />
        </IconRow>
      </IconContainer>
    </Container>
  );
};

const PropertyGeneralInfoCard = memo(PropertyGeneralInfoCardBase);

export { PropertyGeneralInfoCard };
