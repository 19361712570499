import { useSearchProfilesQuery } from '../../../generated';
import { getInEur } from '../utils/currency';

interface IProps {
  userId?: string;
}

const useGetSearchProfiles = ({ userId }: IProps) => {
  const {
    searchProfiles: searchProfilesData,
    isLoading: searchProfilesIsLoading,
    isSuccess: searchProfilesIsSuccess,
    error: searchProfilesError,
  } = useSearchProfilesQuery(
    { ownerId: userId },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        const searchProfiles = (data?.searchProfiles || [])
          .map((item) => {
            const { address } = item.searchProfileData.location;
            const addressTitle = `${
              address.postCode ? `${address.postCode}, ` : ''
            }${address.locality ? `${address.locality}, ` : ''}${
              address.neighborhood ? `${address.neighborhood}, ` : ''
            }${address.city}`;

            return {
              _id: item._id,
              title: item.title,
              address: addressTitle,
              distance: `+${item.searchProfileData.radius} km`,
              price: `bis ${getInEur(item.searchProfileData.purchasePriceMax)}`,
            };
          })
          .reverse();

        return {
          searchProfiles,
          isLoading,
          isSuccess,
          error,
        };
      },
    }
  );

  return {
    searchProfilesData,
    searchProfilesIsLoading,
    searchProfilesIsSuccess,
    searchProfilesError,
  };
};

export { useGetSearchProfiles };
